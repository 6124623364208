import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  height: 100vh;
  width: 100%;
  padding: 0 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 30px;
  width: 100%;
  max-width: ${({ theme }) => theme.gridBreakpoints.lg};

  > p {
    margin-top: 20px;
  }

  form {
    margin-top: 20px;
    width: 100%;
    max-width: 400px;

    > button {
      margin-top: 20px;
      width: 100%;
      max-width: 200px;
    }
  }

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    align-items: center;

    h2,
    p {
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;

      > div {
        width: 100%;
      }
    }
  }
`;
