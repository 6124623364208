import axios from 'axios';
import LocalStorageKeyEnum from '../enums/LocalStorageKeyEnum';
import history from './history';

const baseApiURL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: baseApiURL,
});

api.interceptors.request.use(
  async config => {
    const individuoToken = localStorage.getItem(
      LocalStorageKeyEnum.AccessToken,
    );
    if (config.headers) {
      config.headers.Authorization = `Bearer ${individuoToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem(LocalStorageKeyEnum.AccessToken);
      localStorage.removeItem(LocalStorageKeyEnum.RefreshToken);
      history.push('/');
    }
    return Promise.reject(error);
  },
);

export { api };
