import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useHistory } from 'react-router-dom';
import Input from '../../../../components/Input';

import { Container, Content, Item, ButtonArea } from './styles';
import Button from '../../../../components/Button';
import InputMask from '../../../../components/InputMask';
import InputSelect from '../../../../components/InputSelect';

enum ConfirmRegisterEnum {
  Yes = 'Sim',
  No = 'Não',
  Awaiting = 'Aguardando...',
}

const ConfirmRegister: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [isConfirmed, setConfirmed] = useState<ConfirmRegisterEnum>(
    ConfirmRegisterEnum.Awaiting,
  );

  const handleConfirm = (newValue: ConfirmRegisterEnum) => {
    setConfirmed(value =>
      value.valueOf() !== newValue.valueOf()
        ? newValue
        : ConfirmRegisterEnum.Awaiting,
    );
  };

  const handleSubmit: SubmitHandler<FormData> = useCallback(async () => {
    switch (isConfirmed) {
      case ConfirmRegisterEnum.Yes:
        history.push('/cadastro/autenticar');
        break;
      case ConfirmRegisterEnum.No:
        history.push('/cadastro/concluir');
        break;
      default:
        break;
    }
  }, [history, isConfirmed]);

  useEffect(() => {}, []);

  return (
    <Container>
      <h3>Confirme seus dados do GOV.BR</h3>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Content>
          <Item gridArea="cpf">
            <InputMask name="cpf" title="CPF" mask="999.999.999-99" disabled />
          </Item>
          <Item gridArea="name">
            <Input name="name" title="Nome Completo" disabled />
          </Item>
          <Item gridArea="sName">
            <Input name="sName" title="Nome Social" disabled />
          </Item>
          <Item gridArea="bday">
            <Input
              name="bday"
              title="Data Nascimento"
              type="date"
              disabled
              max={new Date().toISOString().split('T')[0]}
            />
          </Item>
          <Item gridArea="email">
            <Input name="email" title="Email" disabled />
          </Item>
          <Item gridArea="genre">
            <InputSelect name="genre" title="Gênero" disabled />
          </Item>
          <Item gridArea="breed">
            <InputSelect name="breed" title="Raça" disabled />
          </Item>
          <Item gridArea="motherName">
            <Input name="motherName" title="Nome da Mãe" disabled />
          </Item>
          <Item gridArea="cep">
            <InputMask name="cep" title="CEP" mask="99.999-999" disabled />
          </Item>
          <Item gridArea="state">
            <InputSelect name="state" title="Estado" disabled />
          </Item>
          <Item gridArea="city">
            <InputSelect name="city" title="Cidade" disabled />
          </Item>
          <Item gridArea="neighborhood">
            <Input name="neighborhood" title="Bairro" disabled />
          </Item>
          <Item gridArea="street">
            <Input name="street" title="Logradouro" disabled />
          </Item>
          <Item gridArea="numberAddress">
            <Input name="numberAddress" title="Número" disabled type="number" />
          </Item>
          <Item gridArea="addressComplement">
            <Input name="addressComplement" title="Complemento" disabled />
          </Item>
        </Content>
        <ButtonArea>
          <h4>Confirmar dados?</h4>
          <div>
            <Button
              btnType={
                isConfirmed.valueOf() === ConfirmRegisterEnum.Yes.valueOf()
                  ? 'primary'
                  : 'outlinePrimary'
              }
              type="button"
              onClick={() => handleConfirm(ConfirmRegisterEnum.Yes)}
            >
              Sim
            </Button>
            <Button
              btnType={
                isConfirmed.valueOf() === ConfirmRegisterEnum.No.valueOf()
                  ? 'red'
                  : 'outlineRed'
              }
              type="button"
              onClick={() => handleConfirm(ConfirmRegisterEnum.No)}
            >
              Não
            </Button>
          </div>
          <Button
            btnType="primary"
            type="submit"
            disabled={
              isConfirmed.valueOf() === ConfirmRegisterEnum.Awaiting.valueOf()
            }
          >
            Próximo
          </Button>
        </ButtonArea>
      </Form>
    </Container>
  );
};

export default ConfirmRegister;
