import styled, { css } from 'styled-components';

export const Video = styled.div<{ type: 'local' | 'remote' }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  > span {
    position: absolute;
    top: 0;
    left: 0;
    margin: 10px;
    background: ${({ theme }) => theme.colors.graySecondaryDark};
    padding: 10px 15px;
    color: #fff;
    border-radius: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 250px;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      display: none;
    }
  }

  ${({ type }) =>
    type === 'local'
      ? css`
          video {
            width: 100%;
            border-top: 2px solid #fff;

            @media screen and (min-width: ${({ theme }) =>
                theme.gridBreakpoints.sm}) {
              border-radius: 5px;
              border: 1px solid #fff;
            }

            @media screen and (max-width: ${({ theme }) =>
                theme.gridBreakpoints.sm}) and (min-width: 380px) {
              min-height: 140px;
            }
          }
        `
      : css`
          video {
            border-radius: 5px 5px 0 0;
            width: 100%;
          }
        `};
`;

export const Audio = styled.div`
  display: none;
`;
