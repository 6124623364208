import { format, parseISO } from 'date-fns';
import { useCallback, useState } from 'react';
import { ApiObject } from '../../models/apiObject';
import EstabelecimentoProcedimentoService from '../../services/EstabelecimentoProcedimentoService';

function formatApiToInput(date: string): string {
  const dateFormatted = parseISO(date);

  return format(dateFormatted, 'dd/MM/yyyy');
}

interface useProfessionalReturn {
  getDias: (
    estabelecimentoId: string,
    procedimentoId: string,
    tipoDaConsulta: string,
    profissionalId: string,
  ) => Promise<void>;
  getHoras: (
    estabelecimentoId: string,
    procedimentoId: string,
    tipoDaConsulta: string,
    profissionalId: string,
    dia: string,
  ) => Promise<void>;
  dias: ApiObject<any>[];
  horas: ApiObject<any>[];
  loading: boolean;
  error: string;
}

export function useAvailability(): useProfessionalReturn {
  const [loading, setLoading] = useState(false);
  const [dias, setDias] = useState<ApiObject<any>[]>([]);
  const [horas, sethoras] = useState<ApiObject<any>[]>([]);
  const [error, setError] = useState('');

  const getDias = useCallback(
    async (
      estabelecimentoId,
      procedimentoId,
      tipoDaConsulta,
      profissionalId,
    ) => {
      try {
        setLoading(true);
        setError('');
        const horarios = await EstabelecimentoProcedimentoService.getDias(
          1,
          999,
          estabelecimentoId,
          procedimentoId,
          tipoDaConsulta,
          profissionalId,
        );

        const array: string[] = [];
        horarios.items.forEach((element: any[]) => {
          element.forEach(element => {
            const exist = array.some(obj => obj === element.dia);
            if (!exist) array.push(element.dia);
          });
        });

        setDias(dias);
      } catch (err) {
        setError('Não foi possivel carregar os dias');
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const getHoras = useCallback(
    async (
      estabelecimentoId,
      procedimentoId,
      tipoDaConsulta,
      profissionalId,
      dia,
    ) => {
      try {
        setLoading(true);
        setError('');
        const horarios = await EstabelecimentoProcedimentoService.getHoras(
          1,
          999,
          estabelecimentoId,
          procedimentoId,
          tipoDaConsulta,
          profissionalId,
          dia,
        );

        // const array: string[] = [];
        // horarios.items.forEach(element => {
        //   const exist = array.some(obj => obj === element.dia);
        //   if (!exist) array.push(element.dia);
        // });

        sethoras(
          horarios.items.map((item: { hora: string }) => ({
            key: item.hora,
            label: item.hora.substr(0, 5),
          })),
        );
      } catch (err) {
        setError('Não foi possivel carregar os horarios');
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return {
    getDias,
    getHoras,
    dias,
    horas,
    loading,
    error,
  };
}
