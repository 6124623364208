import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 30px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 0 10px;
  }

  > div {
    max-width: 900px;
    min-height: 200px;
    display: flex;
    flex-direction: column;

    h1 {
      color: ${({ theme }) => theme.colors.graySecondaryDark};
      margin-bottom: 1rem;
      text-align: center;
    }
  }
`;

export const MapContainer = styled.div`
  flex: 1;
  display: flex;
  flex-drection: column;
  align-items: center;
  justify-content: center;

  padding: 10px;
`;
