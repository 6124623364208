import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { MdError } from 'react-icons/md';
import { toast } from 'react-toastify';
import { addHours } from 'date-fns';

import { Acompanhamento } from '../../../../models/acompanhamento';
import AcompanhamentoService from '../../../../services/AcompanhamentoService';

import CardGlobal from '../../../../components/CardGlobal';
import InputTrueOrFalse from '../../../../components/InputTrueOrFalse';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';

import { Container, Error, HeaderContainer } from './styles';
import IndividuoService from '../../../../services/IndividuoService';
import { useAuth } from '../../../../hooks/auth';
import { CovidSchema } from '../../../../validations/CovidSchema';

const Covid: React.FC = () => {
  const { id } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [showInput, setShowInput] = useState<boolean>();

  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: Acompanhamento) => {
      setError(undefined);
      setIsLoading(true);

      try {
        formRef.current?.setErrors({});

        await CovidSchema.validate(data, {
          abortEarly: false,
        });
        if (!id) return;
        await AcompanhamentoService.create({ ...data, individuoId: id });
        toast.success('Questionário enviado sucesso!');
        history.push('/area/principal');
      } catch (err) {
        setIsLoading(false);

        if (err instanceof Yup.ValidationError) {
          setError('Responda todas as perguntas antes de enviar o formulário');
        } else {
          setError('Erro ao enviar o formulário');
        }
      }
    },
    [history, id],
  );

  return (
    <Container>
      <CardGlobal>
        <HeaderContainer>
          <h1>Questionário de covid</h1>
          <p>
            Olá. Vamos fazer uma série de perguntas para você que são muito
            importantes para nós avaliarmos sua saúde e lhe oferecer a melhor
            orientação e atendimento possível, se necessário.
          </p>
        </HeaderContainer>
        <hr />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <InputTrueOrFalse
            question="Você está com febre (temperatura igual ou maior a 37,8ºC)?"
            name="temperatura"
          />
          <InputTrueOrFalse question="Você está com tosse?" name="tosse" />
          <InputTrueOrFalse question="Você está com coriza?" name="coriza" />
          <InputTrueOrFalse
            question="Você está com dor no corpo?"
            name="dorCorpo"
          />
          <InputTrueOrFalse
            question="Você está com dor abdominal?"
            name="dorAbdominal"
          />
          <InputTrueOrFalse
            question="Você está com corpo mole/fraqueza?"
            name="fraqueza"
          />
          <InputTrueOrFalse
            question="Você está com dor de garganta?"
            name="dorGarganta"
          />
          <InputTrueOrFalse
            question="Você está com enjoo ou vomitou?"
            name="nauseaVomito"
          />
          <InputTrueOrFalse
            question="Você está com diarréia?"
            name="diarreia"
          />
          <InputTrueOrFalse
            question="Você está com dificuldade ou dor ao respirar?"
            name="dificuldadeRespirar"
          />
          <InputTrueOrFalse
            question="Você está com taquicardia (coração acelerado)?"
            name="taquicardia"
          />
          <InputTrueOrFalse
            question="Você está com perda de olfato e/ou paladar (sem sentir cheiro ou sabor)?"
            name="perdaOlfatoPaladar"
          />
          <InputTrueOrFalse
            question="Sua febre retornou, após período de pelo menos um dia sem febre?"
            name="temperaturaRetornou"
          />
          <InputTrueOrFalse
            question="Nas últimas 24h, você saiu do interior de seu domicílio?"
            name="sairCasa"
          />
          <InputTrueOrFalse
            question="Alguma pessoa que entrou em contato com você, desde o seu adoecimento, apresenta sintomas de doença (por exemplo: febre, tosse, falta de ar, dor de garganta, coriza)?"
            name="contatoPessoas"
          />
          <InputTrueOrFalse
            question="Você está a quantos dias com febre?"
            name="diasComFebre"
          />
          <InputTrueOrFalse
            question="Você foi a algum serviço de saúde?"
            name="atendidoServicoSaude"
            onClick={showInput => setShowInput(showInput)}
          />

          {showInput && (
            <Input
              name="dataAtendidoServicoSaude"
              title="Isso aconteceu há quantos dias?"
              type="date"
              max={new Date().toISOString().split('T')[0]}
            />
          )}

          {error && (
            <Error>
              <MdError size={20} />
              <strong>{error}</strong>
            </Error>
          )}

          <Button type="submit" btnType="gray" loading={isLoading}>
            Enviar
          </Button>
        </Form>
      </CardGlobal>
    </Container>
  );
};

export default Covid;
