import { ApiObject } from '../models/apiObject';
import { api } from './Api';

interface getListByParams {
  ibge?: string;
  uf?: string;
  city?: string;
}

interface getListByItemResponse {
  ibge: number;
  nome: string;
}

const getListBy = async ({
  uf,
  city,
  ibge,
}: getListByParams): Promise<getListByItemResponse[]> => {
  const { data } = await api.get<ApiObject<getListByItemResponse>>(`Cidade`, {
    params: {
      Nome: city ? `%${city}%` : undefined,
      UfAbreviado: uf,
      Ibge: ibge,
    },
  });

  if (!data) {
    return [];
  }

  return data.items;
};

export default { getListBy };
