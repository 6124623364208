import React from 'react';

import { Container, InfoContainer } from './styles';

interface MedicalAppointmentDetailsProps {
  date?: string;
  schedule?: string;
  name?: string;
  address?: string;
  specialty?: string;
  comments?: string | null;
}

const MedicalAppointmentDetails: React.FC<MedicalAppointmentDetailsProps> = ({
  date,
  schedule,
  name,
  address,
  specialty,
  comments,
}) => {
  return (
    <Container>
      <InfoContainer>
        <span>Data:</span>
        <span>{date}</span>
      </InfoContainer>
      <InfoContainer>
        <span>Horário:</span>
        <span>{schedule}</span>
      </InfoContainer>
      <InfoContainer>
        <span>Profissional:</span>
        <span>{name}</span>
      </InfoContainer>
      <InfoContainer>
        <span>Especialidade:</span>
        <span>{specialty}</span>
      </InfoContainer>
      <InfoContainer>
        <span>Endereço/Local:</span>
        <span>{address}</span>
      </InfoContainer>
      {comments !== '' && (
        <InfoContainer>
          <span>Observações:</span>
          <span>{comments}</span>
        </InfoContainer>
      )}
      <InfoContainer>
        <span>Confirmar presença:</span>
      </InfoContainer>
    </Container>
  );
};

export default MedicalAppointmentDetails;
