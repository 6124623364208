import styled from 'styled-components';

import { ContainerScreens } from '../../styles';

export const Container = styled(ContainerScreens)``;

const styleDefaultProfessionalList = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 1fr 1fr;
  gap: 10px;

  width: 100%;
  border-radius: 6px;
`;

export const ProfessionalHeader = styled(styleDefaultProfessionalList)`
  background: ${({ theme }) => theme.colors.primary};
  color: #fff;
  padding: 15px 20px;
  margin-bottom: 10px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    display: none;
  }
`;

export const ProfessionalInfo = styled(styleDefaultProfessionalList)`
  border: 2px solid ${({ theme }) => theme.colors.grayPrimaryLight};
  padding: 20px;

  & + div {
    margin-top: 10px;
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }

  div > strong {
    display: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      strong {
        display: initial;

        margin-right: 5px;
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  color: ${({ theme }) => theme.colors.graySecondaryDark};
  margin-bottom: 30px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    background: transparent;
    border: none;

    span {
      font-size: 16px;
      color: ${({ theme }) => theme.colors.graySecondaryDark};
      margin-left: 10px;
    }
  }
`;
