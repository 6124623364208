import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Leaflet from 'leaflet';

import MapMarker from '../../../../assets/map-pin.svg';

import { Estabelecimento } from '../../../../models/estabelecimento';
import { UserPosition } from '..';

interface MapProps {
  center: UserPosition;
  UBSData: Estabelecimento[];
}

const mapIcon = Leaflet.icon({
  iconUrl: MapMarker,
  iconSize: [25, 25],
});

const Map: React.FC<MapProps> = ({ center, UBSData }) => {
  return (
    <MapContainer
      center={[center.latitude, center.longitude]}
      zoom={15}
      style={{
        width: '100%',
        height: '60vh',
        boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.3)',
        borderRadius: '5px',
      }}
    >
      <TileLayer
        url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
      />
      {center.latitude !== -22.908167 && (
        <Marker position={[center.latitude, center.longitude]} icon={mapIcon}>
          <Popup>
            <strong>Sua localização 😀</strong>
          </Popup>
        </Marker>
      )}

      {UBSData.map(ubs => (
        <Marker
          key={ubs.id}
          icon={mapIcon}
          position={[ubs.latitude, ubs.longitude]}
        >
          <Popup maxWidth={200}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <strong>{ubs.nomeFantasia}</strong>
              <span>Breve descrição do serviço {ubs.nomeFantasia}</span>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default Map;
