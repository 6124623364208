import React, { useState } from 'react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { Container, Button, Content } from './styles';

interface CardCollapseData {
  data: {
    id: string;
    titulo: string;
    descricao: string;
  };
}

const CardCollapse: React.FC<CardCollapseData> = ({ data }) => {
  const [expandCard, setExpandCard] = useState<boolean>(false);

  return (
    <Container>
      <Button
        isExpanded={expandCard}
        onClick={() => setExpandCard(!expandCard)}
      >
        <span>{data.titulo}</span>
        {expandCard ? <MdArrowDropUp /> : <MdArrowDropDown />}
      </Button>
      <Content isExpanded={expandCard}>
        <p>{data.descricao}</p>
      </Content>
    </Container>
  );
};

export default CardCollapse;
