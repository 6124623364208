import { Form } from '@unform/web';
import React, { useCallback, useState } from 'react';
import InputChat from './InputChat';

interface InputChatFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSend: (message: string) => any;
}

interface FormData {
  message: string;
}

const InputChatForm: React.FC<InputChatFormProps> = ({ onSend }) => {
  const [loading, setLoading] = useState(false);

  const handleSend = useCallback(
    async (data: FormData, obj, event) => {
      event.preventDefault();

      if (loading) {
        return;
      }

      setLoading(true);
      obj.reset();

      data.message && (await onSend(data.message));
      setLoading(false);
    },
    [loading, onSend],
  );

  return (
    <Form onSubmit={handleSend}>
      <InputChat placeholder="Digite sua mensagem" name="message" />
    </Form>
  );
};

export default InputChatForm;
