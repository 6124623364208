import styled from 'styled-components';

import CardGlobal from '../../../../components/CardGlobal';

interface ContainerScreensProps {
  isLoading: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  padding: 0 30px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 0 10px;
  }
`;

export const Content = styled(CardGlobal)`
  width: 100%;
  max-width: 800px;
`;

export const ContainerScreens = styled.div<ContainerScreensProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isLoading }) => (isLoading ? 'center' : 'flex-start')};
  justify-content: ${({ isLoading }) => (isLoading ? 'center' : 'flex-start')};

  min-height: 300px;
`;
