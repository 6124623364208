import React, { useEffect, useState } from 'react';
import {
  LocalParticipant as LocalParticipantType,
  Participant as ParticipantType,
  LocalVideoTrack,
  RemoteVideoTrack,
  LocalAudioTrack,
  RemoteAudioTrack,
} from 'twilio-video';

import { Video, Audio } from './styles';

interface ParticipantProps {
  participant: LocalParticipantType | ParticipantType;
  type: 'local' | 'remote';
  participantEntering?: boolean;
  participantName?: string;
}

const Participant: React.FC<ParticipantProps> = ({
  participant,
  type,
  children,
  participantEntering,
  participantName,
}) => {
  const [videoTracks, setVideoTracks] = useState<
    (LocalVideoTrack | RemoteVideoTrack | null)[]
  >([]);
  const [audioTracks, setAudioTracks] = useState<
    (LocalAudioTrack | RemoteAudioTrack | null)[]
  >([]);

  useEffect(() => {
    if (participant) {
      const videoTrackMap = Array.from(participant.videoTracks.values())
        .map(publication => publication.track)
        .filter(track => !!track);
      const audioTrackMap = Array.from(participant.audioTracks.values())
        .map(publication => publication.track)
        .filter(track => !!track);

      setVideoTracks(videoTrackMap);

      setAudioTracks(audioTrackMap);
    }

    participant?.on('trackSubscribed', track => {
      if (track.kind === 'video') {
        setVideoTracks(prevState => [...prevState, track]);
      } else if (track.kind === 'audio') {
        setAudioTracks(prevState => [...prevState, track]);
      }
    });

    participant?.on('trackUnsubscribed', track => {
      if (track.kind === 'video') {
        setVideoTracks(prevState => prevState.filter(v => v !== track));
      } else if (track.kind === 'audio') {
        setAudioTracks(prevState => prevState.filter(a => a !== track));
      }
    });

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant?.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    const localMedia = document.getElementById(
      type === 'local' ? 'localVideo' : 'remoteVideo',
    );

    if (
      videoTrack &&
      (videoTrack.isEnabled || type === 'remote') &&
      !participantEntering &&
      localMedia
    ) {
      localMedia.innerHTML = '';
      localMedia.appendChild(videoTrack.attach());
    }

    return () => {
      videoTrack?.detach();
    };
  }, [videoTracks, type, participantEntering]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    const localMedia = document.getElementById(
      type === 'local' ? 'localAudio' : 'remoteAudio',
    );

    if (audioTrack && audioTrack.isEnabled && localMedia) {
      localMedia.appendChild(audioTrack.attach());
    }

    return () => {
      audioTrack?.detach();
    };
  }, [audioTracks, type]);

  return (
    <>
      {children && participantEntering ? (
        children
      ) : (
        <Video id={type === 'local' ? 'localVideo' : 'remoteVideo'} type={type}>
          {type === 'remote' && participantName && (
            <span>{participantName}</span>
          )}
        </Video>
      )}
      <Audio id={type === 'local' ? 'localAudio' : 'remoteAudio'} />
    </>
  );
};

export default Participant;
