import axios from 'axios';
import { useCallback, useState } from 'react';
import {
  InputSelectItemOptions,
  InputSelectOptions,
} from '../../components/InputSelect';
import UFService from '../../services/UFService';
import CityService from '../../services/CityService';

interface useAddressResponse {
  getCEP: (cep: string) => Promise<getCEPResponse>;
  UfList: InputSelectOptions;
  cityList: InputSelectOptions;
  updateUFList: () => Promise<void>;
  updateCityList: (uf: string, city: string) => Promise<void>;
  getCityByID: (id: string) => Promise<void>;
}

interface getCEPResponse {
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
}

const useAddress = (): useAddressResponse => {
  const [UfList, setUfList] = useState<InputSelectOptions>([]);
  const [cityList, setCityList] = useState<InputSelectOptions>([]);

  const getCEP = useCallback(async (cep: string) => {
    const { data } = await axios.get(
      `https://viacep.com.br/ws/${cep.replace(/[^0-9]/g, '')}/json/`,
    );

    return data as getCEPResponse;
  }, []);

  const updateUFList = useCallback(async () => {
    const data = await UFService.getAll();

    const newItems = data.map<InputSelectItemOptions>(item => ({
      value: item.ufAbreviado,
      label: item.ufExtenso,
    }));

    setUfList(newItems);
  }, []);

  const updateCityList = useCallback(async (uf: string, city: string) => {
    const data = await CityService.getListBy({ uf, city });

    const newItems = data.map<InputSelectItemOptions>(item => ({
      value: item.ibge.toString(),
      label: item.nome,
    }));

    setCityList(newItems);
  }, []);

  const getCityByID = useCallback(async (id: string) => {
    const data = await CityService.getListBy({ ibge: id });

    const newItems = data.map<InputSelectItemOptions>(item => ({
      value: item.ibge.toString(),
      label: item.nome,
    }));

    setCityList(newItems);
  }, []);

  return {
    getCEP,
    UfList,
    cityList,
    updateUFList,
    updateCityList,
    getCityByID,
  };
};

export default useAddress;
