import { ApiObject } from '../models/apiObject';
import { InformacaoUtil } from '../models/informacaoUtil';
import { api } from './Api';

const getAll = async (): Promise<InformacaoUtil[]> => {
  const { data } = await api.get<ApiObject<InformacaoUtil>>('InformacoesUteis');

  if (!data) {
    return [];
  }

  return data.items;
};

export default { getAll };
