import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FiEdit2 } from 'react-icons/fi';
import axios from 'axios';
import { toast } from 'react-toastify';
import getValidationCPF from '../../../utils/getValidationCPF';
import { Individuo } from '../../../models/individuo';
import IndividuoService from '../../../services/IndividuoService';
import getValidationErrors from '../../../utils/getValidationErrors';
import { putCEPMask, putCPFMask } from '../../../utils/hiddenData';

import Input from '../../../components/Input';
import InputMask from '../../../components/InputMask';
import InputSelect, {
  InputSelectOptions,
} from '../../../components/InputSelect';
import Button from '../../../components/Button';
import CardGlobal from '../../../components/CardGlobal';

import { CardHeader, Container, Content, Item } from './styles';
import { Notificacao } from '../../../models/notificacao';
import { useAuth } from '../../../hooks/auth';
import useAddress from '../../../hooks/local/useAddress';
import InputSelectAlternative from '../../../components/InputSelectAlternative';
import { getPartiallyHiddenString } from '../../../utils/getPartiallyHiddenString';
import { ProfileSchema } from '../../../validations/FormProfileSchema';

interface FormData {
  cpf: string;
  name: string;
  sName: string;
  bday?: string;
  email: string;
  phone: string;
  genre?: number;
  breed?: number;
  motherName: string;
  cep: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  numberAddress: string;
  addressComplement: string;
}

const Perfil: React.FC = () => {
  const { id } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [userInfo, setUserInfo] = useState<FormData>();
  const {
    getCEP,
    UfList,
    cityList,
    updateUFList,
    updateCityList,
    getCityByID,
  } = useAddress();
  const [canEdit, setCanEdit] = useState(false);
  const [dontEdit, setDontEdit] = useState(true);

  const breedList: InputSelectOptions = [
    { value: 1, label: 'Branco' },
    { value: 2, label: 'Preto' },
    { value: 3, label: 'Parda' },
    { value: 4, label: 'Amarelo' },
    { value: 5, label: 'Indígena' },
    { value: 6, label: 'Sem Informação' },
  ];

  const genreList: InputSelectOptions = [
    { value: 0, label: 'Masculino' },
    { value: 1, label: 'Feminino' },
  ];

  const hideUserData = useCallback((data: FormData): FormData => {
    return {
      ...data,
      cpf: putCPFMask(data.cpf),
      cep: putCEPMask(data.cep),
      name: getPartiallyHiddenString(data.name),
      email: getPartiallyHiddenString(data.email),
      phone: getPartiallyHiddenString(data.phone),
      motherName: getPartiallyHiddenString(data.motherName),
      neighborhood: getPartiallyHiddenString(data.neighborhood),
      street: getPartiallyHiddenString(data.street),
      addressComplement: getPartiallyHiddenString(data.addressComplement),
    };
  }, []);

  const transformIndividuoToFormData = useCallback(
    (user: Individuo): FormData => {
      return {
        cpf: user.cpf ?? '',
        name: user.nomeCompleto ?? '',
        sName: user.nomeSocial ?? '',
        bday: (user.dataNascimento as string).substr(0, 10),
        email: user.email ?? '',
        phone: user.telefoneCelular ?? '',
        genre: user.sexo,
        breed: user.racaOuCor,
        motherName: user.nomeDaMae ?? '',
        cep: user.logradouroCep ?? '',
        state: user.ufAbreviado ?? '',
        city: user.cidadeId ?? '0',
        neighborhood: user.logradouroBairro ?? '',
        street: user.logradouro ?? '',
        numberAddress: user.logradouroNumero ?? '',
        addressComplement: user.logradouroComplemento ?? '',
      };
    },
    [],
  );

  const handleCEPChange = useCallback(
    async (value: string) => {
      if (value.length === 10) {
        const info = await getCEP(value);
        formRef.current?.setFieldValue('neighborhood', info.bairro);
        formRef.current?.setFieldValue('street', info.logradouro);
        // formRef.current?.setFieldValue('addressComplement', info.complemento);
        formRef.current?.setFieldValue('state', info.uf);
        updateCityList(info.uf, info.localidade).then(() => {
          formRef.current?.setFieldValue('city', info.ibge);
        });
      }
    },
    [getCEP, updateCityList],
  );

  const handleCityInputChange = useCallback(
    (value: string) => {
      if (value.length > 2) {
        const uf = formRef.current?.getFieldValue('state');
        updateCityList(uf, value);
      }
    },
    [updateCityList],
  );

  const handleSubmit: SubmitHandler<FormData> = useCallback(
    async data => {
      try {
        if (!id) {
          return;
        }

        formRef.current?.setErrors({});

        data.cpf = data.cpf.replace(/[^0-9]/g, '');
        data.phone = data.phone.replace(/[^0-9]/g, '');
        data.cep = data.cep.replace(/[^0-9]/g, '');

        await ProfileSchema.validate(data, {
          abortEarly: false,
        });

        const newUser: Individuo = {
          imagem: '',
          telefoneCelular: data.phone,
          cpf: data.cpf,
          nomeCompleto: data.name,
          nomeSocial: data.sName,
          dataNascimento: data.bday,
          email: data.email,
          sexo: data.genre,
          racaOuCor: data.breed,
          nomeDaMae: data.motherName,
          logradouroCep: data.cep,
          ufAbreviado: data.state,
          cidadeId: data.city,
          logradouroBairro: data.neighborhood,
          logradouro: data.street,
          logradouroNumero: data.numberAddress,
          logradouroComplemento: data.addressComplement,
        };

        const user = await IndividuoService.save(id, newUser);
        const newData = transformIndividuoToFormData(user);
        setUserInfo(newData);

        getCityByID(newData.city).finally(() => {
          formRef.current?.setData(hideUserData(newData));
        });

        setCanEdit(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        } else if (axios.isAxiosError(error) && error.response?.data) {
          const { data } = error.response as {
            data: { notifications: Notificacao[] } | string;
          };

          if (typeof data === 'string') {
            toast.error(data as string);
          } else if (data.notifications && data.notifications.length > 0) {
            data.notifications.forEach(notification => {
              toast.error(notification.message);
            });
          } else {
            toast.error('Tivemos um problema.');
          }
        } else {
          toast.error('Tivemos um problema.');
        }
      }
    },
    [getCityByID, hideUserData, id, transformIndividuoToFormData],
  );

  const getUserData = useCallback(async () => {
    if (!id) {
      return;
    }
    const user = await IndividuoService.getById(id);
    const newData = transformIndividuoToFormData(user);
    setUserInfo(newData);

    getCityByID(newData.city).finally(() => {
      formRef.current?.setData(hideUserData(newData));
    });
  }, [getCityByID, hideUserData, id, transformIndividuoToFormData]);

  useEffect(() => {
    updateUFList().then(() => {
      getUserData();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (canEdit && userInfo) {
      formRef.current?.setData(userInfo);
    }
  }, [canEdit, userInfo]);

  return (
    <Container>
      <CardGlobal maxWidthDefault>
        <CardHeader>
          <h1>Perfil</h1>

          {!canEdit && (
            <button type="button" onClick={() => setCanEdit(true)}>
              <FiEdit2 /> Editar dados
            </button>
          )}
        </CardHeader>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Content>
            <Item gridArea="cpf">
              <InputMask
                name="cpf"
                title="CPF"
                mask="999.999.999-99"
                disabled={dontEdit}
              />
            </Item>

            <Item gridArea="name">
              <Input name="name" title="Nome Completo" disabled={!canEdit} />
            </Item>

            <Item gridArea="sName">
              <Input name="sName" title="Nome Social" disabled={!canEdit} />
            </Item>

            <Item gridArea="bday">
              <Input
                name="bday"
                title="Data Nascimento"
                type="date"
                disabled={dontEdit}
                max={new Date().toISOString().substr(0, 10)}
              />
            </Item>

            <Item gridArea="email">
              <Input name="email" title="Email" disabled={!canEdit} />
            </Item>

            <Item gridArea="phone">
              <InputMask
                name="phone"
                title="Telefone"
                mask="(99) 9 9999-9999"
                disabled={!canEdit}
              />
            </Item>

            <Item gridArea="genre">
              <InputSelect
                name="genre"
                title="Gênero"
                options={genreList}
                disabled={!canEdit}
              />
            </Item>

            <Item gridArea="breed">
              <InputSelect
                name="breed"
                title="Raça"
                options={breedList}
                disabled={!canEdit}
              />
            </Item>

            <Item gridArea="motherName">
              <Input
                name="motherName"
                title="Nome da Mãe"
                disabled={!dontEdit}
              />
            </Item>

            <Item gridArea="cep">
              <InputMask
                name="cep"
                title="CEP"
                mask="99.999-999"
                disabled={!canEdit}
                onChange={({ target }) => handleCEPChange(target.value)}
              />
            </Item>

            <Item gridArea="state">
              <InputSelect
                name="state"
                title="Estado"
                disabled={dontEdit}
                options={UfList}
              />
            </Item>

            <Item gridArea="city">
              <InputSelectAlternative
                name="city"
                title="Cidade"
                disabled={dontEdit}
                options={cityList}
                onInputChange={value => handleCityInputChange(value)}
              />
            </Item>

            <Item gridArea="neighborhood">
              <Input name="neighborhood" title="Bairro" disabled={dontEdit} />
            </Item>

            <Item gridArea="street">
              <Input name="street" title="Logradouro" disabled={dontEdit} />
            </Item>

            <Item gridArea="numberAddress">
              <Input
                name="numberAddress"
                title="Número"
                disabled={!canEdit}
                type="number"
              />
            </Item>

            <Item gridArea="addressComplement">
              <Input
                name="addressComplement"
                title="Complemento"
                disabled={!canEdit}
              />
            </Item>
          </Content>

          {canEdit && (
            <Button type="submit" btnType="gray">
              Salvar
            </Button>
          )}
        </Form>
      </CardGlobal>
    </Container>
  );
};

export default Perfil;
