import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button';
import ButtonOption from '../../../../components/ButtonOption';
import { useRouteRules } from '../../../../hooks/routeRules';
import { Notificacao } from '../../../../models/notificacao';
import IndividuoSenhaService from '../../../../services/IndividuoSenhaService';
import { Container } from '../styles';

import { Content } from './styles';

const SendCodeForgotPassword: React.FC = () => {
  const history = useHistory();
  const { verifyRules } = useRouteRules();
  const { state } = useLocation();
  const [buttonSelected, setButtonSelected] = useState<
    'SMS' | 'Email' | 'WhatsApp'
  >();

  const handleSendCode = useCallback(async () => {
    try {
      if (!buttonSelected || !state?.cpf) {
        return;
      }

      await IndividuoSenhaService.forgot({
        cpf: state.cpf,
        metodo: buttonSelected,
      });
      history.push(
        `/esqueci-minha-senha/verificar/confirmar?type=${buttonSelected}`,
        {
          cpf: state.cpf,
          metodo: buttonSelected,
        },
      );
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        const { data } = error.response as {
          data: { notifications: Notificacao[] } | string;
        };

        if (typeof data === 'string') {
          toast.error(data as string);
        } else if (data.notifications && data.notifications.length > 0) {
          data.notifications.forEach(notification => {
            toast.error(notification.message);
          });
        } else {
          toast.error('Tivemos um problema.');
        }
      } else {
        toast.error('Tivemos um problema.');
      }
    }
  }, [buttonSelected, history, state]);

  useEffect(() => {
    verifyRules({
      allowThesePreviousRoutes: [
        '/esqueci-minha-senha/cpf',
        '/esqueci-minha-senha/verificar/confirmar',
      ],
      onError: () => {
        history.push('/esqueci-minha-senha/cpf');
      },
      onSuccess: () => {
        if (!state) {
          history.push('/esqueci-minha-senha/cpf');
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Content>
        <h2>Solicitação de código de autenticação</h2>
        <p>Escolha o método:</p>

        <div>
          <ButtonOption
            onClick={() => setButtonSelected('SMS')}
            filled={buttonSelected === 'SMS'}
          >
            SMS
          </ButtonOption>
          <ButtonOption
            onClick={() => setButtonSelected('Email')}
            filled={buttonSelected === 'Email'}
          >
            E-mail
          </ButtonOption>
          <ButtonOption
            onClick={() => setButtonSelected('WhatsApp')}
            filled={buttonSelected === 'WhatsApp'}
          >
            Whatsapp
          </ButtonOption>
        </div>
        <Button
          onClick={handleSendCode}
          disabled={!buttonSelected}
          btnType="gray"
        >
          Próximo
        </Button>
      </Content>
    </Container>
  );
};

export default SendCodeForgotPassword;
