import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import Route from './Route';

import Auth from '../pages/Public/Register/Auth';
import Login from '../pages/Public/Login';
import Register from '../pages/Public/Register';
import CompleteRegister from '../pages/Public/Register/CompleteRegister';
import ConfirmRegister from '../pages/Public/Register/ConfirmRegister';
import ConsentTerm from '../pages/Public/Terms/ConsentTerm';
import PrivacyPolicy from '../pages/Public/Terms/PrivacyPolicy';
import CreatePassword from '../pages/Public/Register/CreatePassword';
import Error from '../pages/Public/Error';
import ConfirmCode from '../pages/Public/Register/Auth/ConfirmCode';

import Details from '../pages/Area/Agendamento/Detalhes';
import Agendamento from '../pages/Area/Agendamento';
import MainArea from '../pages/Area/Main';
import Notifications from '../pages/Area/Notifications';
import Documents from '../pages/Area/Exames';
import ComorbiditiesQuestionnaire from '../pages/Area/Questionnaires/Comorbidities';
import Covid from '../pages/Area/Covid';
import NovoAgendamento from '../pages/Area/Agendamento/NovoAgendamento';
import UBSDetails from '../pages/Area/UBS/Details';
import CovidQuestionnaire from '../pages/Area/Questionnaires/Covid';
import Perfil from '../pages/Area/Perfil';
import UsefulInformation from '../pages/Area/UsefulInformation';
import UBSList from '../pages/Area/UBS/List';
import TrustTerm from '../pages/Public/Terms/TrustTerm';
import CPFForgotPassword from '../pages/Public/ForgotPassword/CPFForgotPassword';
import SendCodeForgotPassword from '../pages/Public/ForgotPassword/SendCodeForgotPassword';
import ConfirmCodeForgotPassword from '../pages/Public/ForgotPassword/ConfirmCodeForgotPassword';
import CreatePasswordForgotPassword from '../pages/Public/ForgotPassword/CreatePasswordForgotPassword';
import SendCodeResetPassword from '../pages/Area/ResetPassword/SendCodeResetPassword';
import ConfirmCodeResetPassword from '../pages/Area/ResetPassword/ConfirmCodeResetPassword';
import Consulta from '../pages/Area/Consulta';
import ChooseOneResetPassword from '../pages/Area/ResetPassword/ChooseOneResetPassword';
import ServicesNearYou from '../pages/Area/ServicesNearYou';
import ExamsDone from '../pages/Area/ExamsDone';

const Routes: React.FC = () => (
  <Switch>
    <Redirect from="/" to="/login" exact />
    <Route path="/login" exact isIndependent component={Login} />
    <Route path="/cadastro" exact component={Register} />
    <Route path="/cadastro/confirmar" exact component={ConfirmRegister} />
    <Route path="/cadastro/concluir" exact component={CompleteRegister} />
    <Route path="/cadastro/autenticar" exact component={Auth} />
    <Route
      path="/cadastro/autenticar/confirmar"
      exact
      component={ConfirmCode}
    />
    <Route path="/cadastro/criar-senha" exact component={CreatePassword} />
    <Redirect from="/esqueci-minha-senha" to="/esqueci-minha-senha/cpf" exact />
    <Route
      path="/esqueci-minha-senha/cpf"
      exact
      component={CPFForgotPassword}
    />
    <Route
      path="/esqueci-minha-senha/verificar"
      exact
      component={SendCodeForgotPassword}
    />
    <Route
      path="/esqueci-minha-senha/verificar/confirmar"
      exact
      component={ConfirmCodeForgotPassword}
    />
    <Route
      path="/esqueci-minha-senha/senha"
      exact
      component={CreatePasswordForgotPassword}
    />
    <Route
      path="/alterar-senha/verificar"
      isPrivate
      exact
      component={SendCodeResetPassword}
    />
    <Route
      path="/alterar-senha/verificar/confirmar"
      isPrivate
      exact
      component={ConfirmCodeResetPassword}
    />
    <Route
      path="/termo-de-consentimento"
      isPrivate
      exact
      component={ConsentTerm}
    />
    <Route path="/termo-de-confiabilidade" exact component={TrustTerm} />
    <Route path="/politica-de-privacidade" exact component={PrivacyPolicy} />

    <Route path="/error" exact component={Error} isPrivate />

    <Redirect from="/area" to="/area/principal" exact />
    <Route
      path="/area/notificacoes"
      exact
      isPrivate
      component={Notifications}
    />
    <Route
      path="/area/agendamento/detalhes"
      exact
      isPrivate
      component={Details}
    />
    <Route path="/area/agendamento" exact isPrivate component={Agendamento} />
    <Route
      path="/area/agendamento/novo"
      exact
      isPrivate
      component={NovoAgendamento}
    />
    <Route
      path="/area/consulta/:agendamentoId"
      exact
      isPrivate
      component={Consulta}
    />
    <Route path="/area/perfil" exact isPrivate component={Perfil} />
    <Route path="/area/exames" exact isPrivate component={ExamsDone} />
    <Route path="/area/info/covid" exact isPrivate component={Covid} />
    <Route
      path="/area/questionarios/covid"
      exact
      isPrivate
      component={CovidQuestionnaire}
    />
    <Route path="/area/principal" exact isPrivate component={MainArea} />
    <Route
      path="/area/questionarios/comorbidade"
      exact
      isPrivate
      component={ComorbiditiesQuestionnaire}
    />
    <Route path="/area/ubs/:id" isPrivate exact component={UBSDetails} />
    <Route
      path="/area/informacoes-uteis"
      isPrivate
      exact
      component={UsefulInformation}
    />
    <Redirect
      from="/area/profile/alterar-senha"
      to="/area/profile/alterar-senha/verificar"
      exact
    />
    <Route
      path="/area/profile/alterar-senha/escolher"
      isPrivate
      exact
      component={ChooseOneResetPassword}
    />
    <Route
      path="/area/profile/alterar-senha/verificar"
      isPrivate
      exact
      component={SendCodeResetPassword}
    />
    <Route
      path="/area/profile/alterar-senha/verificar/confirmar"
      isPrivate
      exact
      component={ConfirmCodeResetPassword}
    />
    <Route path="/area/listagem-ubs" isPrivate exact component={UBSList} />
    <Route
      path="/area/servicos-perto-de-voce"
      isPrivate
      exact
      component={ServicesNearYou}
    />
    <Redirect from="*" to="/error" exact />
  </Switch>
);

export default Routes;
