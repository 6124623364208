import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { toast } from 'react-toastify';
import { useField } from '@unform/core';
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi';

import { Container, Content } from './styles';

interface InputPasswordProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  title: string;
  placeholder?: string;
}

const InputPassword: React.FC<InputPasswordProps> = ({
  name,
  title,
  placeholder,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [isFocused, setIsFocused] = useState(false);
  const [hidden, setHidden] = useState(true);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleInvisibility = useCallback(() => {
    setHidden(state => !state);
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField, error]);

  return (
    <Container>
      <strong>{title}</strong>

      <Content isErrored={!!error} isFocused={isFocused}>
        <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          type={hidden ? 'password' : 'text'}
          placeholder={placeholder}
          {...rest}
        />

        {/* <button type="button" onClick={() => handleInvisibility()}>
          {hidden ? <HiOutlineEyeOff size={20} /> : <HiOutlineEye size={20} />}
        </button> */}
      </Content>
    </Container>
  );
};

export default InputPassword;
