import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useField } from '@unform/core';
import ReactInputMask, { Props as InputMaskProps } from 'react-input-mask';

import { Container, Content } from './styles';

interface InputProps extends InputMaskProps {
  name: string;
  title?: string;
}

const InputMask: React.FC<InputProps> = ({ name, title, ...rest }) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<ReactInputMask>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setValue(ref: any, value: string) {
        ref.setInputValue(value || '');
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      clearValue(ref: any) {
        ref.setAttribute('value', '');
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {title && <strong>{title}</strong>}

      <Content isErrored={!!error} isFocused={isFocused}>
        <ReactInputMask
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          maskChar={null}
          ref={inputRef}
          {...rest}
        />
      </Content>
    </Container>
  );
};

export default InputMask;
