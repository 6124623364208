import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { MdError } from 'react-icons/md';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { ComorbiditiesSchema } from '../../../../validations/ComorbiditiesSchema';

import { useAuth } from '../../../../hooks/auth';
import { Individuo } from '../../../../models/individuo';
import IndividuoComorbidadeService from '../../../../services/IndividuoComorbidadeService';
import IndividuoService from '../../../../services/IndividuoService';

import Button from '../../../../components/Button';
import InputTrueOrFalse from '../../../../components/InputTrueOrFalse';
import Input from '../../../../components/Input';
import CardGlobal from '../../../../components/CardGlobal';
import Loading from '../../../../components/Loading';

import { Container, LoadingForm, Form, Error } from './styles';

const Comorbidities: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { id } = useAuth();

  const [formData, setFormData] = useState<Individuo>();
  const [loadingButton, setLoadingButton] = useState(false);
  const [hasFluSymptoms, setHasFluSymptoms] = useState<boolean>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (id) {
      IndividuoService.getById(id).then(response => {
        if (response.sintomasGripais) {
          setHasFluSymptoms(true);
        }

        setFormData(response);
      });
    }
  }, [id]);

  useEffect(() => {
    if (formData) {
      formRef.current?.setData({
        ...formData,
        dataInicioSintomas: formData.dataInicioSintomas
          ? format(new Date(formData.dataInicioSintomas), 'yyyy-MM-dd')
          : undefined,
      });
    }
  }, [formData]);

  const handleNext = useCallback(
    async (data: Individuo) => {
      setLoadingButton(true);

      if (data.sintomasGripais === false) {
        data.dataInicioSintomas = undefined;
      }

      try {
        formRef.current?.setErrors({});

        await ComorbiditiesSchema.validate(data, {
          abortEarly: false,
        });

        if (id) {
          await IndividuoComorbidadeService.update(id, data);
        }

        toast.success('Dados salvos com sucesso!');

        setLoadingButton(false);
      } catch (err) {
        setLoadingButton(false);

        if (err instanceof Yup.ValidationError) {
          setError('Responda todas as perguntas antes de enviar o formulário.');
        } else {
          setError('Erro ao enviar o formulário.');
        }
      }
    },
    [id],
  );

  return (
    <Container>
      <CardGlobal>
        <h1>Questionário de comorbidades</h1>

        <p>
          Olá. Vamos fazer uma série de perguntas para você que são muito
          importantes para a gente avaliar sua saúde e lhe oferecer a melhor
          orientação e atendimento possível, se necessário.
        </p>

        <hr />

        {!formData ? (
          <LoadingForm>
            <Loading size={60} />
          </LoadingForm>
        ) : (
          <Form ref={formRef} onSubmit={handleNext}>
            <div>
              <h3>Você só precisará responder essas perguntas uma única vez</h3>

              <InputTrueOrFalse
                name="transplantado"
                question="Você já fez algum transplante?"
              />

              <InputTrueOrFalse
                name="doencaComprometeImunidade"
                question="Você tem alguma doença que compromete sua imunidade (HIV, por exemplo)?"
              />

              <InputTrueOrFalse
                name="fumante"
                question="Você é fumante (tabagista)?"
              />
            </div>

            <div>
              <h3>
                Você faz uso regular de medicamento e/ou acompanhamento médico
                para algum dos problemas abaixo:
              </h3>

              <InputTrueOrFalse
                name="hipertenso"
                question="Tem hipertensão (tem pressão alta) e precisa tomar medicação?"
              />

              <InputTrueOrFalse name="asma" question="Você tem asma?" />

              <InputTrueOrFalse
                name="diabetes"
                question="Você tem diabetes (açúcar no sangue)?"
              />

              <InputTrueOrFalse
                name="doencaPulmao"
                question="Você tem doença do pulmão?"
              />

              <InputTrueOrFalse
                name="doencaCoracao"
                question="Você tem alguma doença do coração?"
              />

              <InputTrueOrFalse
                name="doencaRins"
                question="Você tem alguma doença dos rins?"
              />

              <InputTrueOrFalse
                name="doencaFigado"
                question="Você tem alguma doença do fígado?"
              />

              <InputTrueOrFalse
                name="sintomasGripais"
                question="Você está com sintomas gripais?"
                onClick={value => setHasFluSymptoms(value)}
              />
            </div>

            {hasFluSymptoms && (
              <Input
                name="dataInicioSintomas"
                title="Há quanto tempo?"
                type="date"
                max={new Date().toISOString().split('T')[0]}
              />
            )}

            {error && (
              <Error>
                <MdError size={20} />
                <strong>{error}</strong>
              </Error>
            )}

            <Button type="submit" btnType="gray" loading={loadingButton}>
              Salvar
            </Button>
          </Form>
        )}
      </CardGlobal>
    </Container>
  );
};

export default Comorbidities;
