import styled from 'styled-components';
import { gridBreakpoints } from '../../styles/theme/gridBreakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.25rem;

  :last-child {
    display: none;

    @media screen and (max-width: ${gridBreakpoints.sm}) {
      display: flex;
    }
  }

  span {
    font-weight: bold;
    font-size: 0.938rem;
  }
`;
