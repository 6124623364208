const getPartiallyHiddenString = (text: string): string => {
  const { length } = text;
  if (length === 1) {
    return '*';
  }
  const hideLength = Math.floor(length / 2);
  const textToHide = text.substr(
    hideLength - Math.floor(hideLength / 2),
    hideLength,
  );

  return text.replace(textToHide, '*'.repeat(hideLength));
};

export { getPartiallyHiddenString };
