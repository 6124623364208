import * as Yup from 'yup';
import { REQUIRED, SAME_PASSWORD } from '../constants/error';

export const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required(REQUIRED)
    .matches(
      /^(?=.*[0-9])(?=.*[a-z]).{8,}/,
      'Utilize pelo menos uma letra e um número.',
    ),
  repeat: Yup.string()
    .required(REQUIRED)
    .matches(
      /^(?=.*[0-9])(?=.*[a-z]).{8,}/,
      'Utilize pelo menos uma letra e um número.',
    )
    .oneOf([Yup.ref('password'), undefined], SAME_PASSWORD),
});
