import React, { ButtonHTMLAttributes } from 'react';

import Loading from '../Loading';

import { Container, btnColorType } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  btnType?: btnColorType;
  filled?: boolean;
  squared?: boolean;
  loading?: boolean;
  textSize?: number;
}

const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  loading,
  ...rest
}) => {
  return (
    <Container disabled={disabled || loading} $loading={loading} {...rest}>
      {loading ? <Loading size={rest.textSize ?? 20} /> : <>{children}</>}
    </Container>
  );
};

export default Button;
