/* eslint-disable react/jsx-curly-newline */
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { toast } from 'react-toastify';

import CardGlobal from '../../../components/CardGlobal';
import Loading from '../../../components/Loading';

import Map from './Map';

import EstabelecimentosService from '../../../services/EstabelecimentosService';
import { Estabelecimento } from '../../../models/estabelecimento';
import { Notificacao } from '../../../models/notificacao';

import getUserPosition from '../../../utils/getUserPosition';

import { Container, MapContainer } from './styles';

export interface UserPosition {
  latitude: number;
  longitude: number;
}

const Campinas = {
  latitude: -22.908167,
  longitude: -47.061276,
};

const ServicesNearYou: React.FC = () => {
  const [userCurrentPosition, setUserCurrentPosition] = useState(
    {} as UserPosition,
  );
  const [userPositionFound, setUserPositionFound] = useState<boolean>();
  const [UBSData, setUBSData] = useState<Estabelecimento[]>([]);
  const [loading, setLoading] = useState<boolean>();

  const GetAllUBS = async () => {
    try {
      const UBSList = await EstabelecimentosService.getAll();
      setUBSData(UBSList);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        const { data } = error.response as {
          data: { notifications: Notificacao[] } | string;
        };

        if (typeof data === 'string') {
          toast.error(data as string);
        } else if (data.notifications && data.notifications.length > 0) {
          data.notifications.forEach(notification => {
            toast.error(notification.message);
          });
        } else {
          toast.error('Tivemos um problema.');
        }
      } else {
        toast.error('Tivemos um problema.');
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    GetAllUBS();
    getUserPosition()
      .then(response => {
        setUserCurrentPosition({
          latitude: response.coords.latitude,
          longitude: response.coords.longitude,
        });
        setUserPositionFound(true);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Não foi possível acessar sua localização.');
        setUserPositionFound(false);
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      <CardGlobal>
        <h1>Unidades básicas de saúde próximas a você</h1>
        <MapContainer>
          {loading ? (
            <Loading size={50} spinnerColor="gray" />
          ) : userPositionFound ? (
            <Map center={userCurrentPosition} UBSData={UBSData} />
          ) : (
            <Map center={Campinas} UBSData={UBSData} />
          )}
        </MapContainer>
      </CardGlobal>
    </Container>
  );
};

export default ServicesNearYou;
