import React, { useCallback, useState, useEffect } from 'react';
import { BiMicrophone, BiMicrophoneOff } from 'react-icons/bi';
import { MdOutlineCallEnd } from 'react-icons/md';
import { toast } from 'react-toastify';
import { uuid } from 'uuidv4';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { isSameHour, isSameMinute } from 'date-fns';
import { Client } from 'twilio-chat';
import { Channel } from 'twilio-chat/lib/channel';
import { Message } from 'twilio-chat/lib/message';
import {
  BsCameraVideo,
  BsCameraVideoOff,
  BsFillChatLeftTextFill,
  BsFillChatLeftFill,
} from 'react-icons/bs';
import {
  connect,
  Participant as ParticipantProps,
  Room as RoomVideoProps,
} from 'twilio-video';

import { useRouteRules } from '../../../hooks/routeRules';
import TeleConsultaService from '../../../services/TeleConsultaService';
import AgendamentoService from '../../../services/AgendamentoService';

import CardGlobal from '../../../components/CardGlobal';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import Chat from './Chat';
import Participant from './Participant';

import {
  ToggleChat,
  Container,
  LocalVideoContainer,
  RemoteVideoContainer,
  MainArea,
  ControlsArea,
  NoVideo,
} from './styles';

export interface MessageSectionProps {
  id: string;
  userId: string;
  hour: Date;
  messages: {
    id: string;
    message: string;
  }[];
}

interface RouteParams {
  agendamentoId: string;
}

export type UsersType = {
  professional?: string;
  [key: string]: string | undefined;
};

const Consulta: React.FC = () => {
  const history = useHistory();
  const { params } = useRouteMatch<RouteParams>();
  const { verifyRules } = useRouteRules();

  const { agendamentoId } = params;
  const [microphoneEnabled, setMicrophoneEnabled] = useState(true);
  const [cameraEnabled, setCameraEnabled] = useState(true);
  const [chatVisible, setChatVisible] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [roomVideo, setRoomVideo] = useState<RoomVideoProps | null>(null);
  const [videoParticipants, setVideoParticipants] = useState<
    ParticipantProps[]
  >([]);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [messages, setMessages] = useState<MessageSectionProps[]>([]);
  const [channelChat, setChannelChat] = useState<Channel | null>(null);
  const [users, setUsers] = useState<UsersType>();

  const handleMessageAdded = (message: Message) => {
    setHasNewMessage(state => !state && true);

    setMessages(state => {
      const currentHour = new Date();

      if (
        state.length > 0 &&
        state[state.length - 1].userId === message.author &&
        isSameHour(state[state.length - 1].hour, currentHour) &&
        isSameMinute(state[state.length - 1].hour, currentHour)
      ) {
        const newState = state.slice(0, state.length - 1);

        const newMessages = {
          ...state[state.length - 1],
          messages: [
            ...state[state.length - 1].messages,
            {
              id: uuid(),
              message: message.body,
            },
          ],
        };

        return [...newState, newMessages];
      }
      const newState = [...state];

      newState.push({
        id: uuid(),
        userId: message.author,
        messages: [
          {
            id: uuid(),
            message: message.body,
          },
        ],
        hour: new Date(),
      });

      return newState;
    });
  };

  const joinChannel = async (channel: Channel) => {
    if (channel.status !== 'joined') {
      await channel.join();
    }

    setChannelChat(channel);
  };

  const sendMessage = useCallback(
    (message: string) => {
      if (channelChat) {
        channelChat.sendMessage(message);
      } else {
        toast.error('Não foi possível enviar a mensagem');
      }
    },
    [channelChat],
  );

  useEffect(() => {
    if (token) {
      Client.create(token).then(room => {
        room.on('tokenAboutToExpire', async () => {
          room.updateToken(token);
        });

        room.on('tokenExpired', async () => {
          room.updateToken(token);
        });

        room.on('messageAdded', (message: Message) => {
          handleMessageAdded(message);
        });

        try {
          room.getChannelByUniqueName(params.agendamentoId).then(response => {
            joinChannel(response);
            setChannelChat(response);
          });
        } catch (err) {
          try {
            room
              .createChannel({
                uniqueName: params.agendamentoId,
                friendlyName: params.agendamentoId,
              })
              .then(response => {
                joinChannel(response);
              });
          } catch {
            throw new Error(
              'Unable to create channel, please reload this page',
            );
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const participantConnected = (participant: ParticipantProps) => {
    setVideoParticipants(state => [...state, participant]);
  };

  const handleConnectCall = useCallback(
    async (token: string, roomName: string) => {
      connect(token, {
        name: roomName,
      })
        .then(room => {
          setRoomVideo(room);
          // console.log('room1', room);
          room.on('participantConnected', participant => {
            setVideoParticipants(state => [...state, participant]);
          });
          room.on('participantDisconnected', participant => {
            setVideoParticipants(state => state.filter(p => p !== participant));
          });
          // console.log('room2', room);
          room.participants.forEach(participant => {
            console.log(participant);
            participantConnected(participant);
          });
          // console.log('room3', room);
        })
        .catch(() => toast.error('Não foi possível se conectar à chamada'));
    },
    [],
  );

  const handleDisconnectCall = useCallback(
    (redirectToAppointments?: boolean) => {
      if (roomVideo && roomVideo.localParticipant.state === 'connected') {
        roomVideo.localParticipant.tracks.forEach(trackPublication => {
          trackPublication.track;
        });
        roomVideo.disconnect();
      }

      if (redirectToAppointments) {
        history.push(`/area/agendamento`);
      }
    },
    [roomVideo, history],
  );

  const getTokenAndStartAppointment = useCallback(async () => {
    try {
      await TeleConsultaService.create(params.agendamentoId);

      const appointment = await TeleConsultaService.getTeleConsultaById(
        params.agendamentoId,
      );

      setToken(appointment.token);
    } catch {
      toast.error('Não foi possível se conectar à consulta');
    }
  }, [params.agendamentoId]);

  useEffect(() => {
    verifyRules({
      allowThesePreviousRoutes: [
        '/area/agendamento',
        '/area/agendamento/detalhes',
      ],
      onError: () => {
        history.push('/area/agendamento');
      },
    });

    AgendamentoService.getById(params.agendamentoId).then(response => {
      setUsers({
        professional: response.profissional?.nome,
        [`${response.individuoId}`]: 'Você',
        [`${response.profissionalId}`]:
          response.profissional?.nome || 'Desconhecido',
      });
    });

    TeleConsultaService.getTeleConsultaById(params.agendamentoId)
      .then(response => {
        setToken(response.token);
      })
      .catch(() => {
        getTokenAndStartAppointment();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token && agendamentoId) {
      handleConnectCall(token, agendamentoId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendamentoId, token]);

  useEffect(() => {
    return () => {
      handleDisconnectCall();
    };
  }, [handleDisconnectCall]);

  const handleToggleMicrophone = useCallback(() => {
    if (roomVideo) {
      roomVideo.localParticipant.audioTracks.forEach(publication => {
        if (microphoneEnabled) {
          publication.track.disable();
        } else {
          publication.track.enable();
        }

        setMicrophoneEnabled(state => !state);
      });
    }
  }, [roomVideo, microphoneEnabled]);

  const handleToggleCamera = useCallback(() => {
    if (roomVideo) {
      roomVideo.localParticipant.videoTracks.forEach(publication => {
        if (cameraEnabled) {
          publication.track.disable();
        } else {
          publication.track.enable();
        }

        setCameraEnabled(state => !state);
      });
    }
  }, [roomVideo, cameraEnabled]);

  const handleToggleChat = useCallback(() => {
    setChatVisible(state => !state);

    setHasNewMessage(state => state && !state);
  }, []);

  return (
    <Container>
      <CardGlobal>
        <h1>Teleconsulta</h1>

        <LocalVideoContainer>
          {videoParticipants.length === 1 ? (
            <Participant
              key={videoParticipants[0].sid}
              participant={videoParticipants[0]}
              type="remote"
              participantName={users?.professional}
            />
          ) : (
            <NoVideo videoType="local">
              <span>Aguardando o médico...</span>
            </NoVideo>
          )}

          <MainArea chatVisible={chatVisible}>
            <RemoteVideoContainer canHide={chatVisible}>
              {roomVideo ? (
                <Participant
                  key={roomVideo.localParticipant.sid}
                  participant={roomVideo.localParticipant}
                  type="local"
                />
              ) : (
                <></>
              )}
            </RemoteVideoContainer>

            {chatVisible && (
              <Chat
                messages={messages}
                sendMessage={sendMessage}
                users={users}
                closeChat={handleToggleChat}
              />
            )}
          </MainArea>
        </LocalVideoContainer>

        <ControlsArea>
          <div />

          <div>
            <Button
              onClick={handleToggleMicrophone}
              btnType="gray"
              disabled={!roomVideo}
            >
              {microphoneEnabled ? <BiMicrophone /> : <BiMicrophoneOff />}
            </Button>

            <Button
              btnType="red"
              disabled={!roomVideo}
              onClick={() => handleDisconnectCall(true)}
            >
              <MdOutlineCallEnd />
            </Button>

            <Button
              onClick={handleToggleCamera}
              btnType="gray"
              disabled={!roomVideo}
            >
              {cameraEnabled ? <BsCameraVideo /> : <BsCameraVideoOff />}
            </Button>
          </div>

          <ToggleChat
            type="button"
            onClick={handleToggleChat}
            isActive={chatVisible}
            hasNewMessage={hasNewMessage && !chatVisible}
          >
            {chatVisible ? <BsFillChatLeftFill /> : <BsFillChatLeftTextFill />}
          </ToggleChat>
        </ControlsArea>
      </CardGlobal>
    </Container>
  );
};

export default Consulta;
