import styled from 'styled-components';
import CardGlobal from '../../../../components/CardGlobal';

export const Content = styled(CardGlobal)`
  min-width: 800px;

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.lg}) {
    min-width: min-content;
  }
`;
