import { AxiosResponse } from 'axios';
import { api } from './Api';
import { ApiObject } from '../models/apiObject';
import { EstabelecimentoProcedimento } from '../models/estabelecimentoProcedimento';
import IGetReturn from '../models/IGetReturn';
import { Estabelecimento } from '../models/estabelecimento';
import { Procedimento } from '../models/procedimento';

const getAll = async (): Promise<EstabelecimentoProcedimento[]> => {
  const { data } = await api.get<ApiObject<EstabelecimentoProcedimento>>(
    'Estabelecimentos/Procedimentos',
  );

  if (!data) {
    return [];
  }

  return data.items;
};

const getDias = async (
  skip = 1,
  take = 10,
  estabelecimentoId: string,
  procedimentoId: string,
  tipoDaConsulta: string,
  profissionalId: string,
): Promise<ApiObject<EstabelecimentoProcedimento[]>> => {
  const response = await api.get<ApiObject<EstabelecimentoProcedimento[]>>(
    '/EstabelecimentosProcedimentosHorarios',
    {
      params: {
        skip,
        take,
        estabelecimentoId,
        procedimentoId,
        tipoDaConsulta,
        profissionalId,
      },
    },
  );
  return response.data;
};

const getHoras = async (
  skip = 1,
  take = 10,
  estabelecimentoId: string,
  procedimentoId: string,
  tipoDaConsulta: string,
  profissionalId: string,
  dia: string,
): Promise<ApiObject<EstabelecimentoProcedimento[]>> => {
  const response = await api.get<ApiObject<EstabelecimentoProcedimento[]>>(
    '/EstabelecimentosProcedimentosHorarios',
    {
      params: {
        skip,
        take,
        estabelecimentoId,
        procedimentoId,
        tipoDaConsulta,
        profissionalId,
        dataInicial: dia,
        dataFinal: dia,
      },
    },
  );
  return response.data;
};

export default { getAll, getDias, getHoras };
