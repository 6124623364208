import { AxiosResponse } from 'axios';
import { Agendamento } from '../models/agendamento';
import { ApiObject } from '../models/apiObject';
import { api } from './Api';

interface GetRequestProps {
  skip?: number;
  take?: number;
  individuoId?: string | undefined;
  finalizado?: boolean | undefined;
  cancelado?: boolean | undefined;
  inProgress?: boolean;
}

async function get(
  skip = 1,
  take = 10,
  individuoId: string | undefined,
  finalizado: boolean | undefined,
  cancelado: boolean | undefined,
  inProgress?: boolean,
): Promise<ApiObject<Agendamento[]>> {
  const response = await api.get<
    unknown,
    AxiosResponse<ApiObject<Agendamento[]>>
  >('/agendamento', {
    params: {
      skip,
      take,
      individuoId,
      finalizado,
      cancelado,
      emandamento: inProgress,
      sort: 'dia',
    },
  });
  return response.data;
}

const getAll = async ({
  individuoId,
  finalizado,
  inProgress,
}: GetRequestProps): Promise<Agendamento[]> => {
  const { data } = await api.get<ApiObject<Agendamento>>('/Agendamento', {
    params: {
      individuoId,
      finalizado,
      emandamento: inProgress,
      sort: 'dia',
    },
  });
  return data.items;
};

const getById = async (id: string): Promise<Agendamento> => {
  const { data } = await api.get<Agendamento>(`/Agendamento/${id}`);
  return data;
};

const create = async (agendamento: Agendamento): Promise<Agendamento> => {
  const { data } = await api.post<Agendamento>('Agendamento', agendamento);
  return data;
};

export default { get, getAll, getById, create };
