import React, {
  TextareaHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';

import { Container, Content } from './styles';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  title?: string;
}

const TextArea: React.FC<TextAreaProps> = ({ name, title, ...rest }) => {
  const [isFocused, setIsFocused] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleTextAreaFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleTextAreaBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textAreaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {title && <strong>{title}</strong>}

      <Content isErrored={!!error} isFocused={isFocused}>
        <textarea
          onFocus={handleTextAreaFocus}
          onBlur={handleTextAreaBlur}
          defaultValue={defaultValue}
          ref={textAreaRef}
          {...rest}
        />
      </Content>
    </Container>
  );
};

export default TextArea;
