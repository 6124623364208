import React from 'react';
import { AuthProvider } from './auth';
import { RouteRulesProvider } from './routeRules';

const AppProvider: React.FC = ({ children }) => (
  <RouteRulesProvider>
    <AuthProvider>{children}</AuthProvider>
  </RouteRulesProvider>
);

export default AppProvider;
