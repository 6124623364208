import React, { useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

import EstabelecimentosService from '../../../../services/EstabelecimentosService';
import { googleLink } from '../../../../config/linkGoogleMaps';
import { Estabelecimento } from '../../../../models/estabelecimento';

import ListProfessionals from './Screens/ListProfessionals';
import DetailsUbs from './Screens/DetailsUbs';

import { Container, Content } from './styles';

interface LinkParams {
  id: string;
}

export interface UBSDataProps extends Estabelecimento {
  endereco: string;
  linkGoogleMaps: string;
}

type ShowCardType = 'detailsUbs' | 'listProfessionals';

const UBS: React.FC = () => {
  const { params } = useRouteMatch<LinkParams>();
  const history = useHistory();

  const [UBSData, setUBSData] = useState<UBSDataProps>();
  const [showCard, setShowCard] = useState<ShowCardType>('detailsUbs');

  useEffect(() => {
    EstabelecimentosService.getById(params.id)
      .then(response => {
        const endereco = `${response.logradouro}, ${response.numero}, ${response.bairro}`;

        const linkGoogleMaps = `${googleLink}${response.nomeFantasia}+${response.numero}+${response.logradouro}+${response.bairro}+${response.uf}`;

        setUBSData({ ...response, endereco, linkGoogleMaps });
      })
      .catch(() => history.push('/error'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return (
    <Container>
      <Content>
        {showCard === 'detailsUbs' ? (
          <DetailsUbs
            UBSData={UBSData}
            setShowCard={() => setShowCard('listProfessionals')}
          />
        ) : (
          UBSData && (
            <ListProfessionals
              listProfissionais={UBSData.profissionais}
              setShowCard={() => setShowCard('detailsUbs')}
            />
          )
        )}
      </Content>
    </Container>
  );
};

export default UBS;
