import styled from 'styled-components';
import CardGlobal from '../../../../components/CardGlobal';

export const Content = styled(CardGlobal)`
  min-width: 800px;
  display: flex;
  flex-direction: column;

  margin-top: 30px;
  width: 100%;

  > button {
    margin-top: 20px;
    width: 100%;
    max-width: 200px;
  }

  p {
    margin: 15px 0 25px;
  }

  div > button + button {
    margin-left: 15px;
  }

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.lg}) {
    min-width: min-content;
  }

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    align-items: center;

    h2,
    p {
      text-align: center;
    }

    div > button + button {
      margin-top: 5px;
      margin-left: 0;
    }

    div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
    }
  }
`;
