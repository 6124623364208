import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Container, Content } from './styles';
import Button from '../../../../components/Button';
import getValidationErrors from '../../../../utils/getValidationErrors';
import InputPassword from '../../../../components/InputPassword';
import InputCheckbox from '../../../../components/InputCheckbox';
import IndividuoSenhaService from '../../../../services/IndividuoSenhaService';
import { useRouteRules } from '../../../../hooks/routeRules';
import { Notificacao } from '../../../../models/notificacao';

interface FormData {
  password: string;
  repeat: string;
  terms: boolean;
}

const CreatePassword: React.FC = () => {
  const { verifyRules } = useRouteRules();
  const history = useHistory();
  const { state } = useLocation();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleNext = useCallback(
    async (data: FormData) => {
      setLoading(true);
      if (data.terms) {
        try {
          formRef.current?.setErrors({});

          const schema = Yup.object().shape({
            password: Yup.string()
              .required('A senha é obrigatória.')
              .matches(
                /^(?=.*[0-9])(?=.*[a-z]).{8,}/,
                'O campo deve ter no mínimo 8 caracteres, 1 letra e 1 número.',
              ),
            repeat: Yup.string()
              .required('A senha é obrigatória.')
              .oneOf(
                [Yup.ref('password'), null],
                'As senhas devem ser iguais.',
              ),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          await IndividuoSenhaService.create(state.id, {
            senha: btoa(data.password),
            confirmacao: btoa(data.repeat),
          });

          setLoading(false);
          toast.success('Cadastro realizado com sucesso.');
          history.push('/');
        } catch (error) {
          setLoading(false);
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
          } else if (axios.isAxiosError(error) && error.response?.data) {
            const { data } = error.response as {
              data: { notifications: Notificacao[] } | string;
            };

            if (typeof data === 'string') {
              toast.error(data as string);
            } else if (data.notifications && data.notifications.length > 0) {
              data.notifications.forEach(notification => {
                toast.error(notification.message);
              });
            } else {
              toast.error('Tivemos um problema.');
            }
          } else {
            toast.error('Tivemos um problema.');
          }
        }
      } else {
        toast.error(
          'Aceite o Termo de Confiabilidade e a Politica de Privacidade.',
        );
      }
    },
    [history, state],
  );

  useEffect(() => {
    verifyRules({
      allowThesePreviousRoutes: ['/cadastro/autenticar/confirmar'],
      onError: () => {
        history.push('/cadastro');
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Content>
        <h3>Criação de senha</h3>

        <Form ref={formRef} onSubmit={handleNext}>
          <InputPassword name="password" title="Senha" />
          <InputPassword name="repeat" title="Confirmar senha" />
          <InputCheckbox name="terms">
            Aceitar
            <a href="/termo-de-confiabilidade" target="_blank">
              Termo de Confiabilidade
            </a>
            e
            <a href="/politica-de-privacidade" target="_blank">
              Politica de Privacidade
            </a>
          </InputCheckbox>
          <Button type="submit" btnType="gray" loading={loading}>
            Finalizar
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default CreatePassword;
