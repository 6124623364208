import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 30px;

  > div {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    svg {
      width: 250px;
      height: 250px;
      color: ${({ theme }) => theme.colors.grayPrimaryLight};

      @media screen and (max-width: ${({ theme }) =>
          theme.gridBreakpoints.sm}) {
        width: 150px;
        height: 150px;
      }
    }

    h1,
    span {
      text-align: center;
    }

    div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      margin-top: 1rem;

      button {
        min-width: 190px;
      }
    }
  }
`;
