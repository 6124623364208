import styled from 'styled-components';
import { gridBreakpoints } from '../../styles/theme/gridBreakpoints';

export const Container = styled.div`
  width: 100%;
`;

export const TableContainer = styled.table`
  border-collapse: collapse;
  font-size: 0.9em;
  min-width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px 5px 0 0;

  thead tr {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #ffffff;
    font-weight: bold;
    text-align: left;
  }

  th,
  td {
    padding: 12px 15px;
  }

  td:last-child {
    padding: 0;
  }

  tbody tr {
    border-bottom: 1px solid #dddddd;
    color: ${({ theme }) => theme.colors.graySecondaryDark};
  }

  tbody tr:last-of-type {
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: ${gridBreakpoints.md}) {
    display: none;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
`;

export const CardContent = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  line-height: 1.4rem;

  padding: 1rem;
  border-radius: 6px;

  background: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  cursor: pointer;

  @media (min-width: ${gridBreakpoints.md}) {
    display: none;
  }
`;
