import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 50px;

  h1,
  h2 {
    font-weight: 400;
  }

  form {
    padding: 0;
    margin: 30px 0;
  }

  h2 {
    padding: 15px 0;
  }

  div {
    margin: 0 0 25px 0;
  }

  div button + button {
    margin-left: 40px;
  }

  @media (max-width: 912px) {
    div {
      margin: 0;

      display: flex;
      flex-direction: column;
      gap: 30px;

      button {
        width: 100%;
      }

      button + button {
        margin-left: unset;
      }
    }

    > button {
      margin-top: 40px;
    }
  }
`;
