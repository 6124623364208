// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getUserPosition = async (): Promise<any> => {
  return new Promise((res, rej) => {
    navigator.geolocation.getCurrentPosition(res, rej, {
      enableHighAccuracy: true,
      timeout: 10000,
    });
  });
};

export default getUserPosition;
