import * as Yup from 'yup';
import { REQUIRED, EMAIL_INVALID } from '../constants/error';
import getValidationCPF from '../utils/getValidationCPF';

export const ProfileSchema = Yup.object().shape({
  cpf: Yup.string()
    .required(REQUIRED)
    .length(11, 'Digite um CPF válido')
    .test('cpf', 'Digite um CPF válido', value => getValidationCPF(value)),
  name: Yup.string().required(REQUIRED),
  sName: Yup.string(),
  bday: Yup.date().required(REQUIRED),
  email: Yup.string().required(REQUIRED).email(EMAIL_INVALID),
  phone: Yup.string().required(REQUIRED),
  genre: Yup.string().required(REQUIRED),
  breed: Yup.string().required(REQUIRED),
  motherName: Yup.string().required(REQUIRED),
  cep: Yup.string()
    .required(REQUIRED)
    .test(
      'length',
      'Digite um CEP válido',
      value => !!(!value || value.length === 8),
    ),
  state: Yup.string().required(REQUIRED),
  city: Yup.string().required(REQUIRED),
  neighborhood: Yup.string().required(REQUIRED),
  street: Yup.string().required(REQUIRED),
  numberAddress: Yup.string(),
  addressComplement: Yup.string(),
});
