import styled from 'styled-components';
import { Form as FormDefauld } from '@unform/web';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 10px;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;

    h1 {
      color: ${({ theme }) => theme.colors.graySecondaryDark};
      margin-bottom: 20px;
    }

    p {
      color: ${({ theme }) => theme.colors.graySecondary};
    }

    hr {
      margin: 20px 0 30px;
    }

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      padding: 20px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    height: calc(100vh - 75px);
  }
`;

export const LoadingForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  min-height: 150px;
`;

export const Form = styled(FormDefauld)`
  display: flex;
  flex-direction: column;

  height: 100%;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${props => props.theme.colors.grayPrimaryLight};
  }

  > div {
    margin-bottom: 30px;

    h3 {
      margin-bottom: 20px;
      color: ${({ theme }) => theme.colors.graySecondary};
    }
  }

  > button {
    margin: 0 auto;
    width: 100%;
    max-width: 350px;
  }

  @media screen and (min-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    overflow-y: auto;
    padding-right: 10px;
  }
`;

export const Error = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  color: ${({ theme }) => theme.colors.danger};
  margin: 0 auto 30px;

  svg {
    margin-right: 10px;
  }
`;
