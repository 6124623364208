import styled, { css } from 'styled-components';

export const Container = styled.div`
  > button > span {
    color: ${({ theme }) => theme.colors.graySecondaryDark};
  }

  & + div {
    margin-top: 10px;
  }
`;

interface ButtonProps {
  isExpanded: boolean;
}

export const Button = styled.button<ButtonProps>`
  width: 100%;
  padding: 0.8rem 0.5rem;
  text-align: left;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.colors.graySecondaryLight};
  border-radius: 4px;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border: 2px solid ${({ theme }) => theme.colors.primary};
    `}

  transition: border 0.1s;

  font-size: 1.2rem;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }

  svg {
    min-width: 24px;
    min-height: 24px;
    color: ${({ theme }) => theme.colors.primary};
  }

  strong {
    overflow: hidden;
    white-space: ${({ isExpanded }) => (isExpanded ? 'wrap' : 'nowrap')};
    text-overflow: ellipsis;
  }
`;

interface ContentProps {
  isExpanded: boolean;
}

export const Content = styled.div<ContentProps>`
  width: 100%;
  padding: 0.8rem 0.5rem;

  border: 1px solid ${({ theme }) => theme.colors.graySecondaryLight};

  display: ${({ isExpanded }) => (isExpanded ? 'flex' : 'none')};
`;
