const getValidationCPF = (strCPF: string | null | undefined): boolean => {
  if (!strCPF) {
    return false;
  }

  let Soma;
  let Resto;
  Soma = 0;
  const newStrCPF = strCPF.replace(/\./g, '').replace('-', '');

  if (
    newStrCPF.length !== 11 ||
    newStrCPF === '00000000000' ||
    newStrCPF === '11111111111' ||
    newStrCPF === '22222222222' ||
    newStrCPF === '33333333333' ||
    newStrCPF === '44444444444' ||
    newStrCPF === '55555555555' ||
    newStrCPF === '66666666666' ||
    newStrCPF === '77777777777' ||
    newStrCPF === '88888888888' ||
    newStrCPF === '99999999999'
  )
    return false;

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 9; i++)
    Soma += parseInt(newStrCPF.substring(i - 1, i), 10) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(newStrCPF.substring(9, 10), 10)) return false;

  Soma = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 10; i++)
    Soma += parseInt(newStrCPF.substring(i - 1, i), 10) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(newStrCPF.substring(10, 11), 10)) return false;
  return true;
};

export default getValidationCPF;
