import React, { useCallback, useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import ButtonOption from '../../../../components/ButtonOption';
import Button from '../../../../components/Button';

import { Container } from './styles';
import IndividuoCodigoAutenticacaoService from '../../../../services/IndividuoCodigoAutenticacaoService';
import { useRouteRules } from '../../../../hooks/routeRules';
import { Notificacao } from '../../../../models/notificacao';

const Auth: React.FC = () => {
  const { verifyRules } = useRouteRules();
  const { state } = useLocation();
  const history = useHistory();

  const [buttonSelected, setButtonSelected] = useState<
    'SMS' | 'Email' | 'WhatsApp'
  >();

  const handleValidate = useCallback(async () => {
    try {
      if (!buttonSelected || !state?.id) {
        return;
      }
      await IndividuoCodigoAutenticacaoService.getCode(
        state.id,
        buttonSelected,
      );
      state.method = buttonSelected;

      history.push(
        `/cadastro/autenticar/confirmar?type=${buttonSelected}`,
        state,
      );
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        const { data } = error.response as {
          data: { notifications: Notificacao[] } | string;
        };

        if (typeof data === 'string') {
          toast.error(data as string);
        } else if (data.notifications && data.notifications.length > 0) {
          data.notifications.forEach(notification => {
            toast.error(notification.message);
          });
        } else {
          toast.error('Tivemos um problema.');
        }
      } else {
        toast.error('Tivemos um problema.');
      }
    }
  }, [buttonSelected, history, state]);

  useEffect(() => {
    verifyRules({
      allowThesePreviousRoutes: ['/cadastro/concluir'],
      onError: () => {
        history.push('/cadastro');
      },
      onSuccess: () => {
        if (!state?.id) {
          history.push('/cadastro');
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <h1>Solicitação de código de autenticação</h1>
      <h2>Escolha o método:</h2>

      <div>
        <ButtonOption
          onClick={() => setButtonSelected('SMS')}
          filled={buttonSelected === 'SMS'}
        >
          SMS
        </ButtonOption>
        <ButtonOption
          onClick={() => setButtonSelected('Email')}
          filled={buttonSelected === 'Email'}
        >
          E-mail
        </ButtonOption>
        <ButtonOption
          onClick={() => setButtonSelected('WhatsApp')}
          filled={buttonSelected === 'WhatsApp'}
        >
          Whatsapp
        </ButtonOption>
      </div>

      <Button
        onClick={handleValidate}
        disabled={!buttonSelected}
        btnType="gray"
      >
        Próximo
      </Button>
    </Container>
  );
};

export default Auth;
