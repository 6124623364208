import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import axios from 'axios';
import { toast } from 'react-toastify';
import getValidationCPF from '../../../utils/getValidationCPF';
import getValidationErrors from '../../../utils/getValidationErrors';

import ImgLogo from '../../../assets/atend-logo.png';
import ImgLoogo from '../../../assets/logo-footer-novetech-p.png';
import ImgLogoo from '../../../assets/logo-footer.png';
import InputMask from '../../../components/InputMask';
import Button from '../../../components/Button';
import InputPassword from '../../../components/InputPassword';

import { Container, Content } from './styles';
import { useAuth } from '../../../hooks/auth';
import { Notificacao } from '../../../models/notificacao';
import { LoginSchema } from '../../../validations/LoginSchema';

interface FormData {
  cpf: string;
  password: string;
}

const Login: React.FC = () => {
  const history = useHistory();
  const { signIn } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleNext = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        await LoginSchema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          username: data.cpf.replace(/[^0-9]/g, ''),
          password: data.password,
        });
        setLoading(false);

        history.push('/area/principal');
      } catch (error) {
        setLoading(false);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        } else if (axios.isAxiosError(error) && error.response?.data) {
          const { data } = error.response as {
            data: { notifications: Notificacao[] } | string;
          };

          if (typeof data === 'string') {
            toast.error(data as string);
          } else if (data.notifications && data.notifications.length > 0) {
            data.notifications.forEach(notification => {
              toast.error(notification.message);
            });
          } else {
            toast.error('Tivemos um problem.');
          }
        } else {
          toast.error('Tivemos um problem.');
        }
      }
    },
    [history, signIn],
  );

  return (
    <Container>
      <Content>
        <img src={ImgLogo} alt="logo atend telemedicina" />

        <Form ref={formRef} onSubmit={handleNext}>
          <InputMask name="cpf" title="CPF" mask="999.999.999-99" />

          <InputPassword name="password" title="Senha" />

          <Button type="submit" btnType="gray" loading={loading}>
            Entrar
          </Button>
        </Form>

        <div>
          <Link to="/cadastro">Primeiro acesso</Link>

          <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
        </div>

        <div className="novetech">
          <div>
            <img className="nov" src={ImgLogoo} alt="Logo da Nov" />
            <address className="loginendereco">
              <a href="http://www.novetech.com.br">www.novetech.com.br</a>
              <br />
              <p>contato@novetech.com.br</p>
            </address>
          </div>
          <div>
            <img className="nove" src={ImgLoogo} alt="Logo da Nove" />
          </div>
        </div>
      </Content>
    </Container>
  );
};

export default Login;
