import styled, { css } from 'styled-components';
import { shade } from 'polished';

import { ContainerScreens } from '../../styles';

export const Container = styled(ContainerScreens)``;

export const Details = styled.div`
  margin: 30px 0;

  > div {
    display: flex;
    flex-direction: column;

    color: ${({ theme }) => theme.colors.graySecondaryDark};

    strong {
      margin-bottom: 5px;
    }
  }
`;

const StyleDefaultToOptions = css`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 12px 12px;
  min-width: 100px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.graySecondaryDark};

  strong {
    color: #fff;
    font-size: 16px;
  }

  &:hover {
    background: ${({ theme }) => shade(0.2, theme.colors.graySecondaryDark)};
  }
`;

export const Options = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  width: 100%;

  a {
    ${StyleDefaultToOptions}

    text-decoration: none;
  }

  button {
    ${StyleDefaultToOptions}

    border: none;

    svg {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    display: flex;
    flex-direction: column;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  color: ${({ theme }) => theme.colors.graySecondaryDark};

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    background: transparent;
    border: none;

    span {
      font-size: 16px;
      color: ${({ theme }) => theme.colors.graySecondaryDark};
      margin-left: 10px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;

    button {
      margin-bottom: 20px;
    }
  }
`;
