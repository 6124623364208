import styled, { css } from 'styled-components';

interface ButtonProps {
  isActive: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  span {
    color: ${({ theme }) => theme.colors.graySecondaryDark};
  }

  & + div {
    margin-top: 20px;
  }
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  margin-top: 10px;
  max-width: 350px;
`;

export const Button = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 55px;
  width: 100%;
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;

  ${({ theme, isActive }) =>
    isActive
      ? css`
          background: #7ec7b7;
          border: 2px solid #008b6d;
          color: #fff;
        `
      : css`
          background: #e6f4f1;
          border: none;
          color: ${theme.colors.grayPrimaryDark};
        `}
`;
