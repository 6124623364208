import styled from 'styled-components';
import { gridBreakpoints } from '../../../../styles/theme/gridBreakpoints';

interface ItemProps {
  gridArea: string;
}

export const Container = styled.section`
  padding: 30px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  & > h3 {
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.graySecondaryDark};

    @media (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      text-align: center;
    }
  }
`;

export const Content = styled.div`
  padding-bottom: 30px;
  display: grid;
  grid-template-columns: 3fr 3fr 1fr 1fr 1fr 3fr;
  grid-gap: 20px;
  grid-template-areas:
    'cpf name name name name sName'
    'bday email email email email phone'
    'genre breed motherName motherName motherName motherName'
    'cep state city city city neighborhood'
    'street street numberAddress addressComplement addressComplement addressComplement';

  @media (max-width: ${gridBreakpoints.lg}) {
    grid-template-columns: 3fr 3fr 1fr 1fr 1fr 3fr;
    grid-template-areas:
      'cpf name name name name sName'
      'bday email email email email phone'
      'genre genre breed breed breed breed'
      'motherName motherName motherName motherName motherName motherName'
      'cep cep state state state state'
      'city city neighborhood neighborhood neighborhood neighborhood'
      'street street numberAddress addressComplement addressComplement addressComplement';
  }

  @media (max-width: ${gridBreakpoints.md}) {
    display: flex;
    flex-direction: column;
  }
`;

export const Item = styled.div<ItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
`;
