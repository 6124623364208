import { api } from './Api';

import { Individuo } from '../models/individuo';

const update = async (id: string, individuo: Individuo): Promise<Individuo> => {
  const { data } = await api.put<Individuo>(
    `/individuo/${id}/comorbidades`,
    individuo,
  );

  return data;
};

export default { update };
