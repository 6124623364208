import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';

import EstabelecimentosService from '../../../../services/EstabelecimentosService';
import { Estabelecimento } from '../../../../models/estabelecimento';

import CardGlobal from '../../../../components/CardGlobal';
import Loading from '../../../../components/Loading';

import { Container, ListUbs, ListItem } from './styles';
import CleanView from '../../../../components/CleanView';

interface UBSDataProps extends Estabelecimento {
  endereco: string;
}

const ListagemUbs: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [UBSData, setUBSData] = useState<UBSDataProps[]>([]);

  useEffect(() => {
    setLoading(true);
    EstabelecimentosService.getList(10, 1).then(response => {
      const newUBSData = response.map(ubs => {
        const endereco = `${ubs.bairro}, ${ubs.logradouro}, ${ubs.numero}`;

        return { ...ubs, endereco };
      });

      setUBSData(newUBSData);
      setLoading(false);
    });
  }, []);

  return (
    <Container>
      <CardGlobal>
        <h1>Unidades básicas de saúde</h1>

        <ListUbs isLoading={loading}>
          {loading ? (
            <Loading size={50} spinnerColor="gray" />
          ) : (
            <>
              {UBSData && UBSData.length > 0 ? (
                UBSData.map(ubs => (
                  <ListItem
                    type="button"
                    onClick={() => history.push(`/area/ubs/${ubs.id}`)}
                  >
                    <div>
                      <h3>{ubs.nomeFantasia}</h3>
                      <div>
                        <strong>Endereço:</strong>
                        <span>{ubs.endereco}</span>
                      </div>
                      <div>
                        <strong>Horário de funcionamento:</strong>
                        <span>Não cadastrado</span>
                      </div>
                    </div>

                    <FiChevronRight size={20} />
                  </ListItem>
                ))
              ) : (
                <CleanView text="Nenhuma Unidades básicas de saúde encontrada" />
              )}
            </>
          )}
        </ListUbs>
      </CardGlobal>
    </Container>
  );
};

export default ListagemUbs;
