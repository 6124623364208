import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import { useAuth } from '../../../../hooks/auth';
import { useRouteRules } from '../../../../hooks/routeRules';
import IndividuoService from '../../../../services/IndividuoService';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { CodeSchema } from '../../../../validations/CodeSchema';
import { Container } from '../styles';
import { Content, ResendCode } from './styles';

interface FormData {
  verificationCode: string;
}

const ConfirmCodeResetPassword: React.FC = () => {
  const { verifyRules } = useRouteRules();
  const history = useHistory();
  const { state } = useLocation();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { cpf } = useAuth();

  const handleNext = useCallback(
    async (data: FormData) => {
      setLoading(true);
      if (!cpf) {
        return;
      }
      const user = await IndividuoService.getIndividuoByCpf(cpf);
      const codigo = user.items[0].codigoAutenticacao;
      if (data.verificationCode === codigo) {
        try {
          formRef.current?.setErrors({});

          // const schema = Yup.object().shape({
          //   verificationCode: Yup.string().required(
          //     'O código de verificação é obrigatório',
          //   ),
          // });

          await CodeSchema.validate(data, {
            abortEarly: false,
          });

          setLoading(false);

          history.push('/area/profile/alterar-senha/escolher', {
            codigoAutenticacao: data.verificationCode,
          });
        } catch (err) {
          setLoading(false);

          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
          }
        }
      } else {
        setLoading(false);
        toast.error('Código de verificação inválido.');
      }
    },
    [history],
  );

  const handleReSend = useCallback(() => {
    history.push('/area/profile/alterar-senha/verificar');
  }, [history]);

  useEffect(() => {
    verifyRules({
      allowThesePreviousRoutes: ['/area/profile/alterar-senha/verificar'],
      onError: () => {
        history.push('/area/profile/alterar-senha');
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /*
  VISUALIZAR CODIGO AUTENTICACAO PARA TESTES
  const getCode = useCallback(async () => {
    if (!cpf) return;
    const user = await IndividuoService.getIndividuoByCpf(cpf);
    console.log('user', user);
    const codigo = user.items[0].codigoAutenticacao;
    console.log(codigo);
  }, [cpf]);

  useEffect(() => {
    getCode();
  }, [getCode, cpf]);
*/
  return (
    <Container>
      <Content>
        <h2>
          Código de autenticação enviado via {state?.metodo}, verifique suas
          mensagens
        </h2>

        <Form ref={formRef} onSubmit={handleNext}>
          <Input name="verificationCode" title="Inserir código" />

          <ResendCode>
            <p>Não recebei o código?</p>

            <button type="button" onClick={handleReSend}>
              re-enviar
            </button>
          </ResendCode>

          <Button type="submit" btnType="gray" loading={loading}>
            Próximo
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default ConfirmCodeResetPassword;
