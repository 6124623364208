import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import LocalStorageKeyEnum from '../enums/LocalStorageKeyEnum';
import { Individuo } from '../models/individuo';
import IndividuoService from '../services/IndividuoService';
import TokenService from '../services/TokenService';

interface Credentials {
  username: string;
  password: string;
}

interface AuthContextData {
  id?: string;
  username?: string;
  name?: string;
  authCod?: string;
  cpf?: string;
  signIn(credentials: Credentials): Promise<void>;
  signOut(): void;
  updateName(individuo: Individuo): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [id, setId] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [name, setName] = useState<string>();
  const [authCod, setAuthCod] = useState<string>();
  const [cpf, setCPF] = useState<string>();

  const signIn = useCallback(
    async ({ username, password }: Credentials): Promise<void> => {
      const token = await TokenService.getToken(username, password);

      localStorage.setItem(LocalStorageKeyEnum.AccessToken, token.access_token);
      localStorage.setItem(
        LocalStorageKeyEnum.RefreshToken,
        token.refresh_token,
      );
      localStorage.setItem(LocalStorageKeyEnum.Username, username);

      const [user] = await IndividuoService.getList({ cpf: username });
      setId(user.id);
      setUsername(user.username);
      setName(user.nomeCompleto);
      setAuthCod(user.codigoAutenticacao);
      setCPF(user.cpf);

      if (user.id) {
        localStorage.setItem(LocalStorageKeyEnum.UserId, user.id);
      }
      if (user.username) {
        localStorage.setItem(LocalStorageKeyEnum.Username, user.username);
      }
      if (user.nomeCompleto) {
        localStorage.setItem(LocalStorageKeyEnum.Name, user.nomeCompleto);
      }
      if (user.codigoAutenticacao) {
        localStorage.setItem(
          LocalStorageKeyEnum.AuthCod,
          user.codigoAutenticacao,
        );
      }
      if (user.cpf) {
        localStorage.setItem(LocalStorageKeyEnum.CPF, user.cpf);
      }
    },
    [],
  );

  const signOut = useCallback(() => {
    localStorage.clear();

    setId(undefined);
    setUsername(undefined);
    setName(undefined);
    setAuthCod(undefined);
    setCPF(undefined);

    history.push('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateName = useCallback(async (individuo: Individuo) => {
    setName(individuo.nomeCompleto);
  }, []);

  useEffect(() => {
    setId(localStorage.getItem(LocalStorageKeyEnum.UserId) ?? undefined);
    setUsername(
      localStorage.getItem(LocalStorageKeyEnum.Username) ?? undefined,
    );
    setName(localStorage.getItem(LocalStorageKeyEnum.Name) ?? undefined);
    setAuthCod(localStorage.getItem(LocalStorageKeyEnum.AuthCod) ?? undefined);
    setCPF(localStorage.getItem(LocalStorageKeyEnum.CPF) ?? undefined);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        id,
        username,
        name,
        authCod,
        cpf,
        signIn,
        signOut,
        updateName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};
