import React from 'react';
import { IoDocuments } from 'react-icons/io5';

import { Container, IconContainer } from './styles';

interface CleanViewProps {
  text: string;
}

const CleanView: React.FC<CleanViewProps> = ({ text }) => {
  return (
    <Container>
      <IconContainer>
        <IoDocuments color="white" />
      </IconContainer>
      <h3>{text}</h3>
    </Container>
  );
};

export default CleanView;
