import { ApiObject } from '../models/apiObject';
import { api } from './Api';

interface getAllItemResponse {
  ufAbreviado: string;
  ufExtenso: string;
  ativo: boolean;
  invalid: boolean;
  valid: boolean;
}

const getAll = async (): Promise<getAllItemResponse[]> => {
  const { data } = await api.get<ApiObject<getAllItemResponse>>(
    'UnidadeFederativa?take=27',
  );

  if (!data) {
    return [];
  }

  return data.items;
};

export default { getAll };
