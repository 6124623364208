import React, { ButtonHTMLAttributes } from 'react';
import { Container } from './styles';

interface ButtonOptionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  textSize?: number;
  filled?: boolean;
}

const ButtonOption: React.FC<ButtonOptionProps> = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

export default ButtonOption;
