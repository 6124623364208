import styled, { css } from 'styled-components';

interface ContentProps {
  isFocused: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding-right: 5px;

  strong {
    color: ${({ theme }) => theme.colors.graySecondary};
  }

  & + div {
    margin-top: 15px;
  }
`;

export const Content = styled.div<ContentProps>`
  display: flex;
  align-items: center;

  background: #fff;
  border-radius: 25px;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.graySecondaryLight};

  ${({ isFocused, theme }) =>
    isFocused &&
    css`
      border-color: ${theme.colors.primary};
    `}

  input {
    width: 100%;
    border: 0;
    background: transparent;
    color: ${({ theme }) => theme.colors.graySecondaryDark};
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1rem;
    margin: 12px 16px;

    &:focus {
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    height: auto;
    padding: 12px;
    border: none;
    background: none;

    &:hover {
      background-color: ${({ theme }) => theme.colors.graySecondaryLight};
      border-radius: 50%;
    }
  }
`;
