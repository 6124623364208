import * as Yup from 'yup';

export const ComorbiditiesSchema = Yup.object().shape({
  transplantado: Yup.bool().required(),
  doencaComprometeImunidade: Yup.bool().required(),
  fumante: Yup.bool().required(),
  hipertenso: Yup.bool().required(),
  asma: Yup.bool().required(),
  diabetes: Yup.bool().required(),
  doencaPulmao: Yup.bool().required(),
  doencaCoracao: Yup.bool().required(),
  doencaRins: Yup.bool().required(),
  doencaFigado: Yup.bool().required(),
  sintomasGripais: Yup.bool().required(),
  dataInicioSintomas: Yup.date().when('SintomasGripais', {
    is: true,
    then: Yup.date().required(),
  }),
});
