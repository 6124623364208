import React, { useState, useEffect } from 'react';
import { FiChevronLeft } from 'react-icons/fi';

import Loading from '../../../../../../components/Loading';
import { Profissional as ProfissionalBase } from '../../../../../../models/profissional';

import {
  Container,
  Header,
  ProfessionalInfo,
  ProfessionalHeader,
} from './styles';

interface Profissional extends ProfissionalBase {
  especialidade?: string;
}

interface ListProfessionalsProps {
  setShowCard(): void;
  listProfissionais: Profissional[];
}

const ListProfessionals: React.FC<ListProfessionalsProps> = ({
  setShowCard,
  listProfissionais,
}) => {
  const [professionals, setProfessionals] = useState<Profissional[]>();

  useEffect(() => {
    setProfessionals(listProfissionais);
  }, [listProfissionais]);

  return (
    <Container isLoading={!professionals}>
      {!professionals ? (
        <Loading size={60} spinnerColor="gray" />
      ) : (
        <>
          <Header>
            <h2>Profissionais</h2>

            <button type="button" onClick={() => setShowCard()}>
              <FiChevronLeft size={20} />

              <span>Voltar</span>
            </button>
          </Header>

          <ProfessionalHeader>
            <strong>Nome</strong>

            <strong>CRM</strong>

            <strong>Especialidade</strong>
          </ProfessionalHeader>

          {professionals.map(professional => (
            <ProfessionalInfo key={professional.id}>
              <div>
                <span>{professional.nome}</span>
              </div>

              <div>
                <span>{professional.crm}</span>
              </div>

              <div>
                <span>{professional.especialidade ?? '-'}</span>
              </div>
            </ProfessionalInfo>
          ))}
        </>
      )}
    </Container>
  );
};

export default ListProfessionals;
