import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  height: 100vh;
  width: 100%;
  margin-top: 50px;
  padding: 0 15px;

  h3 {
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.graySecondaryDark};

    @media (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      text-align: center;
    }
  }

  form > button {
    margin-top: 30px;
    width: 100%;
    max-width: 250px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: ${({ theme }) => theme.gridBreakpoints.lg};
`;
