/* eslint-disable */

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CardExame from '../../../components/CardExame';
import { Card } from './styles';
import CleanView from '../../../components/CleanView';
import Loading from '../../../components/Loading';
import { useAuth } from '../../../hooks/auth';
import { UseExams } from '../../../hooks/local/useExams';
import { Exame } from '../../../models/IExams';
import { Container } from '../Exames/styles';
import { ExamsContainer } from './styles';
import moment from 'moment';

const ExamsDone: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { getExams, exams, error, success, loading: loadingExams } = UseExams();
  const { cpf } = useAuth();

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    console.log(error);
  }, [error]);

  useEffect(() => {
    if (cpf) {
      getExams(cpf);
    }
  }, [getExams, cpf]);

  const handleClick = (exame: Exame) => {
    if (!exame) {
      return;
    }
    window.open(exame.pdfExame?.toString());
  };

  return (
    <Container>
      <Card>
        <h1>Exames Enviados</h1>
        <ExamsContainer isLoading={loadingExams}>
          {!loadingExams ? (
            exams ? (
              exams.map((exames, index) => {
                return (
                  <CardExame
                    examType={exames.nome}
                    key={index}
                    name={`Date de envio: ${moment(exames.dataDeEnvio).format(
                      'DD/MM/YYYY',
                    )}`}
                    onClick={() => {
                      if (exames.pdfExame) {
                        var win = window.open();
                        win?.document.write(
                          '<iframe src="' +
                            exames.pdfExame +
                            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>',
                        );
                      }
                    }}
                  />
                );
              })
            ) : (
              <>
                <CleanView text="Nenhum exame encontrado." />
              </>
            )
          ) : (
            <Loading size={60} spinnerColor="gray" />
          )}
        </ExamsContainer>
      </Card>
    </Container>
  );
};

export default ExamsDone;
