import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import Button from '../../../../components/Button';
import CardGlobal from '../../../../components/CardGlobal';
import MedicalAppointmentDetails from '../../../../components/MedicalAppointmentDetails';
import FileDownloaderButton from '../../../../components/FileDownloaderButton';

import {
  Container,
  MedicalAppointmentDetailsContainer,
  DocumentsContainer,
  ButtonsContainer,
  AcceptRejectButtonsContainer,
} from './styles';
import AgendamentoService from '../../../../services/AgendamentoService';
import { Agendamento } from '../../../../models/agendamento';
import useQuery from '../../../../utils/query';
import { Notificacao } from '../../../../models/notificacao';
import Loading from '../../../../components/Loading';

const Details: React.FC = () => {
  const [agendamento, setAgendamento] = useState<Agendamento>(
    {} as Agendamento,
  );
  const [address, setAddress] = useState('');
  const query = useQuery();
  const history = useHistory();

  const handleClickEnter = () => {
    history.push(`/area/consulta/${agendamento.id}`);
  };

  const handleGetAgendamento = async () => {
    try {
      const id = query.get('id');

      if (id) {
        const data = await AgendamentoService.getById(id);

        setAgendamento({
          ...data,
          dia: format(new Date(data.dia as string), 'dd/MM/yyyy'),
        });
        setAddress(
          `${
            data.estabelecimento?.logradouro
              ? `${data.estabelecimento?.logradouro}, `
              : ' '
          }${data.estabelecimento?.numero ?? ''} ${
            data.estabelecimento?.complemento
              ? `${data.estabelecimento?.complemento} - `
              : ''
          }${
            data.estabelecimento?.bairro
              ? `${data.estabelecimento?.bairro}, `
              : ''
          } ${data.estabelecimento?.nomeFantasia}`,
        );
      } else {
        history.push('/area/agendamento');
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        const { data } = error.response as {
          data: { notifications: Notificacao[] } | string;
        };

        if (typeof data === 'string') {
          toast.error(data as string);
        } else if (data.notifications && data.notifications.length > 0) {
          data.notifications.forEach(notification => {
            toast.error(notification.message);
          });
        } else {
          toast.error('Tivemos um problema.');
        }
      } else {
        toast.error('Tivemos um problema.');
      }
    }
  };

  useEffect(() => {
    handleGetAgendamento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!agendamento.emAndamento) {
      setTimeout(() => {
        handleGetAgendamento();
      }, 3000);
    }
  }, [agendamento]);

  return (
    <Container>
      <CardGlobal>
        <MedicalAppointmentDetailsContainer>
          <h1>{agendamento.tipoDaConsulta}</h1>
          <MedicalAppointmentDetails
            date={agendamento.dia}
            schedule={agendamento.hora}
            name={agendamento.profissional?.nome}
            address={address}
            specialty={agendamento.procedimento?.descricao}
            comments={agendamento.observacoes ?? ''}
          />
        </MedicalAppointmentDetailsContainer>

        <ButtonsContainer>
          {agendamento.emAndamento ? (
            <Button textSize={15} btnType="primary" onClick={handleClickEnter}>
              Entrar na sala de atendimento
            </Button>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <p style={{ marginRight: '20px' }}>
                Aguarde o médico entrar na sala...
              </p>
              <Loading size={20} />
            </div>
          )}
          {/* <AcceptRejectButtonsContainer>
            <Button textSize={15} btnType="primary">
              Aceitar
            </Button>
            <Button textSize={15} btnType="primary">
              Recusar
            </Button>
          </AcceptRejectButtonsContainer> */}
        </ButtonsContainer>
      </CardGlobal>
    </Container>
  );
};

export default Details;
