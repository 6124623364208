import styled from 'styled-components';

interface ItemProps {
  gridArea: string;
}

export const Container = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 30px;

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 0 10px;
  }

  > div {
    display: flex;
    flex-direction: column;

    width: 100%;

    form {
      width: 100%;
    }
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  > button {
    border: none;
    background: transparent;
    display: flex;
    color: ${({ theme }) => theme.colors.graySecondaryDark};
    font-size: 1rem;
    align-items: center;

    > svg {
      margin-right: 0.5rem;
    }
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  grid-template-areas:
    'cpf cpf name name sName sName'
    'motherName motherName bday bday phone phone'
    'email email genre genre breed breed'
    'cep state city city neighborhood neighborhood'
    'street street numberAddress addressComplement addressComplement addressComplement';

  padding-bottom: 30px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.xl}) {
    grid-template-columns: 3fr 3fr 1fr 1fr 1fr 3fr;
    grid-template-areas:
      'name name name name name sName'
      'cpf cpf cpf cpf cpf cpf'
      'email email email email email phone'
      'genre genre breed breed breed breed'
      'motherName motherName motherName motherName motherName motherName'
      'cep cep state state state state'
      'city city neighborhood neighborhood neighborhood neighborhood'
      'street street numberAddress addressComplement addressComplement addressComplement';
  }

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.lg}) {
    display: flex;
    flex-direction: column;
  }
`;

export const Item = styled.div<ItemProps>`
  grid-area: ${({ gridArea }) => gridArea};
  width: 100%;
`;

export const ButtonArea = styled.div`
  & > h4 {
    color: ${({ theme }) => theme.colors.graySecondaryDark};
  }

  & > div {
    display: flex;
    margin: 20px 0 30px;

    button + button {
      margin-left: 10px;
    }

    > button {
      min-width: 120px;
    }
  }

  & > button {
    min-width: 250px;
  }
`;
