import React from 'react';
import { VscLoading } from 'react-icons/vsc';

import { Container, ColorType } from './styles';

interface LoadingProps {
  spinnerColor?: ColorType;
  size?: number;
}

const Loading: React.FC<LoadingProps> = ({
  size = 20,
  spinnerColor = 'gray',
}) => {
  return (
    <Container color={spinnerColor}>
      <VscLoading size={size} />
    </Container>
  );
};

export default Loading;
