import moment from 'moment';
import { useCallback, useState } from 'react';
import { boolean } from 'yup';

import { Agendamento } from '../../models/agendamento';
import AgendamentoApi from '../../services/AgendamentoService';
import { useAuth } from '../auth';

interface UseShedulesReturn {
  getSchedules: (finalizado: boolean, cancelado: boolean) => Promise<void>;
  //   postSchedules: (agendamento: Agendamento) => Promise<void>;
  //   updateSchedules: (agendamento: Agendamento, cancelado?: boolean) => Promise<void>;
  scheduleItems: AgendamentoDTO[];
  loading: boolean;
  error: string;
  success: string;
}

interface AgendamentoDTO {
  id?: string;
  data?: string;
  hora?: string;
  tipo?: string;
  profissional?: string;
  especialidade?: string;
}

export function useSchedules(): UseShedulesReturn {
  const { id } = useAuth();

  const [loading, setLoading] = useState(false);
  const [scheduleItems, setScheduleItems] = useState<AgendamentoDTO[]>([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const getSchedules = useCallback(
    async (finalizado, cancelado) => {
      try {
        if (!id) return;
        setLoading(true);
        setError('');
        const agendamentos = await AgendamentoApi.get(
          1,
          999,
          id,
          finalizado,
          cancelado,
        );
        if (!agendamentos) return;
        const apiAgendamentos = agendamentos.items.map((agendamento: any) => ({
          id: agendamento.id,
          data: moment(agendamento.dia).format('DD/MM/YYYY'),
          hora: agendamento.hora,
          tipo: agendamento.tipoDaConsulta,
          profissional: agendamento.profissional?.nome,
          especialidade: agendamento.procedimento?.descricao,
        }));
        setScheduleItems(apiAgendamentos);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setError('Não foi possivel carregar os agendamentos');
      } finally {
        setLoading(false);
      }
    },
    [id],
  );

  //   const postSchedules = useCallback(
  //     async (agendamento: Agendamento) => {
  //       try {
  //         setLoading(true);
  //         setError('');
  //         await AgendamentoApi.post({ ...agendamento, individuoId: user?.id, cancelado: false });
  //         setSuccess('Dados enviados com sucesso');
  //       } catch (err) {
  //         console.log('error', err);
  //         setSuccess('');
  //         setError('Não foi possivel enviar os dados');
  //       } finally {
  //         setLoading(false);
  //       }
  //     },
  //     [user],
  //   );
  //   const updateSchedules = useCallback(
  //     async (agendamento: IAgendamento, cancelado?: boolean) => {
  //       try {
  //         setLoading(true);
  //         setError('');
  //         await AgendamentoApi.update(agendamento.id, {
  //           ...agendamento,
  //           individuoId: user?.id,
  //         });

  //         setSuccess('Dados enviados com sucesso');
  //       } catch (err) {
  //         setSuccess('');
  //         setError('Não foi possivel enviar os dados');
  //       } finally {
  //         setLoading(false);
  //       }
  //     },
  //     [user],
  //   );

  return {
    getSchedules,
    // postSchedules,
    // updateSchedules,
    scheduleItems,
    loading,
    error,
    success,
  };
}
