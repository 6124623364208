import { api } from './Api';

import { Acompanhamento } from '../models/acompanhamento';

const create = async (
  acompanhamento: Acompanhamento,
): Promise<Acompanhamento> => {
  const { data } = await api.post<Acompanhamento>(
    `Acompanhamento`,
    acompanhamento,
  );

  return data;
};

export default { create };
