import styled from 'styled-components';
import CardGlobal from '../../../../components/CardGlobal';

export const ResendCode = styled.div`
  display: flex;
  align-items: center;

  button {
    background: transparent;
    border: none;

    font-size: 16px;
    color: ${({ theme }) => theme.colors.primary};
    margin-left: 10px;
  }

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    justify-content: center;
  }
`;

export const Content = styled(CardGlobal)`
  min-width: 800px;

  > h2 {
    margin-bottom: 15px;
  }

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.lg}) {
    min-width: min-content;
  }
`;
