import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';

import { Container, Content } from './styles';

export type InputSelectOptions = InputSelectItemOptions[];
export type InputSelectItemOptions = { label: string; value: string | number };

interface InputProps extends InputHTMLAttributes<HTMLSelectElement> {
  name: string;
  title: string;
  options?: InputSelectOptions;
}

const InputSelect: React.FC<InputProps> = ({
  name,
  title,
  options,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLSelectElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container
      style={{
        width: '100%',
      }}
    >
      <strong>{title}</strong>

      <Content isErrored={!!error} isFocused={isFocused}>
        <select
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          {...rest}
        >
          <option value="">Selecione...</option>
          {options &&
            options.length &&
            options.map(option => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
        </select>
      </Content>
    </Container>
  );
};

export default InputSelect;
