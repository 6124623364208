import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const DropMenuProfile = styled.ul`
  list-style: none;
  width: 180px;
  background: ${({ theme }) => theme.colors.graySecondaryDark};
  padding: 8px;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  opacity: 0;
  transition: opacity 0.4s;
  visibility: hidden;
  z-index: 1;

  position: absolute;
  top: calc(120%);
  transform: translateX(-5%);

  -webkit-box-shadow: 10px 6px 14px -6px rgba(179, 179, 179, 1);
  -moz-box-shadow: 10px 6px 14px -6px rgba(179, 179, 179, 1);
  box-shadow: 10px 6px 14px -6px rgba(179, 179, 179, 1);

  &::before {
    content: '';
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.graySecondaryDark} transparent;
    border-width: 0px 6px 6px 6px;
    bottom: 100%;
    position: absolute;
    left: 52%;
  }

  li + li {
    border-top: 1px solid ${props => props.theme.colors.grayLight};
  }
`;

export const MenuProfileButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;

  padding: 10px 0px;

  margin-top: 4px;
  border: 0px;

  &:hover {
    color: ${props => props.theme.colors.primary};

    svg {
      color: ${props => props.theme.colors.primary} !important;
    }
  }

  svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    color: #ffffff !important;
  }
`;

export const ProfileWeb = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: transparent;

  svg {
    color: ${props => props.theme.colors.secondaryDark};
  }

  &:hover ul {
    opacity: 1;
    visibility: visible;
  }
`;
