import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import CardGlobal from '../../../components/CardGlobal';
import CleanView from '../../../components/CleanView';
import Loading from '../../../components/Loading';
import { useAuth } from '../../../hooks/auth';
import { IndividuoNotificacao } from '../../../models/individuoNotificacao';
import { Notificacao } from '../../../models/notificacao';
import NotificacaoService from '../../../services/NotificacaoService';

import { Container, Notification } from './styles';

const Notifications: React.FC = () => {
  const { id } = useAuth();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState<IndividuoNotificacao[]>(
    [],
  );

  const getNotifications = useCallback(async () => {
    try {
      setLoading(true);
      if (!id) {
        setLoading(false);
        return;
      }
      const data = await NotificacaoService.getAll(id);
      setNotifications(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (axios.isAxiosError(error) && error.response?.data) {
        const { data } = error.response as {
          data: { notifications: Notificacao[] } | string;
        };

        if (typeof data === 'string') {
          toast.error(data as string);
        } else if (data.notifications && data.notifications.length > 0) {
          data.notifications.forEach(notification => {
            toast.error(notification.message);
          });
        } else {
          toast.error('Tivemos um problema.');
        }
      } else {
        toast.error('Tivemos um problema.');
      }
    }
  }, [id]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return (
    <Container>
      <CardGlobal>
        <h1>Notificações</h1>

        {!loading ? (
          notifications && notifications.length > 0 ? (
            <>
              {notifications.map(notification => (
                <Notification key={notification.id}>
                  <h3>{notification.titulo}</h3>
                  <p>{notification.descricao}</p>
                </Notification>
              ))}
            </>
          ) : (
            <CleanView text="Nenhuma notificação encontrada" />
          )
        ) : (
          <Loading size={60} spinnerColor="gray" />
        )}
      </CardGlobal>
    </Container>
  );
};

export default Notifications;
