import { AxiosResponse } from 'axios';
import { extname } from 'path';
import { ApiObject } from '../models/apiObject';
import { Exame } from '../models/IExams';
import { api } from './Api';

async function get(
  skip = 1,
  take = 100,
  sort = 'Nome ASC',
  total = 0,
  individuoCpf: string | undefined,
): Promise<ApiObject<any[]>> {
  const response = await api.get<unknown, AxiosResponse<ApiObject<any[]>>>(
    '/Exames/GetExames/',
    {
      params: { skip, take, sort, individuoCpf, total },
    },
  );
  return response.data;
}

async function post(exame: Exame[]): Promise<any> {
  const response = await api.post<unknown, AxiosResponse<any>>(
    `Exames/ReceberExames`,
    exame,
  );
  return response;
}
export default { post, get };
