import { format } from 'date-fns';
import React from 'react';
import { MdCalendarToday, MdOutlineWatchLater } from 'react-icons/md';
import {
  Container,
  LargeCard,
  Card,
  AgendamentoInfo,
  ProfessionalInfo,
} from './styles';

interface CardInProgressProps {
  date?: string;
  hour?: string;
  specialty?: string;
  profissional?: string;
}

const CardInProgress: React.FC<CardInProgressProps> = ({
  date,
  hour,
  specialty,
  profissional,
}) => {
  return (
    <Container>
      <LargeCard>
        <div>
          <MdOutlineWatchLater />
          <strong>Atendimento em andamento</strong>
        </div>
        <div>
          <span>DATA</span>
          <strong>
            {date && format(new Date(date as string), 'dd/MM/yyyy')}
          </strong>
        </div>
        <div>
          <span>HORA</span>
          <strong>{hour}</strong>
        </div>
        <div>
          <span>ESPECIALIDADE</span>
          <strong>{specialty}</strong>
        </div>
        <div>
          <span>PROFISSIONAL</span>
          <strong>{profissional}</strong>
        </div>
      </LargeCard>
      <Card>
        <h4>Atendimento em andamento</h4>
        <AgendamentoInfo>
          <div>
            <MdCalendarToday />
            <strong>{date}</strong>
          </div>
          <div>
            <MdOutlineWatchLater />
            <strong>{hour}</strong>
          </div>
        </AgendamentoInfo>
        <ProfessionalInfo>
          <strong>{specialty}</strong>
          <strong>{profissional}</strong>
        </ProfessionalInfo>
      </Card>
    </Container>
  );
};

export default CardInProgress;
