import { useCallback, useState } from 'react';
import IGetReturn from '../../models/IGetReturn';
import { Exame } from '../../models/IExams';
import ExameService from '../../services/ExameService';
import ExamTypes from '../../services/ExamTypesService';
import { ApiObject } from '../../models/apiObject';
import { TipoExame } from '../../models/ITipoExame';

interface UseExamReturn {
  getExamsTypes: () => Promise<void>;
  type?: TipoExame[];
  error?: string;
}

export function useExamsTypes(): UseExamReturn {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [type, setExamType] = useState<TipoExame[]>();

  const getExamsTypes = useCallback(async () => {
    setLoading(true);
    setError('');
    try {
      const response = await ExamTypes.getExamsTypes();
      setExamType(
        response.items.map((examType: any) => {
          return {
            id: examType.id,
            nome: examType.nome,
          };
        }),
      );
    } catch (err) {
      console.log(err);
      setError('Não foi possivel carregar os tipos de agendamento.');
    } finally {
      setLoading(false);
    }
  }, []);

  return { getExamsTypes, type, error };
}
