import styled, { css } from 'styled-components';

export interface ContainerProps {
  maxWidthInitial?: boolean;
}

interface InfosContainerProps {
  isLoading: boolean | undefined;
}

export const Container = styled.div<ContainerProps>`
  padding: 0 30px;

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 0 10px;
  }

  > div {
    max-width: 700px;
    min-height: 200px;
    display: flex;
    flex-direction: column;

    ${({ maxWidthInitial }) =>
      maxWidthInitial &&
      css`
        max-width: initial;
      `}

    h1 {
      color: ${({ theme }) => theme.colors.graySecondaryDark};
      margin-bottom: 1rem;
    }
  }
`;

export const InfosContainer = styled.div<InfosContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: ${({ isLoading }) => (isLoading ? 'center' : 'left')};
  justify-content: ${({ isLoading }) => (isLoading ? 'center' : 'flex-start')};
`;
