import styled, { css } from 'styled-components';
import { Form } from '@unform/web';

export interface ContainerProps {
  maxWidthInitial?: boolean;
  showButton?: boolean;
}

interface DocumentsContainerProps {
  isLoading: boolean | undefined;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 0 30px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 0 10px;
  }

  & > div {
    width: 100%;
    max-width: 700px;
    min-height: 200px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      min-height: initial;
    }

    ${({ maxWidthInitial }) =>
      maxWidthInitial &&
      css`
        max-width: initial;
      `}

    h1 {
      color: ${({ theme }) => theme.colors.graySecondaryDark};
      margin-bottom: 1rem;
    }
  }
`;

export const DocumentsContainer = styled.div<DocumentsContainerProps>`
  height: 10vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: ${({ isLoading }) => (isLoading ? 'center' : 'left')};
  justify-content: ${({ isLoading }) => (isLoading ? 'center' : 'flex-start')};

  > button:last-child {
    margin: 15px auto 0 auto;
  }
`;
export const FormExams = styled(Form)`
  display: flex;
  align-items: center;
  flex-direction: collumn;
  justify-content: center;
`;
