import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import getValidationCPF from '../../../utils/getValidationCPF';
import getValidationErrors from '../../../utils/getValidationErrors';

import InputMask from '../../../components/InputMask';
import Button from '../../../components/Button';

import { Container, Content } from './styles';
import Input from '../../../components/Input';
import { Notificacao } from '../../../models/notificacao';

interface FormData {
  cpf: string;
  birthday: string;
  phone: string;
}

const Register: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleNext = useCallback(
    async (data: FormData) => {
      setLoading(true);

      if (data.phone && data.phone.indexOf('_') !== 1) {
        data.phone = data.phone.replace('_', '');
      }

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cpf: Yup.string()
            .required('O CPF é obrigatório')
            .length(14, 'Digite um CPF válido')
            .test('cpf', 'Digite um CPF válido', value =>
              getValidationCPF(value),
            ),
          birthday: Yup.string()
            .length(10, 'Digite uma data de nascimento válida')
            .required('A data de nascimento é obrigatória'),
          phone: Yup.string()
            .length(16, 'Digite um número de telefone válido')
            .required('O telefone é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        history.push('/cadastro/concluir', data);
      } catch (error) {
        setLoading(false);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        } else if (axios.isAxiosError(error) && error.response?.data) {
          const { data } = error.response as {
            data: { notifications: Notificacao[] } | string;
          };

          if (typeof data === 'string') {
            toast.error(data as string);
          } else if (data.notifications && data.notifications.length > 0) {
            data.notifications.forEach(notification => {
              toast.error(notification.message);
            });
          } else {
            toast.error('Tivemos um problema.');
          }
        } else {
          toast.error('Tivemos um problema.');
        }
      }
    },
    [history],
  );

  return (
    <Container>
      <Content>
        <h3>Informe os dados a seguir para realizar seu primeiro acesso</h3>

        <Form ref={formRef} onSubmit={handleNext}>
          <InputMask name="cpf" title="CPF" mask="999.999.999-99" />

          <Input name="birthday" title="Data de nascimento" type="date" />

          <InputMask
            name="phone"
            title="Número de telefone"
            mask="(99) 9 9999-9999"
          />

          <Button type="submit" btnType="gray" loading={loading}>
            Próximo
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default Register;
