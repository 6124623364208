import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

interface VerifyRulesProps {
  allowThesePreviousRoutes: string[];
  onSuccess?: () => void;
  onError?: () => void;
}

interface RouteRulesContextData {
  verifyRules: (rules: VerifyRulesProps) => void;
}

interface RouteRulesValues {
  current: string;
  prev?: string;
}

const RouteRulesContext = createContext<RouteRulesContextData>(
  {} as RouteRulesContextData,
);

export const RouteRulesProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [valueRoute] = useState<RouteRulesValues>({
    current: location.pathname,
  });

  const updateRoutes = useCallback(() => {
    if (valueRoute.current !== location.pathname) {
      valueRoute.prev = valueRoute.current;
      valueRoute.current = location.pathname;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const verifyRules = useCallback(
    ({ allowThesePreviousRoutes, onError, onSuccess }: VerifyRulesProps) => {
      updateRoutes();

      const isComeFrom = allowThesePreviousRoutes.some(
        itemRoute => itemRoute === valueRoute.prev,
      );

      if (isComeFrom) {
        if (onSuccess) {
          onSuccess();
        }
      } else if (onError) {
        onError();
      }
    },
    [updateRoutes, valueRoute],
  );

  useEffect(() => {
    updateRoutes();
  }, [updateRoutes]);

  return (
    <RouteRulesContext.Provider value={{ verifyRules }}>
      {children}
    </RouteRulesContext.Provider>
  );
};

export const useRouteRules = (): RouteRulesContextData => {
  const context = useContext(RouteRulesContext);

  if (!context) {
    throw new Error('useRouteRules must be used within a RouteRulesProvider');
  }

  return context;
};
