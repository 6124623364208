import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { MdOutlineEditCalendar } from 'react-icons/md';
import { FiFilter, FiX } from 'react-icons/fi';
import moment from 'moment';
import Button from '../../../components/Button';
import Table from '../../../components/Table';
import AgendamentoService from '../../../services/AgendamentoService';

import {
  Container,
  Header,
  Content,
  FilterContent,
  CardGlobal,
} from './styles';
import { useAuth } from '../../../hooks/auth';
import CardInProgress from '../../../components/CardInProgress';
import { Notificacao } from '../../../models/notificacao';
import Loading from '../../../components/Loading';
import CleanView from '../../../components/CleanView';
import Input from '../../../components/Input';
import InputSelect from '../../../components/InputSelect';
import { Agendamento as AgendamentoModel } from '../../../models/agendamento';
import { useSchedules } from '../../../hooks/local/useSchedules';

interface AgendamentoDTO {
  id?: string;
  data?: string;
  tipo?: string;
  profissional?: string;
}

interface FilterDTO {
  date?: string;
  status?: string;
}

const Agendamento: React.FC = () => {
  const history = useHistory();
  const { id } = useAuth();
  const [loading, setLoading] = useState(true);
  const [agendamentoEmAndamento, setAgendamentoEmAndamento] =
    useState<AgendamentoModel>();
  const [agendamentos, setAgendamentos] = useState<AgendamentoDTO[]>([]);
  const [andamento, setAndamento] = useState(false); // <AgendamentoDTO> dados do agendamento em andamento
  const [filter, setFilter] = useState<FilterDTO>();
  const {
    error: errorSchedules,
    getSchedules,
    loading: loadingSchedules,
    scheduleItems,
    success: successSchedules,
  } = useSchedules();

  useEffect(() => {
    getSchedules(false, false);
  }, [getSchedules]);

  const getAgendamentoEmAndamento = useCallback(async () => {
    try {
      if (!id) {
        return;
      }
      const data = await AgendamentoService.getAll({
        individuoId: id,
        inProgress: true,
      });
      if (!data) {
        setAndamento(false);
        return;
      }

      if (data.length > 0) {
        setAgendamentoEmAndamento(data[data.length - 1]);
        setAndamento(true);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        const { data } = error.response as {
          data: { notifications: Notificacao[] } | string;
        };

        if (typeof data === 'string') {
          toast.error(data as string);
        } else if (data.notifications && data.notifications.length > 0) {
          data.notifications.forEach(notification => {
            toast.error(notification.message);
          });
        } else {
          toast.error('Tivemos um problema.');
        }
      } else {
        toast.error('Tivemos um problema.');
      }
    }
  }, [id]);

  // const getAgendamentos = useCallback(
  //   async (day?: string, status?: string) => {
  //     try {
  //       setLoading(true);
  //       if (!id) {
  //         setLoading(false);
  //         return;
  //       }
  //       const data = await AgendamentoService.getAll({
  //         id,
  //         day,
  //         inProgress: false,
  //         finished: Boolean(Number(status)),
  //       });
  //       console.log('data', data);
  //       if (!data) {
  //         setLoading(false);
  //         return;
  //       }
  //       const apiAgendamentos = data.map(agendamento => ({
  //         id: agendamento.id,
  //         data: moment(agendamento.dia).format('DD/MM/YYYY'),
  //         hora: agendamento.hora,
  //         tipo: agendamento.tipoDaConsulta,
  //         profissional: agendamento.profissional?.nome,
  //         especialidade: agendamento.procedimento?.descricao,
  //       }));
  //       setAgendamentos(apiAgendamentos);
  //       setLoading(false);
  //     } catch (error) {
  //       console.log(error);
  //       setLoading(false);
  //       if (axios.isAxiosError(error) && error.response?.data) {
  //         const { data } = error.response as {
  //           data: { notifications: Notificacao[] } | string;
  //         };

  //         if (typeof data === 'string') {
  //           toast.error(data as string);
  //         } else if (data.notifications && data.notifications.length > 0) {
  //           data.notifications.forEach(notification => {
  //             toast.error(notification.message);
  //           });
  //         } else {
  //           toast.error('Tivemos um problema.');
  //         }
  //       } else {
  //         toast.error('Tivemos um problema.');
  //       }
  //     }
  //   },
  //   [id],
  // );

  // const handleFilter = useCallback(
  //   (data?: FilterDTO) => {
  //     setAgendamentos([]);
  //     if (filter) {
  //       setFilter(undefined);
  //       getAgendamentos();
  //     } else {
  //       setFilter(data);
  //       getAgendamentos(data?.date, data?.status);
  //     }
  //   },
  //   [filter, getAgendamentos],
  // );

  // useEffect(() => {
  //   getAgendamentoEmAndamento();
  //   getAgendamentos();
  //   console.log('agendamentos: ', agendamentos);
  // }, [getAgendamentos, getAgendamentoEmAndamento]);

  return (
    <Container>
      <CardGlobal maxWidthDefault>
        <div>
          <Header>
            {andamento && (
              <CardInProgress
                date={agendamentoEmAndamento?.dia}
                hour={agendamentoEmAndamento?.hora}
                profissional={agendamentoEmAndamento?.profissional?.nome}
                specialty={agendamentoEmAndamento?.procedimento?.descricao}
              />
            )}
            {/* <Button
              btnType="outlinePrimary"
              onClick={() => history.push('/area/agendamento/novo')}
            >
              <MdOutlineEditCalendar size={20} />
              <span>Consultas pré-autorizadas</span>
            </Button> */}
          </Header>
          <h3>Filtros</h3>
          <FilterContent onSubmit={() => {}}>
            <Input name="date" title="Dia" type="date" disabled={!!filter} />
            <InputSelect
              name="status"
              title="Status"
              disabled={!!filter}
              options={[
                { label: 'Próximos atendimentos', value: '0' },
                { label: 'Atendimentos finalizados', value: '1' },
              ]}
            />
            <Button btnType={filter ? 'red' : 'primary'}>
              {filter ? <FiX /> : <FiFilter />}
            </Button>
          </FilterContent>
          <h3>Agendamentos</h3>
          <Content>
            {!loadingSchedules ? (
              <>
                {scheduleItems && scheduleItems.length > 0 ? (
                  <Table
                    header={{
                      Tipo: 'tipo',
                      Dia: 'data',
                      Hora: 'hora',
                      Médico: 'profissional',
                      Especialidade: 'especialidade',
                    }}
                    data={scheduleItems}
                    rowKey="id"
                  />
                ) : (
                  <CleanView text="Nenhum agendamento encontrado" />
                )}
              </>
            ) : (
              <Loading size={60} spinnerColor="gray" />
            )}
          </Content>
        </div>
      </CardGlobal>
    </Container>
  );
};

export default Agendamento;
