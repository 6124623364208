import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { toast } from 'react-toastify';

import CardCollapse from '../../../components/CardCollapse';
import CardGlobal from '../../../components/CardGlobal';
import Loading from '../../../components/Loading';

import InformacoesUteisService from '../../../services/InformacoesUteisService';
import { InformacaoUtil } from '../../../models/informacaoUtil';

import { Notificacao } from '../../../models/notificacao';
import {
  Container,
  ContainerProps as UseFulInformationProps,
  InfosContainer,
} from './styles';
import CleanView from '../../../components/CleanView';

const UsefulInformation: React.FC<UseFulInformationProps> = ({ ...rest }) => {
  const [infos, setInfos] = useState<InformacaoUtil[]>();
  const [loading, setLoading] = useState<boolean>();

  const getAllInfos = async () => {
    try {
      setLoading(true);
      const newInfos = await InformacoesUteisService.getAll();
      setInfos(newInfos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (axios.isAxiosError(error) && error.response?.data) {
        const { data } = error.response as {
          data: { notifications: Notificacao[] } | string;
        };

        if (typeof data === 'string') {
          toast.error(data as string);
        } else if (data.notifications && data.notifications.length > 0) {
          data.notifications.forEach(notification => {
            toast.error(notification.message);
          });
        } else {
          toast.error('Tivemos um problema.');
        }
      } else {
        toast.error('Tivemos um problema.');
      }
    }
  };

  useEffect(() => {
    getAllInfos();
  }, []);

  return (
    <Container {...rest}>
      <CardGlobal>
        <h1>Informações úteis</h1>

        <InfosContainer isLoading={loading}>
          {loading ? (
            <Loading size={50} spinnerColor="gray" />
          ) : (
            <>
              {infos && infos.length > 0 ? (
                infos?.map(info => <CardCollapse key={info.id} data={info} />)
              ) : (
                <CleanView text="Nenhuma informação encontrada" />
              )}
            </>
          )}
        </InfosContainer>
      </CardGlobal>
    </Container>
  );
};

export default UsefulInformation;
