import { api } from './Api';

interface CreatePasswordProps {
  cpf?: string;
  codigoAutenticacao?: string;
  senha: string;
  confirmacao: string;
}

interface ForgotPasswordProps {
  cpf: string;
  metodo: string;
  id?: string;
}

interface ResetPasswordProps {
  cpf?: string;
  codigoAutenticacao?: string;
  senha: string;
  confirmacao: string;
}

const create = async (id: string, info: CreatePasswordProps): Promise<void> => {
  await api.post(`Individuo/${id}/Senha`, info);
};

const forgot = async (info: ForgotPasswordProps): Promise<void> => {
  await api.get(`Individuo/Senha/Recuperar`, {
    params: info,
  });
};

const reset = async (info: ResetPasswordProps): Promise<void> => {
  await api.put(`Individuo/Senha/Redefinir`, { ...info });
};

export default { create, forgot, reset };
