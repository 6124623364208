import styled from 'styled-components';
import CardGlobal from '../../../components/CardGlobal';
import { gridBreakpoints } from '../../../styles/theme/gridBreakpoints';

export const DesktopContainer = styled.div<{ visible?: boolean }>`
  display: none;

  @media screen and (min-width: ${gridBreakpoints.lg}) {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 30px;
    height: 85vh;
  }
`;

export const CardGlobalPerfil = styled(CardGlobal)`
  @media screen and (min-width: ${gridBreakpoints.lg}) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const DesktopSection = styled.div`
  display: none;

  @media screen and (min-width: ${gridBreakpoints.lg}) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    div button {
      margin-top: 2rem;
    }

    > span,
    div {
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &:nth-child(3) > span,
    div {
      width: 60%;
    }

    p {
      font-size: 1.5rem;
    }

    div > span {
      font-size: 1.8rem;
    }

    > img {
      width: 18rem;
    }
  }
`;

export const MobileSection = styled.div`
  display: flex;
  flex-direction: column;

  &.ultimoSlide {
    justify-content: space-between;
  }

  padding: 1em;

  overflow-y: auto;

  h1,
  h2 {
    font-size: 3em;
    padding: 1em 0 1em;
    font-weight: 400;
  }

  > img {
    align-self: center;
    max-width: min(50vw, 50vh);
  }

  p {
    font-size: 1.8em;
    overflow-y: auto;
  }

  > img + p {
    margin-top: 3em;
  }

  > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
  }

  button {
    font-size: 1.8em;
  }

  @media screen and (max-width: ${gridBreakpoints.lg}) {
    font-size: 90%;
  }

  @media screen and (max-width: ${gridBreakpoints.md}) {
    font-size: 80%;
  }

  @media screen and (max-width: ${gridBreakpoints.sm}) {
    font-size: 50%;
  }
`;

export const MobileContainer = styled.div`
  display: none;

  @media screen and (max-width: ${gridBreakpoints.lg}) {
    display: block;
    padding: 0 10px;
    height: 90%;

    > div {
      height: 100%;
      padding-bottom: 4rem;
    }

    div.BrainhubCarousel__container,
    div.BrainhubCarousel,
    div.BrainhubCarousel__trackContainer,
    ul.BrainhubCarousel__track {
      height: 100%;
    }

    li.BrainhubCarouselItem {
      align-items: unset;
    }
  }

  @media (max-width: ${gridBreakpoints.md}) {
    height: 85%;
  }
`;

export const DotThumbnail = styled.div`
  background-color: ${({ theme }) => theme.colors.danger};
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
`;
