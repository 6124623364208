import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button';
import ButtonOption from '../../../../components/ButtonOption';
import { useAuth } from '../../../../hooks/auth';
import { Notificacao } from '../../../../models/notificacao';
import IndividuoSenhaService from '../../../../services/IndividuoSenhaService';
import IndividuoService from '../../../../services/IndividuoService';
import { Container } from '../styles';
import { Content } from './styles';

const SendCodeResetPassword: React.FC = () => {
  const { cpf, id } = useAuth();
  const history = useHistory();
  const { state } = useLocation();
  const [buttonSelected, setButtonSelected] = useState<
    'SMS' | 'Email' | 'WhatsApp'
  >();

  const handleSendCode = useCallback(async () => {
    try {
      if (!buttonSelected || !cpf) {
        return;
      }
      const user = await IndividuoService.getById(id);
      await IndividuoSenhaService.forgot({
        cpf,
        metodo: buttonSelected,
      });
      history.push(
        `/area/profile/alterar-senha/verificar/confirmar?type=${buttonSelected}`,
        {
          metodo: buttonSelected,
        },
      );
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error) && error.response?.data) {
        const { data } = error.response as {
          data: { notifications: Notificacao[] } | string;
        };

        if (typeof data === 'string') {
          toast.error(data as string);
        } else if (data.notifications && data.notifications.length > 0) {
          data.notifications.forEach(notification => {
            toast.error(notification.message);
          });
        } else {
          toast.error('Tivemos um problema.');
        }
      } else {
        toast.error('Tivemos um problema.');
      }
    }
  }, [buttonSelected, cpf, history]);

  return (
    <Container>
      <Content>
        <h2>Solicitação de código de autenticação</h2>
        <p>Escolha o método:</p>

        <div>
          <ButtonOption
            onClick={() => setButtonSelected('SMS')}
            filled={buttonSelected === 'SMS'}
          >
            SMS
          </ButtonOption>
          <ButtonOption
            onClick={() => setButtonSelected('Email')}
            filled={buttonSelected === 'Email'}
          >
            E-mail
          </ButtonOption>
          <ButtonOption
            onClick={() => setButtonSelected('WhatsApp')}
            filled={buttonSelected === 'WhatsApp'}
          >
            Whatsapp
          </ButtonOption>
        </div>
        <Button
          onClick={handleSendCode}
          disabled={!buttonSelected}
          btnType="gray"
        >
          Próximo
        </Button>
      </Content>
    </Container>
  );
};
export default SendCodeResetPassword;
