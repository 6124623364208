import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { IoSadOutline } from 'react-icons/io5';

import Button from '../../../components/Button';
import CardGlobal from '../../../components/CardGlobal';

import { Container } from './styles';

const Error: React.FC = () => {
  const history = useHistory();

  return (
    <Container>
      <CardGlobal maxWidthDefault>
        <IoSadOutline />
        <h1>Página não encontrada</h1>
        <span>
          A página que você tentou acessar está indisponível ou não existe.
        </span>
        <div>
          <Button btnType="gray" textSize={14} onClick={() => history.go(-1)}>
            Retornar à página anterior
          </Button>
          <Link to="/area/principal">
            <Button btnType="primary" textSize={14}>
              Ir para a página inicial
            </Button>
          </Link>
        </div>
      </CardGlobal>
    </Container>
  );
};

export default Error;
