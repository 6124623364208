/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { MdSettings } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import TableButton from '../TableButton';
import {
  Container,
  TableContainer,
  CardContainer,
  CardContent,
} from './styles';

interface HeaderProps {
  [key: string]: string;
}

interface TableProps {
  header: HeaderProps;
  data: any[];
  rowKey: string;
}

const Table: React.FC<TableProps> = ({ data, header, rowKey }) => {
  const history = useHistory();
  const handleClickCard = (id: string) => {
    history.push(`/area/agendamento/detalhes?id=${id}`);
  };

  return (
    <Container>
      <TableContainer>
        <thead>
          <tr>
            {Object.keys(header).map(key => (
              <td key={key}>{key}</td>
            ))}
            <td>Ações</td>
          </tr>
        </thead>
        <tbody>
          {data.map((querie: any) => (
            <tr key={querie[rowKey]}>
              {Object.keys(header).map(key => (
                <td key={key}>{querie[header[key]]}</td>
              ))}
              <td>
                <TableButton id={querie[rowKey]}>
                  <MdSettings size={20} />
                </TableButton>
              </td>
            </tr>
          ))}
        </tbody>
      </TableContainer>
      <CardContainer>
        {data.map((querie: any) => (
          <CardContent
            key={querie[rowKey]}
            onClick={() => handleClickCard(querie[rowKey])}
          >
            {Object.keys(header).map(key => (
              <strong key={key}>{querie[header[key]]}</strong>
            ))}
          </CardContent>
        ))}
      </CardContainer>
    </Container>
  );
};

export default Table;
