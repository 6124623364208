import React from 'react';

import Exams from '../Exames';
import UsefulInformation from '../UsefulInformation';

import { Container, DocumentsAndInfosContainer } from './styles';
import NextAppointments from './NextAppointments';

const MainArea: React.FC = () => {
  return (
    <Container>
      <NextAppointments />
      <DocumentsAndInfosContainer>
        <Exams maxWidthInitial showButton />
        <UsefulInformation maxWidthInitial />
      </DocumentsAndInfosContainer>
    </Container>
  );
};

export default MainArea;
