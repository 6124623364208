import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px 0;

  > h3 {
    color: ${({ theme }) => theme.colors.grayPrimaryDark};
    font-weight: 400;
    text-align: center;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  width: 10vw;
  font-size: 5vw;
  height: 10vw;
  background: ${({ theme }) => theme.colors.grayPrimaryLight};
  border-radius: 36% 63% 64% 36% / 64% 48% 52% 36%;
  margin-bottom: 25px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.lg}) {
    width: 25vw;
    font-size: 15vw;
    height: 25vw;
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    width: 35vw;
    font-size: 20vw;
    height: 35vw;
  }
`;
