import { ApiObject } from '../models/apiObject';
import { IndividuoNotificacao } from '../models/individuoNotificacao';
import { api } from './Api';

const getAll = async (id: string): Promise<IndividuoNotificacao[]> => {
  const { data } = await api.get<ApiObject<IndividuoNotificacao>>(
    `/Notificacao`,
    {
      params: {
        individuoId: id,
        // individuoId: '0b31b977-d90c-4695-9e3e-659084bde199',
      },
    },
  );

  return data.items;
};

export default { getAll };
