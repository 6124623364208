import styled from 'styled-components';
import { gridBreakpoints } from '../../../../styles/theme/gridBreakpoints';

interface ItemProps {
  gridArea: string;
}

export const Container = styled.div`
  padding: 0 30px;

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 0 10px;
  }

  > div {
    h3 {
      color: ${({ theme }) => theme.colors.graySecondaryDark};
      margin-bottom: 1rem;

      @media (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
        text-align: center;
      }
    }
  }
`;

export const Item = styled.div<ItemProps>`
  height: 100%;
  width: 100%;
  grid-area: ${({ gridArea }) => gridArea};
`;

export const Content = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  grid-template-areas:
    'localc prof note'
    'spe typec note'
    'date hour note'
    'button . .';

  & > div:nth-child(7) {
    display: flex;
    align-self: flex-start;
  }

  @media (max-width: ${gridBreakpoints.lg}) {
  }

  @media (max-width: ${gridBreakpoints.md}) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'localc prof'
      'spe typec'
      'date hour'
      'note note'
      'button .';
    button {
      margin-top: 1rem;
    }
  }

  @media (max-width: ${gridBreakpoints.sm}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* button {
      width: 76px;
    } */
  }
`;
