import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useField } from '@unform/core';

import { Container, InputsContainer, Button } from './styles';

interface InputTrueOrFalseProps {
  question?: string;
  name: string;
  onClick?: (value?: boolean) => void;
}

interface InputRefProps {
  value?: boolean;
}

const InputTrueOrFalse: React.FC<InputTrueOrFalseProps> = ({
  question,
  name,
  onClick,
}) => {
  const inputRef = useRef<InputRefProps>({ value: undefined });
  const { fieldName, registerField } = useField(name);
  const [value, setValue] = useState<boolean>();

  const handleSelectValue = useCallback(
    (newValue: boolean) => {
      if (inputRef.current) {
        inputRef.current.value = newValue;
        setValue(newValue);

        if (onClick) {
          onClick(newValue);
        }
      }
    },
    [onClick],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, value?: boolean) => {
        setValue(value);
        inputRef.current.value = value;
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {question && <span>{question}</span>}

      <InputsContainer>
        <Button
          type="button"
          isActive={value === true}
          onClick={() => handleSelectValue(true)}
        >
          Sim
        </Button>

        <Button
          type="button"
          isActive={value === false}
          onClick={() => handleSelectValue(false)}
        >
          Não
        </Button>
      </InputsContainer>
    </Container>
  );
};

export default InputTrueOrFalse;
