import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  withHeader: boolean;
}

interface NotificationsProps {
  hasNewNotifications: boolean;
}

interface HeaderProps {
  withLateralMenu: boolean;
}

export const Container = styled.div<ContainerProps>`
  & > div {
    display: flex;
    ${({ withHeader }) =>
      withHeader
        ? css`
            height: calc(100vh - 75px);
          `
        : css`
            height: 100vh;
          `};
  }

  & > div > div > div.pro-sidebar-inner {
    background: ${({ theme }) => theme.colors.grayPrimary};
    color: black;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover,
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
    color: ${({ theme }) => theme.colors.graySecondaryDark};
  }

  .pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu
    > .pro-inner-item
    > .pro-arrow-wrapper
    .pro-arrow {
    border-color: ${({ theme }) => theme.colors.graySecondaryDark};
  }

  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: ${({ theme }) => theme.colors.grayPrimaryLight};
  }

  .pro-sidebar
    .pro-menu.shaped
    .pro-menu-item
    > .pro-inner-item
    > .pro-icon-wrapper {
    background-color: ${({ theme }) => theme.colors.grayPrimaryLight};
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    white-space: normal;
  }

  .pro-sidebar .pro-menu .pro-menu-item.active {
    color: black;
    font-weight: bold;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus,
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: black;
  }

  .pro-sidebar.collapsed
    .pro-menu
    > ul
    > .pro-menu-item.pro-sub-menu
    > .pro-inner-list-item
    > .popper-inner {
    background-color: ${({ theme }) => theme.colors.grayPrimaryLight};
    padding-left: 0;
  }

  .pro-sidebar.collapsed
    .pro-menu
    > ul
    > .pro-menu-item.pro-sub-menu
    .pro-inner-list-item
    .pro-inner-item {
    padding: 8px 30px;
  }

  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    padding-left: 0;
  }

  .pro-menu-item > .pro-inner-item:hover .pro-item-content {
    color: ${({ theme }) => theme.colors.primary};
  }

  .pro-menu-item > .pro-inner-item:hover .pro-icon-wrapper {
    background-color: ${({ theme }) => theme.colors.primary} !important;
  }

  .pro-menu-item > .pro-inner-item:hover .pro-arrow-wrapper span {
    border-color: ${({ theme }) => theme.colors.primary} !important;
  }

  .pro-menu-item > .pro-inner-item:hover .pro-icon-wrapper .pro-icon svg {
    color: white !important;
  }

  .pro-menu-item > .pro-inner-item:hover {
    background: ${({ theme }) => shade(0.1, theme.colors.grayPrimary)};
  }
`;

export const Header = styled.nav<HeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.primaryDark};
  padding: 0px 20px;
  height: 75px;

  div {
    display: flex;
    align-items: center;

    > button {
      background: transparent;
      border: 0;

      &:hover {
        background: ${({ theme }) => shade(0.2, theme.colors.primaryDark)};
      }
    }

    > button:first-child {
      display: ${({ withLateralMenu }) => (withLateralMenu ? 'flex' : 'none')};
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 35px;
      height: 35px;
    }

    > button + button {
      margin-left: 25px;
      border-radius: 6px;

      img {
        height: 45px;
      }
    }
  }

  div:first-child {
    flex: 1;
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  background: #f6f6f6;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.grayLight};
`;

export const Notifications = styled.button<NotificationsProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 40px;
  background: transparent;
  border: none;

  ${({ hasNewNotifications, theme }) =>
    hasNewNotifications &&
    css`
      &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: ${theme.colors.warning};
        border-radius: 50%;
        margin-left: 15px;
        margin-bottom: 15px;
      }
    `}
`;

export const DropMenuProfile = styled.ul`
  list-style: none;
  width: 180px;
  background: ${({ theme }) => theme.colors.graySecondaryDark};
  padding: 8px;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  opacity: 0;
  transition: opacity 0.4s;
  visibility: hidden;

  position: absolute;
  top: calc(7%);
  right: 55px;
  transform: translateX(-5%);

  -webkit-box-shadow: 10px 6px 14px -6px rgba(179, 179, 179, 1);
  -moz-box-shadow: 10px 6px 14px -6px rgba(179, 179, 179, 1);
  box-shadow: 10px 6px 14px -6px rgba(179, 179, 179, 1);

  &::before {
    content: '';
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.graySecondaryDark} transparent;
    border-width: 0px 6px 6px 6px;
    bottom: 100%;
    position: absolute;
    left: 85%;
  }

  li + li {
    border-top: 1px solid ${props => props.theme.colors.grayLight};
  }
`;

export const MenuProfileButton = styled.div`
  display: flex;
  justify-content: flex-start;

  color: #fff;

  padding: 10px 15px;

  margin-top: 4px;
  border: 0px;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }

  svg {
    margin-right: 10px;
  }
`;

export const ProfileWeb = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 25px;
  border: 0;
  background: transparent;
  height: 100%;

  > img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
  }

  &:hover ul {
    opacity: 1;
    visibility: visible;
  }
`;

export const ProfileGreetings = styled.div`
  display: flex;
  align-items: baseline !important;
  color: white;
  flex-direction: column;
  text-align: left;
  margin-left: 5px;
`;
