import { api } from './Api';
import { Procedimento } from '../models/procedimento';
import { ApiObject } from '../models/apiObject';

const getByType = async (type: string): Promise<Procedimento[]> => {
  const { data } = await api.get<ApiObject<Procedimento>>(
    `Procedimento?Tipo=${type}&sort=Descricao`,
  );

  if (!data) {
    return [];
  }

  return data.items;
};

export default { getByType };
