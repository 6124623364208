enum LocalStorageKeyEnum {
  AccessToken = '@AtendTelemedicina:AccessToken',
  RefreshToken = '@AtendTelemedicina:RefreshToken',
  UserId = '@AtendTelemedicina:User:Id',
  Username = '@AtendTelemedicina:User:Username',
  Name = '@AtendTelemedicina:User:Name',
  AuthCod = '@AtendTelemedicina:User:AuthCod',
  CPF = '@AtendTelemedicina:User:CPF',
}

export default LocalStorageKeyEnum;
