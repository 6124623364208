import React, { useCallback, useEffect, useRef } from 'react';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { format, parse } from 'date-fns';
import Input from '../../../../components/Input';

import { Container, Content, Item } from './styles';
import Button from '../../../../components/Button';
import InputMask from '../../../../components/InputMask';
import InputSelect, {
  InputSelectOptions,
} from '../../../../components/InputSelect';
import getValidationCPF from '../../../../utils/getValidationCPF';
import getValidationErrors from '../../../../utils/getValidationErrors';
import useAddress from '../../../../hooks/local/useAddress';
import InputSelectAlternative from '../../../../components/InputSelectAlternative';
import IndividuoService from '../../../../services/IndividuoService';
import { Individuo } from '../../../../models/individuo';
import { useRouteRules } from '../../../../hooks/routeRules';
import { Notificacao } from '../../../../models/notificacao';

interface FormData {
  cpf: string;
  name: string;
  sName: string;
  bday: string;
  email: string;
  phone: string;
  genre: number;
  breed: number;
  motherName: string;
  cep: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  numberAddress: string;
  addressComplement: string;
}

function formatInputDateToApi(date: string): string {
  const dateFormatted = parse(date, 'dd/MM/yyyy', new Date());

  return format(dateFormatted, 'yyyy-MM-dd HH:mm:ss');
}
const CompleteRegister: React.FC = () => {
  const { verifyRules } = useRouteRules();
  const { state } = useLocation();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { getCEP, UfList, cityList, updateUFList, updateCityList } =
    useAddress();

  const breedList: InputSelectOptions = [
    { value: 1, label: 'Branco' },
    { value: 2, label: 'Preto' },
    { value: 3, label: 'Parda' },
    { value: 4, label: 'Amarelo' },
    { value: 5, label: 'Indígena' },
    { value: 6, label: 'Sem Informação' },
  ];

  const genreList: InputSelectOptions = [
    { value: 0, label: 'Masculino' },
    { value: 1, label: 'Feminino' },
  ];

  const handleCEPChange = useCallback(
    async (value: string) => {
      if (value.length === 10) {
        const info = await getCEP(value);

        formRef.current?.setFieldValue('neighborhood', info.bairro);
        formRef.current?.setFieldValue('street', info.logradouro);
        formRef.current?.setFieldValue('addressComplement', info.complemento);
        formRef.current?.setFieldValue('state', info.uf);
        updateCityList(info.uf, info.localidade).then(() => {
          formRef.current?.setFieldValue('city', info.ibge);
        });
      }
    },
    [getCEP, updateCityList],
  );

  const handleCityInputChange = useCallback(
    (value: string) => {
      if (value.length > 2) {
        const uf = formRef.current?.getFieldValue('state');
        updateCityList(uf, value);
      }
    },
    [updateCityList],
  );

  const handleSubmit: SubmitHandler<FormData> = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          cpf: Yup.string()
            .length(14, 'Digite um CPF válido')
            .test('cpf', 'Digite um CPF válido', value =>
              getValidationCPF(value),
            ),
          name: Yup.string().required(),
          sName: Yup.string(),
          bday: Yup.string().required(),
          email: Yup.string().required().email('Digite um email válido'),
          phone: Yup.string().required(),
          genre: Yup.string().required(),
          breed: Yup.string().required(),
          motherName: Yup.string(),
          cep: Yup.string()
            .required()
            .test(
              'length',
              'Digite um CEP válido',
              value => !!(!value || value.length === 10),
            ),
          state: Yup.string().required(),
          city: Yup.string().required(),
          neighborhood: Yup.string(),
          street: Yup.string().required(),
          numberAddress: Yup.string(),
          addressComplement: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let newUser: Individuo = {
          imagem: '',
          telefoneCelular: data.phone.replace(/[^0-9]/g, ''),
          cpf: data.cpf.replace(/[^0-9]/g, ''),
          nomeCompleto: data.name,
          nomeSocial: data.sName,
          dataNascimento: data.bday,
          email: data.email,
          sexo: data.genre,
          racaOuCor: data.breed,
          nomeDaMae: data.motherName,
          logradouroCep: data.cep.replace(/[^0-9]/g, ''),
          ufAbreviado: data.state,
          cidadeId: data.city,
          logradouroBairro: data.neighborhood,
          logradouro: data.street,
          logradouroNumero: data.numberAddress,
          logradouroComplemento: data.addressComplement,
        };
        newUser = await IndividuoService.create(newUser);

        history.push('/cadastro/autenticar', newUser);
      } catch (error) {
        const e = error as AxiosError;
        console.log(e.response);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        } else if (axios.isAxiosError(error) && error.response?.data) {
          const { data } = error.response as {
            data: { notifications: Notificacao[] } | string;
          };

          if (typeof data === 'string') {
            toast.error(data as string);
          } else if (data.notifications && data.notifications.length > 0) {
            data.notifications.forEach(notification => {
              toast.error(notification.message);
            });
          } else {
            toast.error('Tivemos um problema.');
          }
        } else {
          toast.error('Tivemos um problema.');
        }
      }
    },
    [history],
  );

  useEffect(() => {
    verifyRules({
      allowThesePreviousRoutes: ['/cadastro'],
      onError: () => {
        history.push('/cadastro');
      },
      onSuccess: () => {
        if (!state) {
          history.push('/cadastro');
          return;
        }
        updateUFList();
        formRef.current?.setFieldValue('cpf', state?.cpf ?? '');
        formRef.current?.setFieldValue('bday', state?.birthday ?? '');
        formRef.current?.setFieldValue('phone', state?.phone ?? '');
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <h3>Informe seus dados para concluir o cadastro.</h3>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Content>
          <Item gridArea="cpf">
            <InputMask name="cpf" title="CPF" mask="999.999.999-99" />
          </Item>
          <Item gridArea="name">
            <Input name="name" title="Nome Completo" />
          </Item>
          <Item gridArea="sName">
            <Input name="sName" title="Nome Social" />
          </Item>
          <Item gridArea="bday">
            <Input name="bday" title="Data Nascimento" type="date" />
          </Item>
          <Item gridArea="email">
            <Input name="email" title="Email" />
          </Item>
          <Item gridArea="phone">
            <InputMask name="phone" title="Telefone" mask="(99) 9 9999-9999" />
          </Item>
          <Item gridArea="genre">
            <InputSelect name="genre" title="Gênero" options={genreList} />
          </Item>
          <Item gridArea="breed">
            <InputSelect name="breed" title="Raça" options={breedList} />
          </Item>
          <Item gridArea="motherName">
            <Input name="motherName" title="Nome da Mãe" />
          </Item>
          <Item gridArea="cep">
            <InputMask
              name="cep"
              title="CEP"
              mask="99.999-999"
              onChange={({ target }) => handleCEPChange(target.value)}
            />
          </Item>
          <Item gridArea="state">
            <InputSelect name="state" title="Estado" options={UfList} />
          </Item>
          <Item gridArea="city">
            <InputSelectAlternative
              name="city"
              title="Cidade"
              options={cityList}
              onInputChange={value => handleCityInputChange(value)}
            />
          </Item>
          <Item gridArea="neighborhood">
            <Input name="neighborhood" title="Bairro" />
          </Item>
          <Item gridArea="street">
            <Input name="street" title="Logradouro" />
          </Item>
          <Item gridArea="numberAddress">
            <Input name="numberAddress" title="Número" />
          </Item>
          <Item gridArea="addressComplement">
            <Input name="addressComplement" title="Complemento" />
          </Item>
        </Content>
        <Button btnType="gray" type="submit">
          Salvar
        </Button>
      </Form>
    </Container>
  );
};

export default CompleteRegister;
