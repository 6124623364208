/* eslint-disable camelcase */
import { api } from './Api';

interface getTokenResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
}

const getToken = async (
  username: string,
  password: string,
): Promise<getTokenResponse> => {
  const bodyInfo = {
    Username: btoa(username),
    Password: btoa(password),
    Audience: btoa('atendtelemedicina'),
  };

  const { data } = await api.post('/Token', bodyInfo);

  return data as getTokenResponse;
};

export default { getToken };
