import * as Yup from 'yup';
import getValidationCPF from '../utils/getValidationCPF';

export const LoginSchema = Yup.object().shape({
  cpf: Yup.string()
    .required('O CPF é obrigatório')
    .length(14, 'Digite um CPF válido')
    .test('cpf', 'Digite um CPF válido', value => getValidationCPF(value)),
  password: Yup.string().required('A senha é obrigatória'),
});
