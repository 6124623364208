/* eslint-disable */

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { toast, ToastContainer } from 'react-toastify';
import CardGlobal from '../../../components/CardGlobal';
import CleanView from '../../../components/CleanView';
import FileDownloaderButton from '../../../components/FileDownloaderButton';
import Loading from '../../../components/Loading';
import { useExamsTypes } from '../../../hooks/local/useExamsTypes';
import { UseExams } from '../../../hooks/local/useExams';
import InputSelectExamsTypes from '../../../components/InputSelectExamsTypes';
import theme from '../../../styles/theme';
import Button from '../../../components/Button';
import CardExame from '../../../components/CardExame';

import {
  Container,
  ContainerProps as DocumentsProps,
  DocumentsContainer,
  FormExams,
} from './styles';
import { useAuth } from '../../../hooks/auth';
import './style.css';
import { Exame } from '../../../models/IExams';
import { TipoExame } from '../../../models/ITipoExame';
import Swal from 'sweetalert2';

const Exams: React.FC<DocumentsProps> = ({ showButton, ...rest }) => {
  const history = useHistory();
  // const [examsTypes, setExamsTypes] = useState<TipoExame[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { getExamsTypes, type, error: errorExamType } = useExamsTypes();
  const { postExams, error: errorExam, success: successExams } = UseExams();
  const [examsList, setExamsList] = useState<Exame[]>([]);
  const { cpf, id } = useAuth();
  const [showBtn, setShowBtn] = useState(false);
  const [examType, setExamType] = useState<TipoExame>();

  useEffect(() => {
    setLoading(true);
    getExamsTypes();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (errorExamType) {
      toast.error(errorExamType);
    }
  }, [errorExamType]);

  useEffect(() => {
    if (errorExam) {
      toast.error(errorExam);
    }
  }, [errorExam]);

  useEffect(() => {
    if (successExams) {
      toast.success(successExams);
    }
  }, [successExams]);

  const handleChange = async (e: any) => {
    const arrayFiles = Array.from(e.target.files);

    arrayFiles.forEach((file: any) => {
      if (file.type !== 'application/pdf') {
        toast.error('O exame deve estar no formato PDF.');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const data = reader.result; // Contém o base64
        if (!examsList) return;
        const sizeFormat = +(file.size / 1024 / 1000).toFixed(2);
        const fileName = file.name.split('.', 1);
        const obj: Exame = {
          individuoId: id,
          individuoCpf: cpf,
          pdfExame: data,
          nome: fileName[0],
          size: sizeFormat,
          tipoExame: examType,
        };
        const exist = examsList.some(exame => {
          if (exame.nome === obj.nome && exame.tipoExame == obj.tipoExame) {
            return true;
          } else return false;
        });
        if (!exist) {
          setExamsList(examsList => [...examsList, obj]);
        } else {
          toast.error('Não é possível enviar dois exames iguais.');
        }

        //setExamsList(examsList => [...examsList, obj]);
      };
      reader.readAsDataURL(file);
    });
    setShowBtn(false);
  };

  const selectChange = (event: any) => {
    setShowBtn(true);
    const current = event.target.value;
    type?.forEach(tipo => {
      if (tipo.id == current) {
        setExamType(tipo);
      }
    });
  };

  const handleRemove = (exameSelected: Exame, indexExam: number) => {
    Swal.fire({
      confirmButtonColor: `${theme.colors.success}`,
      title: 'Deseja excluir o exame?',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Não`,
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const examsListFilter = examsList.filter(
          (item, index) => index !== indexExam,
        );
        setExamsList([]);
        setExamsList(examsListFilter);
        //setTeste(examsListFilter);

        Swal.fire({
          title: 'Exame excluído com sucesso.',
          confirmButtonText: 'OK',
          confirmButtonColor: `${theme.colors.success}`,
        });
      } else if (result.isDenied) {
      }
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    await postExams(examsList);
    setExamsList([]);
    setLoading(false);
  };

  return (
    <Container {...rest}>
      <CardGlobal>
        <h1>Enviar Exames</h1>

        <DocumentsContainer isLoading={loading}>
          {loading ? (
            <Loading size={50} spinnerColor="gray" />
          ) : (
            <div>
              <FormExams
                onSubmit={() => {}}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%',
                }}
              >
                <InputSelectExamsTypes
                  name="inputSelect"
                  title="Selecione o tipo de exame"
                  options={type}
                  onChange={selectChange}
                />
                {showBtn && (
                  <div
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <label
                      className="custom-file-upload"
                      style={{ backgroundColor: `${theme.colors.secondary}` }}
                    >
                      <input type="file" multiple onChange={handleChange} />
                      <i className="fa fa-cloud-upload" /> Selecionar o exame
                    </label>
                  </div>
                )}

                {examsList.map((exame, index) => (
                  <CardExame
                    key={exame.nome}
                    examType={exame.tipoExame?.nome}
                    name={exame.nome}
                    size={`${exame.size}`}
                    onClick={() => handleRemove(exame, index)}
                  />
                ))}
              </FormExams>
            </div>
          )}
        </DocumentsContainer>

        {examsList.length > 0 && (
          <Button
            btnType="gray"
            onClick={() => handleSubmit()}
            loading={loading}
          >
            Enviar Exames
          </Button>
        )}
        {showButton && (
          <Button
            btnType="primary"
            onClick={() => history.push('/area/exames')}
          >
            Exames enviados
          </Button>
        )}
      </CardGlobal>
      {/* {showDialog && (
       
      )} */}
    </Container>
  );
};

export default Exams;
