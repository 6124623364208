import styled, { css } from 'styled-components';

interface ContainerProps {
  textSize?: number;
  filled?: boolean;
}

export const Container = styled.button<ContainerProps>`
  padding: 12px 12px;
  min-width: 100px;
  font-size: ${({ textSize }) => (textSize ? `${textSize}px` : '1rem')};
  font-weight: 500;

  color: ${({ theme }) => theme.colors.graySecondaryDark};
  border: 2px solid ${({ theme }) => theme.colors.graySecondaryLight};
  background: #fff;

  &:hover {
    color: #fff;
    background: ${({ theme }) => theme.colors.graySecondaryLight};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.graySecondaryLight};
    color: ${({ theme }) => theme.colors.graySecondaryDark};
    border: 2px solid ${({ theme }) => theme.colors.graySecondaryLight};
    cursor: not-allowed;
  }

  ${({ filled }) =>
    filled &&
    css`
      color: #fff;
      background: ${({ theme }) => theme.colors.graySecondaryLight};
    `}
`;
