import { AxiosResponse } from 'axios';
import { ApiObject } from '../models/apiObject';

import { api } from './Api';

interface ExamTypes {
  nome: string | number;
  id: string;
}

async function getExamsTypes(
  skip = 1,
  take = 2000,
  sort = 'Nome ASC',
  total = 0,
): Promise<ApiObject<ExamTypes[]>> {
  const response = await api.get<
    unknown,
    AxiosResponse<ApiObject<ExamTypes[]>>
  >(`/Exames/GetTipoExames`, {
    params: { skip, take, sort, total },
  });
  return response.data;
}

export default { getExamsTypes };
