import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  padding: 0 10px;
  width: 100%;
  max-height: calc(100vh - 75px);

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    max-height: initial;
  }

  > div {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    form {
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      > button {
        margin: 30px auto 0;
        width: 100%;
        max-width: 350px;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #fff;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: ${({ theme }) => theme.colors.grayPrimaryLight};
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  h1 {
    color: ${({ theme }) => theme.colors.graySecondaryDark};
  }

  p {
    color: ${({ theme }) => theme.colors.graySecondary};
    margin-top: 1.25rem;
  }
`;

export const Error = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  color: ${({ theme }) => theme.colors.danger};

  svg {
    margin-right: 10px;
  }
`;
