import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  padding: 0 30px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 0 10px;
  }

  > div {
    width: 100%;
    max-width: 900px;
    padding: 40px;

    h1 {
      margin-bottom: 20px;
    }

    > div + div {
      margin-top: 10px;
    }
  }
`;

export const Notification = styled.div`
  display: flex;
  flex-direction: column;

  border: 2px solid ${({ theme }) => theme.colors.graySecondaryLight};
  padding: 15px;
  border-radius: 6px;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;
