import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 10px;

  & > div {
    max-width: 500px;
    width: 100%;
  }

  h3 {
    margin-bottom: 1rem;
  }

  div + button {
    margin-top: 1rem;
  }
`;
