import { Form } from '@unform/web';
import styled, { css } from 'styled-components';

export interface ContainerProps {
  maxWidthDefault?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  h3 {
    padding: 1rem 0;
  }

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 0 10px;
  }

  div > div {
    > button {
      display: flex;
      align-items: center;
      gap: 0.2rem;
    }
  }
`;

export const CardGlobal = styled.div<ContainerProps>`
  ::-webkit-scrollbar {
    display: none;
  }

  max-height: 100vh;

  overflow: visible;
  background: #fff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 30px;
  margin: 30px auto;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 20px;
    margin: 10px auto;
  }

  ${({ maxWidthDefault }) =>
    maxWidthDefault &&
    css`
      width: 100%;
      max-width: 1600px;
    `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.lg}) {
    flex-direction: column;

    button {
      margin-bottom: 1rem;
    }
  }
`;

export const Content = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const FilterContent = styled(Form)`
  display: flex;
  align-items: flex-end;

  > div + div,
  > button {
    margin-left: 15px;
  }

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
    flex-direction: column;
    width: 100%;

    > div,
    > button {
      width: 100%;
    }

    > div + div,
    > button {
      margin-top: 15px;
    }
  }
`;
