import * as Yup from 'yup';

export const CovidSchema = Yup.object().shape({
  temperatura: Yup.boolean().required(),
  tosse: Yup.boolean().required(),
  coriza: Yup.boolean().required(),
  dorCorpo: Yup.boolean().required(),
  dorAbdominal: Yup.boolean().required(),
  fraqueza: Yup.boolean().required(),
  dorGarganta: Yup.boolean().required(),
  nauseaVomito: Yup.boolean().required(),
  diarreia: Yup.boolean().required(),
  dificuldadeRespirar: Yup.boolean().required(),
  taquicardia: Yup.boolean().required(),
  perdaOlfatoPaladar: Yup.boolean().required(),
  temperaturaRetornou: Yup.boolean().required(),
  sairCasa: Yup.boolean().required(),
  contatoPessoas: Yup.boolean().required(),
  diasComFebre: Yup.boolean().required(),
  atendidoServicoSaude: Yup.boolean().required(),
  dataAtendidoServicoSaude: Yup.date().when('AtendidoServicoSaude', {
    is: true,
    then: Yup.date().required(),
  }),
});
