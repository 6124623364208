import styled from 'styled-components';

interface ListUbsProps {
  isLoading: boolean;
}

export const Container = styled.div`
  padding: 0 30px;

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 0 10px;
  }

  > div {
    display: flex;
    flex-direction: column;

    gap: 1.5rem;
    max-width: 900px;
    color: ${({ theme }) => theme.colors.graySecondaryDark};
  }
`;

export const ListUbs = styled.div<ListUbsProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ isLoading }) => (isLoading ? 'center' : 'flex-start')};

  min-height: 200px;
`;

export const ListItem = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 0.75rem;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.graySecondaryLight};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.graySecondaryDark};

  & + button {
    margin-top: 10px;
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    text-align: start;

    h3 {
      margin-bottom: 5px;
    }

    > div {
      display: flex;
      align-items: flex-start;
      margin-top: 5px;
      font-size: 0.938rem;

      strong {
        margin-right: 5px;
      }

      span {
        text-align: left;
      }
    }
  }

  svg {
    margin-left: 10px;

    @media (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      display: none;
    }
  }
`;
