import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 30px;
  width: 100%;
  max-width: ${({ theme }) => theme.gridBreakpoints.lg};

  > button {
    margin-top: 20px;
    width: 100%;
    max-width: 200px;
  }

  p {
    margin: 15px 0 25px;
  }

  div > button + button {
    margin-left: 15px;
  }

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    align-items: center;

    h2,
    p {
      text-align: center;
    }

    div {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
`;
