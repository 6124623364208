import { useCallback, useState } from 'react';
import { ApiObject } from '../../models/apiObject';
import { Exame } from '../../models/IExams';
import ExameService from '../../services/ExameService';

interface UseExamsReturn {
  postExams: (exame: Exame[]) => Promise<void>;
  getExams: (cpf: string) => Promise<void>;
  error: string;
  success: string;
  exams: Exame[] | undefined;
  loading: boolean;
}

interface ExamsProps {
  skip: 10;
  take: 1000;
  sort: 'Nome ASC';
}

export function UseExams(): UseExamsReturn {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [exams, setExams] = useState<Exame[]>();

  const postExams = useCallback(async (exame: Exame[]) => {
    try {
      setLoading(true);
      setError('');
      await ExameService.post(exame);
      setSuccess('Exames enviados com sucesso.');
    } catch (err: any) {
      setSuccess('');
      setError('Não foi possível enviar os exames.');
    } finally {
      setLoading(false);
    }
  }, []);

  const getExams = useCallback(async (individuoCpf: string) => {
    try {
      if (!individuoCpf) return;
      setLoading(true);
      setError('');
      const Exams = await ExameService.get(1, 999, 'Nome ASC', 0, individuoCpf);
      setExams(Exams.items);
    } catch (err) {
      console.log(err);
      setError('Não foi possivel carregar os exames.');
    } finally {
      setLoading(false);
    }
  }, []);

  return { postExams, getExams, error, success, exams, loading };
}
