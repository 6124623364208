function putCPFMask(cpf: string | undefined): string {
  if (typeof cpf === 'undefined') return '';
  return cpf
    .replace(/(\D)/, '')
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.***.***-$4');
}
function putCEPMask(cpf: string | undefined): string {
  if (typeof cpf === 'undefined') return '';
  return cpf.replace(/(\D)/, '').replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2-***');
}
export { putCEPMask, putCPFMask };
