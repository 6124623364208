import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 30px;

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 0 10px;
  }

  > div {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const MedicalAppointmentDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    gap: 1rem;
  }
`;

export const AcceptRejectButtonsContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 1rem;

  button {
    width: 130px;
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    button {
      width: 80px;
    }
  }
`;

export const DocumentsContainer = styled.div`
  width: 100%;

  h3 {
    font-size: 1.1rem;
    margin-bottom: 0.75rem;
  }
`;
