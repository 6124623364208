import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%; // Tablet: 1rem = 15px
    }

    @media (max-width: 728px) {
      font-size: 87.5%; // Mobile: 1rem = 14px
    }
  }

  body {
    -webkit-font-smoothing: antialiased;
    font-family: 'Roboto', sans-serif;
  }

  button {
    font-family: inherit;
    cursor: pointer;
  }
`;
