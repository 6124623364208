import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export interface ContainerProps {
  maxWidthInitial?: boolean;
}

interface ContentProps {
  isLoading: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 0 30px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.md}) {
    padding: 0 30px;
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 0;
  }

  & > div {
    width: 100%;
    height: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      min-height: initial;
    }

    ${({ maxWidthInitial }) =>
      maxWidthInitial &&
      css`
        max-width: initial;
      `}

    h1 {
      color: ${({ theme }) => theme.colors.graySecondaryDark};
      margin-bottom: 1rem;
    }
  }
`;

export const Content = styled.div<ContentProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({ isLoading }) => (isLoading ? 'center' : 'flex-start')};
  align-items: center;
`;

export const CardMedicalAppointment = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.5rem;
  border-radius: 4px;
  border: 0;
  padding: 1.5rem 1rem;
  transition: filter 0.2s;
  background-color: #fafafa;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 1rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.lg}) {
    gap: 1.25rem;
  }

  &:hover {
    filter: brightness(0.9);
  }

  & + button {
    margin-top: 1rem;
  }

  > div:first-child {
    min-width: 76.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.35rem;
      background: ${({ theme }) => transparentize(0.7, theme.colors.primary)};
      border-radius: 50%;
    }

    strong {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  > div:last-child {
    min-width: 260px;
    max-width: 260px;
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;

    @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
      gap: 0.3rem;
      min-width: 140px;
      max-width: 140px;
      justify-content: center;
    }

    > div:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      @media screen and (max-width: ${({ theme }) =>
          theme.gridBreakpoints.sm}) {
        display: none;
      }

      span {
        font-size: 0.875rem;
        color: #707683;

        & + span {
          margin-top: 0.5rem;
        }
      }
    }

    > div:last-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      @media screen and (max-width: ${({ theme }) =>
          theme.gridBreakpoints.sm}) {
        align-items: center;
      }

      strong {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors.graySecondaryDark};

        & + strong {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 150px;
          margin-top: 0.5rem;
        }
      }
    }
  }
`;
