import React from 'react';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import Loading from '../../../../../../components/Loading';

import { UBSDataProps } from '../../index';

import { Container, Details, Options, Header } from './styles';

interface DetailsUbsProps {
  UBSData?: UBSDataProps;
  setShowCard(): void;
}

const DetailsUbs: React.FC<DetailsUbsProps> = ({ UBSData, setShowCard }) => {
  const history = useHistory();

  return (
    <Container isLoading={!UBSData}>
      {UBSData ? (
        <>
          <Header>
            <h1>{UBSData.nomeFantasia}</h1>

            <button
              type="button"
              onClick={() => history.push('/area/listagem-ubs')}
            >
              <FiChevronLeft size={20} />

              <span>Voltar</span>
            </button>
          </Header>

          <Details>
            <div>
              <strong>Dias de funcionamento:</strong>
              <p>Não informado</p>
            </div>

            <div>
              <strong>Horário de funcionamento:</strong>
              <p>Não informado</p>
            </div>

            <div>
              <strong>
                {UBSData.telefone1 && UBSData.telefone2
                  ? 'Telefone(s):'
                  : 'Telefone:'}
              </strong>
              {UBSData.telefone1 && <p>{UBSData.telefone1}</p>}
              {UBSData.telefone2 && <p>{UBSData.telefone2}</p>}
              {!UBSData.telefone1 && !UBSData.telefone2 && <p>Não informado</p>}
            </div>

            <div>
              <strong>CEP:</strong>
              <p>{UBSData.cep}</p>
            </div>

            <div>
              <strong>Endereço:</strong>
              <p>{UBSData.endereco}</p>
            </div>
          </Details>

          <Options>
            <a
              href={UBSData.linkGoogleMaps}
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Como chegar?</strong>
            </a>

            <button type="button" onClick={() => setShowCard()}>
              <strong>Profissionais</strong>

              <FiChevronRight size={20} color="#fff" />
            </button>
          </Options>
        </>
      ) : (
        <Loading spinnerColor="gray" size={60} />
      )}
    </Container>
  );
};

export default DetailsUbs;
