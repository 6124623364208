import { api } from './Api';
import { Teleconsulta } from '../models/teleConsulta';

const create = async (id: string): Promise<boolean> => {
  const { data } = await api.post(`Teleconsulta/?agendamentoId=${id}`);
  return !!data;
};

const getTeleConsultaById = async (id: string): Promise<Teleconsulta> => {
  const { data } = await api.get<Teleconsulta>(`TeleConsulta/${id}`);

  return data;
};

export default { create, getTeleConsultaById };
