import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { isBefore, isValid, parseISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '../../../../components/Button';
import CardGlobal from '../../../../components/CardGlobal';
import InputSelectAlternative from '../../../../components/InputSelectAlternative';
import TextArea from '../../../../components/TextArea';

import { Container, Content, Item } from './styles';

import getValidationErrors from '../../../../utils/getValidationErrors';

import ProcedimentoEspecialidadeService from '../../../../services/ProcedimentoEspecialidadeService';
import ProfissionalService from '../../../../services/ProfissionalService';
import EstabelecimentosService from '../../../../services/EstabelecimentosService';

import { Estabelecimento } from '../../../../models/estabelecimento';
import { Profissional } from '../../../../models/profissional';
import { Procedimento } from '../../../../models/procedimento';
import InputSelect from '../../../../components/InputSelect';
import { useAvailability } from '../../../../hooks/local/useAvailability';

interface FormData {
  type: string;
  local: string;
  specialty: string;
  professional: string;
  date: Date;
  hour: string;
}

const NovoAgendamento: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [specialties, setSpecialties] = useState<Procedimento[]>([]);
  const [profissionals, setProfissionals] = useState<Profissional[]>([]);
  const [establishments, setEstablishments] = useState<Estabelecimento[]>();
  const { getDias, getHoras, dias, horas } = useAvailability();

  const handleGetSpecialties = async () => {
    const data = await ProcedimentoEspecialidadeService.getByType(
      'Especialidade',
    );
    console.log(data);

    setSpecialties(data);
  };

  const handleGetProfessionals = async () => {
    const data = await ProfissionalService.getAll();

    setProfissionals(data);
  };

  const handleGetEstablishments = async () => {
    const data = await EstabelecimentosService.getList();
    setEstablishments(data);
  };

  // useEffect(() => {
  //   if (
  //     establishments !== null &&
  //     specialties !== null &&
  //     profissionals !== null
  //   ) {
  //     getDias(establishments[0].nomeFantasia, specialties, 'Teleconsulta', profissionals);
  //   }
  // }, [getDias, estabelecimento, procedimento, tipoDaConsulta, profissional]);

  useEffect(() => {
    handleGetEstablishments();
  }, []);

  useEffect(() => {
    handleGetSpecialties();
  }, []);

  useEffect(() => {
    handleGetProfessionals();
  }, []);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          type: Yup.string().required(),
          local: Yup.string(),
          specialty: Yup.string().required(),
          professional: Yup.string().required(),
          date: Yup.date().required(),
          hour: Yup.string()
            .required('Horário obrigatório')
            .test('validate-time', 'Horário invalido', value => {
              if (value) {
                const time = value.split(':');
                const hour = Number(time[0]);
                const minutes = Number(time[1]);
                const date = new Date().setHours(hour, minutes);
                return isValid(date);
              }
              return false;
            })
            .test('time-has-passed', 'O Horário já passou', value => {
              if (value) {
                const time = value.split(':');
                const hour = Number(time[0]);
                const minutes = Number(time[1]);
                const date = parseISO(
                  formRef.current?.getFieldValue('date'),
                ).setHours(hour, minutes);
                return !isBefore(date, new Date());
              }
              return false;
            }),
          note: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        history.push('/termo-de-consentimento', data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [history],
  );
  return (
    <Container>
      <CardGlobal>
        <h3>Agendamento de Consultas</h3>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Content>
            <Item gridArea="localc">
              <InputSelectAlternative
                name="local"
                title="Local da consulta"
                options={establishments?.map(establishment => {
                  return {
                    label: establishment.nomeFantasia,
                    value: establishment.id,
                  };
                })}
              />
            </Item>
            <Item gridArea="spe">
              <InputSelectAlternative
                name="specialty"
                title="Especialidade"
                // options={proceduresPikerItems?.map(specialty => {
                //   return {
                //     label: specialty.label,
                //     value: specialty.key,
                //   };
                // })}
              />
            </Item>
            <Item gridArea="prof">
              <InputSelectAlternative
                name="professional"
                title="Profissional"
                options={profissionals?.map(profissional => {
                  return {
                    label: profissional.nome,
                    value: profissional.id,
                  };
                })}
              />
            </Item>
            {/* <Item gridArea="date">
              <InputSelect
                name="date"
                title="Data"
                options={diasPikerItems.map((dia: { label: any }) => {
                  return {
                    label: dia.label,
                    value: dia.label,
                  };
                })}
              />
            </Item> */}
            {/* options={[
                  { label: '01/10/2021', value: '01/10/2021' },
                  { label: '03/10/2021', value: '03/10/2021' },
                  { label: '05/10/2021', value: '05/10/2021' },
                ]} */}
            <Item gridArea="hour">
              <InputSelect
                name="hour"
                title="Horário"
                type="time"
                options={[{ label: '09:00', value: '09:00' }]}
              />
            </Item>
            <Item gridArea="typec">
              <InputSelectAlternative
                name="type"
                title="Tipo da consulta"
                options={[
                  { label: 'Teleconsulta', value: 'Teleconsulta' },
                  { label: 'Presencial', value: 'Presencial' },
                ]}
              />
            </Item>
            <Item gridArea="note">
              <TextArea name="note" title="Observação" />
            </Item>
            <Item gridArea="button">
              <Button btnType="gray" type="submit">
                Próximo
              </Button>
            </Item>
          </Content>
        </Form>
      </CardGlobal>
    </Container>
  );
};

export default NovoAgendamento;
