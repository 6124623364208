export const colors = {
  primary: '#43bec6',
  primaryLight: '#62E5CB',
  primaryDark: '#008d95',
  secondary: '#546E7A',
  secondaryLight: '#819CA9',
  secondaryDark: '#29434E',
  grayPrimary: '#E0E0E0',
  grayPrimaryLight: '#C1C5C2',
  grayPrimaryDark: '#97989A',
  graySecondary: '#4b5c6c',
  graySecondaryLight: '#c4ced6',
  graySecondaryDark: '#293845',
  grayLight: '#f2f2f2',
  success: '#00C851',
  warning: '#FFBB33',
  danger: '#FF4444',
  info: '#33B5E5',
};
