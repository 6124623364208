import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 50px;

  h1,
  h2 {
    font-weight: 400;
  }

  h2 {
    padding: 15px 0;
  }

  form {
    > div {
      margin: 30px 0px 20px;
      max-width: 300px;

      > button:first-of-type {
        margin-left: 8px;
        border: none;
        background: none;
        font-size: 16px;
        color: ${({ theme }) => theme.colors.info};
      }
    }

    > div + div {
      display: flex;
      margin: 10px 0px 30px;
    }
  }

  @media (max-width: 912px) {
    form {
      > div {
        max-width: unset;
      }

      > button {
        width: 100%;
      }
    }
  }
`;
