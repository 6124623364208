import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import axios from 'axios';
import Button from '../../../../../components/Button';
import InputMask from '../../../../../components/InputMask';
import useQuery from '../../../../../utils/query';
import { Container } from './styles';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import IndividuoCodigoAutenticacaoService from '../../../../../services/IndividuoCodigoAutenticacaoService';
import { useRouteRules } from '../../../../../hooks/routeRules';
import { Notificacao } from '../../../../../models/notificacao';
import IndividuoService from '../../../../../services/IndividuoService';
import IndividuoSenhaService from '../../../../../services/IndividuoSenhaService';

const ConfirmCode: React.FC = () => {
  const { verifyRules } = useRouteRules();
  const { state } = useLocation();
  const history = useHistory();
  const query = useQuery();
  const formRef = useRef<FormHandles>(null);
  const [type, setType] = useState<'sms' | 'whatsapp' | 'email'>(state.method);
  const [user, setUser] = useState<any>({});

  const handleSendAgain = useCallback(async () => {
    try {
      await IndividuoCodigoAutenticacaoService.getCode(state.id, state.method);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        const { data } = error.response as {
          data: { notifications: Notificacao[] } | string;
        };

        if (typeof data === 'string') {
          toast.error(data as string);
        } else if (data.notifications && data.notifications.length > 0) {
          data.notifications.forEach(notification => {
            toast.error(notification.message);
          });
        } else {
          toast.error('Tivemos um problema.');
        }
      } else {
        toast.error('Tivemos um problema.');
      }
    }
  }, []);

  const displayableType = () => {
    if (type === 'email') {
      return 'e-mail';
    }

    if (type === 'sms') {
      return 'SMS';
    }

    return 'whatsapp';
  };

  const handleSubmit = useCallback(
    async (data: { code: string }) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          code: Yup.string().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await IndividuoCodigoAutenticacaoService.confirmCode(
          state?.id,
          data.code,
        );

        history.push('/cadastro/criar-senha', {
          id: state.id,
          cpf: state.cpf,
          codigoAutenticacao: data.code,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        } else if (axios.isAxiosError(error) && error.response?.data) {
          const { data } = error.response as {
            data: { notifications: Notificacao[] } | string;
          };

          if (typeof data === 'string') {
            toast.error(data as string);
          } else if (data.notifications && data.notifications.length > 0) {
            data.notifications.forEach(notification => {
              toast.error(notification.message);
            });
          } else {
            toast.error('Tivemos um problema.');
          }
        } else {
          toast.error('Tivemos um problema.');
        }
      }
    },
    [history, state],
  );

  useEffect(() => {
    verifyRules({
      allowThesePreviousRoutes: ['/cadastro/autenticar'],
      onError: () => {
        history.push('/cadastro');
      },
      onSuccess: () => {
        if (!state?.id) {
          history.push('/cadastro');
        }

        const newType = query.get('type')?.toLowerCase() as
          | 'sms'
          | 'whatsapp'
          | 'email';

        if (
          newType &&
          ['sms', 'whatsapp', 'email'].includes(newType) &&
          state?.id
        ) {
          setType(newType);
        } else {
          history.push('/error');
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <h1>
        Código de autenticação enviado via {displayableType()}, verifique suas
        mensagens
      </h1>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <InputMask
          mask="9999999"
          placeholder="0000000"
          name="code"
          title="Inserir código"
        />
        <div>
          <p>Não recebeu o código?</p>
          <button type="button" onClick={handleSendAgain}>
            re-enviar
          </button>
        </div>
        <Button btnType="gray">Próximo</Button>
      </Form>
    </Container>
  );
};

export default ConfirmCode;
