import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
  width: 100%;
  padding: 15px 15px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin: 30px 0;
  width: 100%;
  max-width: ${({ theme }) => theme.gridBreakpoints.xl};

  h1 {
    margin: 20px auto;
  }

  p {
    margin-top: 15px;
  }
  div {
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    button {
      flex: 0.25;
    }
  }
`;
