import React, { useCallback, useState } from 'react';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { useHistory } from 'react-router-dom';

import PlaceholderImage from '../../../assets/placeholder.png';

import Button from '../../../components/Button';
import {
  MobileContainer,
  DesktopContainer,
  DotThumbnail,
  DesktopSection,
  MobileSection,
  CardGlobalPerfil,
} from './styles';

const Covid: React.FC = () => {
  const history = useHistory();

  const [value, setValue] = useState(0);

  const onChange = useCallback((newValue: number) => {
    setValue(newValue);
  }, []);

  const goToQuestionnaire = () => {
    history.push(`/area/questionarios/covid`);
  };

  return (
    <>
      <DesktopContainer>
        <CardGlobalPerfil>
          <h1>O que é Coronavírus?</h1>

          <DesktopSection>
            <span>
              Os coronavírus podem causar doenças respiratórias e intestinárias
              em seres humanos e em animais. Podem se apresentar de forma leve,
              moderada ou grave.
            </span>
            <img src={PlaceholderImage} alt="" />
          </DesktopSection>

          <DesktopSection>
            <span>
              Olá! Este aplicativo tem a intenção de informar, apoiar e
              acompanhar você que precisa de informações sobre a COVID-19(o
              Coronavírus), está com sintomas e quer saber o que deve fazer ou o
              que tem que fazer o isolamento domiciliar e deve ser monitorado
              para não correr riscos evitáveis. Por meio dele iremos conversar
              com você, seja por questionários e mensagens ou ainda chats e
              contato telefônico nos estados e municípios que oferecem equipe
              clínica de suporte. Orientamos suas decisões, incluindo o suporte.
              Orientaremos suas decisões incluindo o momento certo de procurar
              um serviço de saúde
            </span>
            <img src={PlaceholderImage} alt="" />
          </DesktopSection>

          <DesktopSection>
            <div>
              <span>
                Está com alguma suspeita? Responda o formulário &quot;Como está
                se sentindo?&quot;
              </span>
              <Button btnType="gray" onClick={() => goToQuestionnaire()}>
                Responder questionário
              </Button>
            </div>
            <img src={PlaceholderImage} alt="" />
          </DesktopSection>
        </CardGlobalPerfil>
      </DesktopContainer>

      <MobileContainer>
        <CardGlobalPerfil>
          <Carousel onChange={onChange} value={value}>
            <MobileSection>
              <img src={PlaceholderImage} alt="" />
              <h1>O que é Coronavírus?</h1>
              <p>
                Os coronavírus podem causar doenças respiratórias e
                intestinárias em seres humanos e em animais. Podem se apresentar
                de forma leve, moderada ou grave.
              </p>
            </MobileSection>
            <MobileSection>
              <img src={PlaceholderImage} alt="" />

              <p>
                Olá! Este aplicativo tem a intenção de informar, apoiar e
                acompanhar você que precisa de informações sobre a COVID-19(o
                Coronavírus), está com sintomas e quer saber o que deve fazer ou
                o que tem que fazer o isolamento domiciliar e deve ser
                monitorado para não correr riscos evitáveis. Por meio dele
                iremos conversar com você, seja por questionários e mensagens ou
                ainda chats e contato telefônico nos estados e municípios que
                oferecem equipe clínica de suporte. Orientamos suas decisões,
                incluindo o suporte. Orientaremos suas decisões incluindo o
                momento certo de procurar um serviço de saúde
              </p>
            </MobileSection>
            <MobileSection className="ultimoSlide">
              <img src={PlaceholderImage} alt="" />

              <div>
                <h2>
                  Está com alguma suspeita? Responda o formulário &quot;Como
                  está se sentindo?&quot;
                </h2>
                <Button btnType="gray" onClick={() => goToQuestionnaire()}>
                  Responder questionário
                </Button>
              </div>
            </MobileSection>
          </Carousel>
          <Dots
            onChange={onChange}
            value={value}
            thumbnails={[<DotThumbnail />, <DotThumbnail />, <DotThumbnail />]}
          />
        </CardGlobalPerfil>
      </MobileContainer>
    </>
  );
};

export default Covid;
