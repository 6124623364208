import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import axios from 'axios';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../../../components/Button';
import InputPassword from '../../../../components/InputPassword';
import { Container, Content } from '../styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import IndividuoSenhaService from '../../../../services/IndividuoSenhaService';
import { Notificacao } from '../../../../models/notificacao';
import { PasswordSchema } from '../../../../validations/PasswordSchema';

interface FormData {
  password: string;
  repeat: string;
}

const CreatePasswordForgotPassword: React.FC = () => {
  const { state } = useLocation();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleChangePassword = useCallback(
    async (data: FormData) => {
      setLoading(true);
      try {
        if (!state?.cpf || !state?.codigoAutenticacao) {
          return;
        }
        formRef.current?.setErrors({});

        await PasswordSchema.validate(data, {
          abortEarly: false,
        });

        await IndividuoSenhaService.reset({
          cpf: btoa(state.cpf),
          codigoAutenticacao: btoa(state.codigoAutenticacao),
          senha: btoa(data.password),
          confirmacao: btoa(data.repeat),
        });

        setLoading(false);

        history.push('/');

        toast.success('Senha alterada com sucesso.');
      } catch (error) {
        setLoading(false);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        } else if (axios.isAxiosError(error) && error.response?.data) {
          const { data } = error.response as {
            data: { notifications: Notificacao[] } | string;
          };

          if (typeof data === 'string') {
            toast.error(data as string);
          } else if (data.notifications && data.notifications.length > 0) {
            data.notifications.forEach(notification => {
              toast.error(notification.message);
            });
          } else {
            toast.error('Tivemos um problema.');
          }
        } else {
          toast.error('Tivemos um problema.');
        }
      }
    },
    [history, state],
  );

  return (
    <Container>
      <Content>
        <h2>Criação de senha</h2>

        <Form ref={formRef} onSubmit={handleChangePassword}>
          <InputPassword
            name="password"
            title="Senha"
            placeholder="Digite sua senha..."
          />
          <InputPassword
            name="repeat"
            title="Confirmar senha"
            placeholder="Confirme sua senha..."
          />
          <Button type="submit" btnType="gray" loading={loading}>
            Alterar
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default CreatePasswordForgotPassword;
