/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css, keyframes } from 'styled-components';

export type ColorType = 'gray' | 'primary' | 'white';

interface ContainerProps {
  color?: ColorType;
}

type styleVariationsType = {
  [key in ColorType]: any;
};

const animateLoading = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const styleVariations: styleVariationsType = {
  gray: css`
    color: ${({ theme }) => theme.colors.graySecondaryDark};
  `,
  primary: css`
    color: ${({ theme }) => theme.colors.primary};
  `,
  white: css`
    color: #fff;
  `,
};

export const Container = styled.div<ContainerProps>`
  ${({ color }) => color && styleVariations[color]};

  svg {
    animation: ${animateLoading} 0.6s linear infinite;
  }
`;
