import { Estabelecimento } from '../models/estabelecimento';
import { ApiObject } from '../models/apiObject';
import { api } from './Api';

const getAll = async (): Promise<Estabelecimento[]> => {
  const { data } = await api.get<ApiObject<Estabelecimento>>(
    'Estabelecimentos',
  );
  return data.items;
};

const getList = async (
  take?: number,
  ship?: number,
): Promise<Estabelecimento[]> => {
  const { data } = await api.get<ApiObject<Estabelecimento>>(
    'Estabelecimentos',
    {
      params: { ship, take },
    },
  );
  return data.items;
};

const getById = async (id: string): Promise<Estabelecimento> => {
  const { data } = await api.get<Estabelecimento>(`Estabelecimentos/${id}`);
  return data;
};

export default { getList, getById, getAll };
