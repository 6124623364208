import styled, { keyframes } from 'styled-components';
import { gridBreakpoints } from '../../styles/theme/gridBreakpoints';

const appearFromNothing = keyframes`
  from {
    transform: scaleY(0);
    transform-origin: top;
  }
  to {
    opacity: 1;
    transform: scaleY(1);
  }
`;

const appearFromNothingContent = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
`;

export const LargeCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: ${appearFromNothing} 1s normal;
  transition: transform 0.26s ease;
  background: ${({ theme }) => theme.colors.grayLight};
  border-radius: 12px;
  padding: 1rem 4rem;

  div {
    animation: ${appearFromNothingContent} 1s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    line-height: 27px;

    span {
      color: #757d8e;
      font-size: 0.88rem;
    }
    strong {
      color: ${({ theme }) => theme.colors.graySecondaryDark};
      font-size: 1.25rem;
    }

    &:first-of-type {
      align-items: center;
      color: ${({ theme }) => theme.colors.primary};

      strong {
        max-width: 8.8rem;
        text-align: center;
        color: ${({ theme }) => theme.colors.primary};
        font-weight: 500;
      }

      svg {
        width: 35px;
        height: 35px;
        margin-bottom: 10px;
      }
    }
  }

  @media (max-width: ${gridBreakpoints.lg}) {
    display: none;
  }
`;

export const Card = styled.div`
  width: 50%;
  margin: auto;

  background: ${({ theme }) => theme.colors.grayLight};
  padding: 1rem;
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.graySecondaryDark};

  text-align: center;

  h4 {
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: ${gridBreakpoints.md}) {
    width: 70%;
  }
  @media (max-width: ${gridBreakpoints.sm}) {
    width: 100%;
  }

  @media (min-width: ${gridBreakpoints.lg}) {
    display: none;
  }
`;

export const AgendamentoInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  line-height: 1.5rem;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
`;

export const ProfessionalInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
