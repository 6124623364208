import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdCheck, MdClose, MdOutlineRemoveRedEye } from 'react-icons/md';
import { CgEnter } from 'react-icons/cg';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import axios from 'axios';
import { Agendamento } from '../../models/agendamento';
import { Notificacao } from '../../models/notificacao';
import AgendamentoService from '../../services/AgendamentoService';
import {
  Container,
  DropMenuProfile,
  MenuProfileButton,
  ProfileWeb,
} from './styles';

interface TableButton {
  id: string;
}

const TableButton: React.FC<TableButton> = ({ children, id }) => {
  const history = useHistory();
  const containerRef = useRef<HTMLDivElement>(null);
  const [agendamento, setAgendamento] = useState<Agendamento>(
    {} as Agendamento,
  );
  const handleGetAgendamento = async () => {
    try {
      if (id) {
        const data = await AgendamentoService.getById(id);

        setAgendamento({
          ...data,
          dia: format(new Date(data.dia as string), 'dd/MM/yyyy'),
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        const { data } = error.response as {
          data: { notifications: Notificacao[] } | string;
        };

        if (typeof data === 'string') {
          toast.error(data as string);
        } else if (data.notifications && data.notifications.length > 0) {
          data.notifications.forEach(notification => {
            toast.error(notification.message);
          });
        } else {
          toast.error('Tivemos um problema.');
        }
      } else {
        toast.error('Tivemos um problema.');
      }
    }
  };

  useEffect(() => {
    handleGetAgendamento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!agendamento.emAndamento) {
      setTimeout(() => {
        handleGetAgendamento();
      }, 5000);
    }
  }, [agendamento]);

  const handleClickDetails = () => {
    history.push(`/area/agendamento/detalhes?id=${id}`);
  };

  const handleClickEnter = () => {
    if (agendamento.emAndamento) {
      history.push(`/area/consulta/${id}`);
    }
  };

  return (
    <Container ref={containerRef}>
      <ProfileWeb>
        {children}
        <DropMenuProfile>
          {/* <li>
            <MenuProfileButton>
              <MdCheck />
              <span>Aceitar</span>
            </MenuProfileButton>
          </li>
          <li>
            <MenuProfileButton>
              <MdClose />
              <span>Recusar</span>
            </MenuProfileButton>
          </li> */}
          <li>
            <MenuProfileButton onClick={handleClickDetails}>
              <MdOutlineRemoveRedEye />
              <span>Detalhes</span>
            </MenuProfileButton>
          </li>

          <li>
            <MenuProfileButton onClick={handleClickEnter}>
              <CgEnter />
              <span>Entrar</span>
            </MenuProfileButton>
          </li>
        </DropMenuProfile>
      </ProfileWeb>
    </Container>
  );
};

export default TableButton;
