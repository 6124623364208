import styled, { css } from 'styled-components';
import { colors } from '../../styles/theme/colors';

interface ContainerProps {
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;

  & > input {
    margin-right: 10px;
    width: 20px;
    height: 20px;

    ${({ isErrored }) =>
      isErrored &&
      css`
        outline: 2px solid ${colors.danger};
      `}
  }

  a {
    font-weight: bold;
    text-decoration: none;
    color: ${colors.primaryDark};
    margin: 0 4px;
  }

  a:hover {
    color: ${colors.primary};
  }
`;
