import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { RiStethoscopeLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import moment from 'moment';
import Button from '../../../../components/Button';
import CardGlobal from '../../../../components/CardGlobal';
import CleanView from '../../../../components/CleanView';
import Loading from '../../../../components/Loading';
import { useAuth } from '../../../../hooks/auth';
import { Notificacao } from '../../../../models/notificacao';
import AgendamentoService from '../../../../services/AgendamentoService';
import { colors } from '../../../../styles/theme/colors';

import {
  CardMedicalAppointment,
  Container,
  Content,
  ContainerProps as NextAppointmentsProps,
} from './styles';
import { useSchedules } from '../../../../hooks/local/useSchedules';

interface MedicalAppointmentProps {
  id?: string;
  type?: string;
  date?: string;
  hora?: string;
  doctor?: string;
  specialty?: string;
}

const NextAppointments: React.FC<NextAppointmentsProps> = (...rest) => {
  const { id } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<MedicalAppointmentProps[]>(
    [],
  );

  const {
    error,
    getSchedules,
    loading: loadingChedules,
    scheduleItems,
    success,
  } = useSchedules();

  // const getAppointments = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     if (!id) {
  //       setLoading(false);
  //       return;
  //     }
  //     const data = await AgendamentoService.getAll({
  //       individuoId: id,
  //       finalizado: false,
  //     });
  //     // console.log(format(new Date(data[0].dataCadastro), 'dd/MM/yyyy'));
  //     const apiAppointments = data.map(appointment => ({
  //       id: appointment.id,
  //       date: moment(appointment.dia).format('DD/MM/YYYY'),
  //       hora: appointment.hora,
  //       type: appointment.tipoDaConsulta,
  //       doctor: appointment.profissional?.nome,
  //       specialty: appointment.procedimento?.descricao,
  //     }));

  //     setShowButton(apiAppointments.length > 10);

  //     setAppointments(apiAppointments);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     if (axios.isAxiosError(error) && error.response?.data) {
  //       const { data } = error.response as {
  //         data: { notifications: Notificacao[] } | string;
  //       };

  //       if (typeof data === 'string') {
  //         toast.error(data as string);
  //       } else if (data.notifications && data.notifications.length > 0) {
  //         data.notifications.forEach(notification => {
  //           toast.error(notification.message);
  //         });
  //       } else {
  //         toast.error('Tivemos um problema.');
  //       }
  //     } else {
  //       toast.error('Tivemos um problema.');
  //     }
  //   }
  // }, [id]);

  useEffect(() => {
    getSchedules(false, false);
  }, [getSchedules]);

  return (
    <Container {...rest}>
      <CardGlobal>
        <h1>Próximas consultas</h1>
        <Content isLoading={loading}>
          {!loading ? (
            scheduleItems && scheduleItems.length > 0 ? (
              <>
                {scheduleItems?.map(appointment => (
                  <CardMedicalAppointment
                    key={appointment.id}
                    onClick={() =>
                      history.push(
                        `/area/agendamento/detalhes?id=${appointment.id}`,
                      )
                    }
                  >
                    <div>
                      <div>
                        <RiStethoscopeLine
                          size={25}
                          color={colors.primaryDark}
                        />
                      </div>
                      <strong>{appointment.tipo}</strong>
                    </div>
                    <div>
                      <div>
                        <span>Data:</span>
                        <span>Hora:</span>
                        <span>Médico:</span>
                        <span>Especialidade:</span>
                      </div>
                      <div>
                        <strong>{appointment.data ?? ''}</strong>
                        <strong>{appointment.hora ?? ''}</strong>
                        <strong>{appointment.profissional ?? ''}</strong>
                        <strong>{appointment.especialidade ?? ''}</strong>
                      </div>
                    </div>
                  </CardMedicalAppointment>
                ))}
                {showButton && (
                  <Button
                    btnType="outlinePrimary"
                    onClick={() => history.push('/area/agendamento')}
                  >
                    Todas as consultas
                  </Button>
                )}
              </>
            ) : (
              <CleanView text="Nenhuma consulta agendada" />
            )
          ) : (
            <Loading size={60} spinnerColor="gray" />
          )}
        </Content>
      </CardGlobal>
    </Container>
  );
};

export default NextAppointments;
