import { useField } from '@unform/core';
import React, { InputHTMLAttributes, useEffect, useRef } from 'react';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const InputCheckbox: React.FC<InputProps> = ({ name, children, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.checked;
      },
      clearValue: ref => {
        /**
         * If you want to change the default checked for false or true,
         * you can do so here. In this example, when resetting the form,
         * the checkbox goes back to its initial state.
         */
        ref.current.checked = false;
      },
      setValue: (ref, value) => {
        ref.current.checked = value;
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error}>
      <input
        type="checkbox"
        defaultValue={defaultValue}
        ref={inputRef}
        {...rest}
      />
      {children}
    </Container>
  );
};

export default InputCheckbox;
