import { api } from './Api';
import { ApiObject } from '../models/apiObject';
import { Profissional } from '../models/profissional';

const getAll = async (): Promise<Profissional[]> => {
  const { data } = await api.get<ApiObject<Profissional>>('/profissional', {
    params: {
      sort: 'email',
    },
  });

  if (!data) {
    return [];
  }

  return data.items;
};

export default { getAll };
